var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CSelectWrapper", {
    attrs: {
      label: _vm.label !== undefined ? _vm.label : "Level",
      options: _vm.options,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "tooltip",
          fn: function () {
            return [_vm._t("tooltip")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.level,
      callback: function ($$v) {
        _vm.level = $$v
      },
      expression: "level",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }