<template>
	<lookup
		optionsEndpoint="/api/users/permissionGroups"
		:label="label || 'Roles'"
		v-model="selected"
		displayKey="displayName"
		valueKey="name"
		labelClass="col-form-label col-sm-3 required"
		fieldClass="col-sm-9"
		:isValid="isValid"
		:invalidFeedback="invalidFeedback"
		:multiple="true"
		horizontal
		:selectableFunction="isOptionSelectable"
		:disabled="disable"
		@change="$emit('change', $event)"
	></lookup>
</template>

<script>

import Lookup from '../generic/Lookup'

export default {
	name: 'PermissionsGroupLookup',
	props: ['value', 'isValid', 'invalidFeedback', 'label'],
	components: {Lookup},
	computed: {
		selected: {
			get(){
				return this.value;
			},
			set(v){
				this.$emit('input', v);
			}
		},
		disable(){
			return this.selected && Boolean(this.selected.filter(o => o.name == 'superadmin').length) && !this.hasPermission(this.constants.PERMISSION_UPDATE_USER_SETTINGS)
		},
	},
	methods: {
		isOptionSelectable(option){
			return option.name != 'superadmin' || this.hasPermission(this.constants.PERMISSION_UPDATE_USER_SETTINGS)
		}
	}
}
</script>
