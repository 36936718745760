var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("lookup", {
    attrs: {
      preloadedOptions: _vm.options,
      label: _vm.label !== false ? _vm.label || "Bill To" : false,
      isValid: _vm.isValid,
      invalidFeedback: _vm.invalidFeedback,
      displayKey: "fullName",
      labelClass: "col-form-label col-sm-3",
      fieldClass: _vm.fieldClass || "col-sm-8",
      disabled: "disabled",
    },
    on: {
      change: function ($event) {
        return _vm.$emit("change", $event)
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "tooltip",
          fn: function () {
            return [
              Boolean(_vm.label)
                ? _c("CIcon", {
                    directives: [
                      {
                        name: "c-tooltip",
                        rawName: "v-c-tooltip",
                        value:
                          "Want to change this user? Go to the Edit Client screen and update the main contact.",
                        expression:
                          "'Want to change this user? Go to the Edit Client screen and update the main contact.'",
                      },
                    ],
                    staticClass: "icon-tooltip",
                    attrs: { name: "cid-info-circle" },
                  })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
        {
          key: "extra",
          fn: function () {
            return [_vm._t("ccButton")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v
      },
      expression: "selected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }