var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { directives: [{ name: "fragments", rawName: "v-fragments" }] },
    [
      _vm.type == "item"
        ? _c(
            "span",
            {
              staticClass: "badge",
              class: {
                "badge-secondary": _vm.name == "Draft",
                "badge-danger": _vm.name == "Reverted" || _vm.name == "Overdue",
                "badge-warning": _vm.name == "Pending",
                "badge-primary": _vm.name == "ZohoInvoiceDraft",
                "badge-success": _vm.name == "Paid",
                "badge-info": _vm.name == "InternalCharge",
              },
            },
            [
              _vm._v("\n        " + _vm._s(_vm.formatLabel(_vm.name)) + " "),
              _vm.name == "Overdue"
                ? _c("span", [_vm._v(_vm._s(_vm.days) + "d")])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "import"
        ? _c(
            "span",
            {
              staticClass: "badge",
              class: {
                "badge-success-light": _vm.name == "ImportCompleted",
                "badge-secondary-light": _vm.name == "Draft",
                "badge-danger-light": _vm.name == "Reverted",
                "badge-warning-light": _vm.name == "PartiallyReverted",
              },
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.formatLabel(_vm.name)) + "\n    "
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }