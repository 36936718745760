var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.containerClass || "form-group form-row lookup-field",
      attrs: { role: "group" },
    },
    [
      _vm.label
        ? _c(
            "label",
            { class: _vm.labelClass || "col-form-label w-180" },
            [_vm._v(" " + _vm._s(_vm.label) + " "), _vm._t("tooltip")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "width-300", class: _vm.fieldClass },
        [
          _c("v-select", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.requestOptions,
                expression: "!requestOptions",
              },
            ],
            class: { "is-invalid": !_vm._isValid },
            attrs: {
              label: _vm._displayKey,
              options: _vm.sortedOptions,
              placeholder: _vm.placeholderText,
              invalidFeedback: _vm.invalidFeedback || "",
              clearable: _vm.clearable !== undefined ? _vm.clearable : true,
              disabled: _vm.disabled,
              "append-to-body": true,
              multiple: _vm.multiple,
              selectable: _vm.selectableFunc,
            },
            on: { input: _vm.onChange },
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          }),
          _vm._v(" "),
          _vm.requestOptions
            ? _c(
                "span",
                { staticClass: "form-control" },
                [
                  _c("spinner", { staticClass: "spinner-inline-sm" }),
                  _vm._v(" Loading Options..."),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control is-invalid",
            attrs: { type: "hidden" },
          }),
          _vm._v(" "),
          _vm._t("description"),
          _vm._v(" "),
          !_vm._isValid
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(_vm._s(_vm._invalidFeedback)),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("extra"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }