<template>
	<lookup
		:optionsEndpoint="_optionsEndpoint"
		:label="label !== false ? (label || 'Service') : false"
		v-model="selected"
		:isValid="isValid"
		:invalidFeedback="invalidFeedback"
		:placeholder="placeholder"
		:clearable="clearable !== undefined ? clearable : true"
		:containerClass="containerClass || ''"
		:style="style"
		:fieldClass="fieldClass"
		:labelClass="labelClass"
		:key="clientId"
		:includeProperties="_includeProperties"
		:multiple="multiple"
		:queryParams="queryParams"
		@change="$emit('change', $event)"
		@loaded-options="$emit('loaded-options', $event)"
	></lookup>
</template>

<script>

import Lookup from '../generic/Lookup'

export default {
	name: 'SubscriptionLookup',
	props: {
		value: [String, Object, Array],
		clientId: String,
		isValid: Boolean,
		invalidFeedback: String,
		label: String,
		placeholder: String,
		clearable: { type: Boolean, default: true },
		containerClass: [String, Object],
		minWidth: [String, Number],
		fieldClass: [String, Object],
		labelClass: [String, Object],
		multiple: Boolean,
		optionsEndpoint: String,
		includeProperties: Array,
		queryParams: Object,
		excludeWithUser: Object,
	},
	components: {Lookup},
	computed: {
		_optionsEndpoint(){
			return this.optionsEndpoint || ('/api/services/getSubscriptionOptions/' + this.clientId)
		},
		selected: {
			get(){
				return this.value;
			},
			set(v){
				this.$emit('input', v);
			}
		},
		style(){
			return this.minWidth ? {
				'min-width': this.minWidth
			} : {};
		},
		_includeProperties(){
			const props = ['billingType', 'serviceTypeId', 'project'].concat(this.includeProperties || [])
			return props
		},
	}
}
</script>
