<template>
	<div role="group" class="form-group form-row" :horizontal="horizontal">
		<label v-if="label" class="col-form-label col-sm-3"> {{label}} <span v-if="tooltip" v-tooltip="tooltip"><CBadge shape="pill" color="secondary">?</CBadge></span></label>
		<div :class="label ? 'col-sm-9' : ''" class="d-flex align-items-center">
			<label class="c-switch c-switch-label mb-0" :class="'c-switch-' + (color || 'success')" >
				<input type="checkbox" class="c-switch-input" v-model="checkboxValue" :disabled="disabled" />
				<span class="c-switch-slider" :data-checked="checkedValue || 'On'" :data-unchecked="uncheckedValue || 'Off'"></span>
			</label>
		</div>
	</div>
</template>

<script>
export default {
	props: ['value', 'label', 'disabled', 'tooltip', 'color', 'horizontal', 'checkedValue', 'uncheckedValue'],
	computed: {
		checkboxValue: {
			get() { return this.value },
			set(v) { this.$emit('input', v) }
		}
	}
}
</script>
