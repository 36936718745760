<template>
	<lookup
		optionsEndpoint="/api/plans"
		:label="label !== false ? (label || 'Subsciption Plan') : false"
		v-model="selected"
		:isValid="isValid"
		:invalidFeedback="invalidFeedback"
		:placeholder="placeholder"
		:clearable="clearable !== undefined ? clearable : true"
		:containerClass="containerClass || ''"
		:style="style"
		:labelClass="labelClass"
		:fieldClass="fieldClass"
		@change="$emit('change', $event)"
	></lookup>
</template>

<script>

import Lookup from '../generic/Lookup'

export default {
	name: 'PlanLookup',
	props: ['value', 'isValid', 'invalidFeedback', 'label', 'placeholder', 'clearable', 'containerClass', 'minWidth', 'labelClass', 'fieldClass'],
	components: {Lookup},
	computed: {
		selected: {
			get(){
				return this.value;
			},
			set(v){
				this.$emit('input', v);
			}
		},
		style(){
			return this.minWidth ? {
				'min-width': this.minWidth
			} : {};
		}
	}
}
</script>
