var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "badge",
      class: {
        "badge-success": _vm.type == "External",
        "badge-info": _vm.type == "InternalCharge",
        "badge-warning": _vm.type == "Internal",
        "badge-secondary": _vm.type == "None",
      },
    },
    [_vm._v("\n    " + _vm._s(_vm.formatLabel(_vm.type)) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }