<template>
	<span>
		<slot v-if="show" />
		<slot v-else name="alternate-content" />
        <CCard v-if="accessDeniedMsg && !show" class="h-100">
            <CCardBody>
                <h4>Access Denied.</h4>
                <p>You do not have permission to access this section.</p>
            </CCardBody>
        </CCard>
	</span>
</template>

<script>
export default {
	props: {
		value: [String, Array, Object],
		'type': {
			type: String,
			default: 'permission'
 		},
        'access-denied-msg':{
            type: Boolean,
            default: false
        }
	},
	computed: {
		show() {
            if(this.type == 'permission')
			    return this.hasPermission(this.value)
            else if(this.type == 'role')
                return this.hasRole(this.value)
            else if(this.type == 'any')
                return this.hasAnyPermission(this.value)
		}
	}
}
</script>
