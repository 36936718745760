<template>
	<div>
		<spinner v-if="reevaluating" />
		<div v-else-if="validationResponse">
			<div class="alert alert-warning" v-if="validationResponse.alertHTML">
				<span v-html="validationResponse.alertHTML"></span>
			</div>
			<div class="d-flex border mb-2" style="border-radius: 10px;">
				<div class="border-right position-relative" v-width="'40px'">
					<Icon name="wallet" icon-pack="bootstrap" class="vertical-center" />
				</div>
				<div class="pl-2 pt-2 pb-2">
					<p class="mb-1">Before this {{userLeave.entryType}} {{username}}'s {{userLeave.type}}'s balance is {{balanceBefore}}</p>
					<p class="mb-0">After this {{userLeave.entryType}} {{username}}'s {{userLeave.type}}'s balance will be {{balanceAfter}}</p>
				</div>
			</div>
			<div class="d-flex border mb-2" style="border-radius: 10px;" v-if="(validationResponse.dailyTargets || []).length > 0">
				<div class="border-right position-relative" v-width="'40px'">
					<Icon name="cis-target" class="vertical-center" />
				</div>
				<div class="w-100">
					<DxScrollView width="100%" height="100%" show-scrollbar="always">
						<div style="max-height: 100px;">
							<div class="border-bottom pl-2 pb-2 pt-2" v-for="(dailyTarget, i) in validationResponse.dailyTargets" :key="`daily-target-${i}`">
								<p class="mb-1">Before this {{userLeave.entryType}} {{username}}'s daily target on {{dailyTarget.date | MMslashDD}} is {{dailyTarget.billableHoursTarget}}</p>
								<p class="mb-0">After this {{userLeave.entryType}} {{username}}'s daily target on {{dailyTarget.date | MMslashDD}} will be {{dailyTarget.expectedBillableHoursTarget}}</p>
							</div>
						</div>
					</DxScrollView>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { DxScrollView } from 'devextreme-vue/scroll-view';
export default{
	props:['validationResponse','userLeave','username','leaveBank'],
	components: {DxScrollView},
	data(){
		return {
			reevaluating: false,
			balanceBefore: 0,
			balanceAfter: 0
		}
	},
	methods:{
		reevaluatingStart(){
			this.reevaluating = true;
		},
		evaluateBalance(){
			this.reevaluating = false;
			let type = _.camelCase(this.userLeave.type);
            this.balanceBefore = Number(this.leaveBank[type]).toFixed(2)
            this.balanceAfter = (Number(this.leaveBank[type]) + Number(this.userLeave.debit) - Number(this.userLeave.credit)).toFixed(2)
		}
	}
}
</script>
