const requiredObject = (value) => (typeof(value) !== 'undefined' && value != null && value.constructor === Object && Object.keys(value).length > 0)
const required = (value) => (typeof(value) !== 'undefined' && value !== null && value.toString().trim() !== '')
const requiredIf = (value, conditionCheck) => (!conditionCheck() || (typeof(value) !== 'undefined' && value !== null && value.toString().trim() !== ''))
const requiredObjectIf = (value, conditionCheck) => (!conditionCheck() || typeof(value) !== 'undefined' && value != null && value.constructor === Object && Object.keys(value).length > 0)
const requiredFileIf = (value, conditionCheck) => (!conditionCheck() || typeof(value) !== 'undefined' && value != null && value.constructor === FileList && value.length > 0)
const numeric = (value) => ((value != null && value != '') ? !isNaN(Number(value)) : true)
const integer = (value) => ((value != null && value != '') ? parseInt(value).toString() == value : true)
const maxLength = (value, max) => ((typeof(value) !== 'undefined' && value !== null && value != '') ? value.length <= max : true)
const minLength = (value, min) => ((typeof(value) !== 'undefined' && value !== null) ? value.length >= min : true)
const minLengthIf = (value, min, conditionCheck) => (!conditionCheck() || ((typeof(value) !== 'undefined' && value !== null) ? value.length >= min : true))
const emailDomainRegex = new RegExp(/^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
const emailDomain = (value) => ((value != null && value != '') ? (!value.includes('@') && emailDomainRegex.test(value)) : true)
export const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
const email = (value) => ((value != null && value != '') ? emailRegex.test(value) : true)
const phoneRegex = new RegExp(/\d{10,}$/)
const phone = (value) => ((value != null && value != '') ? phoneRegex.test(value) : true)
export const urlRegex = new RegExp(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/)
const url = (value) => ((value != null && value != '') ? urlRegex.test(value) : true)
const minValue = (value, minValue) => ((value != null && value != '') ? value >= minValue : true)
const maxValue = (value, maxValue) => ((value != null && value != '') ? value <= maxValue : true)

const BASE_VALIDATION = {
	data(){
		return {
			validationPasses: {},
			validationRules: {},
			modifiedFields: {},
			validationErrors: {},
			// Add to this in the extending objects
			errorMessages: {
				default: {
					requiredObject: 'This field is required.',
					required: 'This field is required.',
					requiredIf: 'This field is required.',
					numeric: 'This field must contain a numeric value.',
					integer: 'This field must contain an integer.',
					emailDomain: 'Please enter a valid domain.',
					maxLength: 'This value exceeds the maximum length.',
					minLength: 'This value does not meet the minimum length.',
					minLengthIf: 'This value does not meet the minimum length.',
					email: 'This field must contain an email address.',
					phone: 'This field must contain a valid phone number.',
					url: 'This field must contain a valid URL.',
				}
			}
		}
	},
	watch: {},
	methods: {
		validationReset(){
			this.$set(this, 'validationPasses', {})
			this.$set(this, 'modifiedFields', {})
			this.$set(this, 'validationErrors', {})
		},
		fieldChanged(model, newVal, oldVal, fieldName){
			if (newVal != oldVal){
				this.modifiedFields[fieldName] = true
			}
			this.validateField(model, fieldName)
		},
		isValid (fieldName) {
			return this.modifiedFields[fieldName] ? this.validationPasses[fieldName] : null
		},
		validateField(model, fieldName){
			let passes = true;
			let self = this;
			if (self.validationErrors[fieldName]){
				delete self.validationErrors[fieldName]
			}
			if (model) {
				Object.keys(self.validationRules[fieldName]).forEach(rule => {
					let method = self.validationRules[fieldName][rule]
					let params = []
					let fieldValue = model[fieldName]
					if (typeof(method) != 'function'){
						if (method.getSubModel){
							model = method.getSubModel(model)
							fieldValue = model[fieldName]
						}
						if (method.getFieldValue){
							fieldValue = method.getFieldValue(model)
						}
						params = method.params
						method = method.method
					}

					const thisPasses = model == null || method(fieldValue, ...params)
					passes = passes && thisPasses
					if (!thisPasses){
						const validationErrors = self.validationErrors
						let errorMsg = (self.errorMessages[fieldName] && self.errorMessages[fieldName][rule])
							? self.errorMessages[fieldName][rule]
							: self.errorMessages.default[rule]
						validationErrors[fieldName] = typeof errorMsg === 'function' ? errorMsg() : errorMsg
						self.$set(self, 'validationErrors', validationErrors)
						console.error(validationErrors)
					}
				})
			}
			const validationPasses = self.validationPasses
			validationPasses[fieldName] = passes
			self.$set(self, 'validationPasses', validationPasses)
			if (this.onUpdateValidation){
				this.onUpdateValidation()
			}
		},
		validate(model){
			let self = this;
			Object.keys(this.validationRules).forEach(fieldName => {
				self.validateField(model, fieldName)
				self.modifiedFields[fieldName] = true
			});
			self.$forceUpdate();
			if (self.validationErrors.length){
				console.log(self.validationErrors)
			}
			return !Object.keys(self.validationPasses).some(key=>!self.validationPasses[key])
		},
		checkIfValid (fieldName) {
			return typeof(this.validationPasses[fieldName]) !== 'undefined' ? this.validationPasses[fieldName] : null
		},
		isFieldRequired(fieldName){
			return this.validationRules[fieldName] != null
				&& (Object.keys(this.validationRules[fieldName]).some(fn => (fn == 'required' || fn == 'requiredObject'))
					// Also check if it's conditionally required.
					|| this.validationRules[fieldName].requiredIf && this.validationRules[fieldName].requiredIf.params[0]()
					|| this.validationRules[fieldName].requiredObjectIf && this.validationRules[fieldName].requiredObjectIf.params[0]()
				)
		},
		addError(fieldName, message){
			this.validationPasses[fieldName] = false
			this.validationErrors[fieldName] = message
			this.$forceUpdate()
		}
	}
}

export const Helpers = {
	methods: {
		isEmailOrBlank: email,
		isNumberOrBlank: numeric,
		hasTextValue: required,
	}
}

export const SubscriptionValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				product: {
					requiredObjectIf: {
						method: requiredObjectIf,
						params: [() => this.service.zoho_subscription_id]
					}
				},
				plan: {
					requiredObjectIf: {
						method: requiredObjectIf,
						params: [() => this.service.zoho_subscription_id]
					}
				},
				client: {
					requiredObject
				},
				project: {
					requiredObjectIf: {
						method: requiredObjectIf,
						params: [() => this.isProjectRequired]
					}
				},
				zoho_subscription_id: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.isSubscriptionRequired]
					},
				},
				quota: {
					numeric,
				},
				// price: {
				// 	required,
				// 	numeric
				// },
				// quantity: {
				// 	requiredIf: {
				// 		method: requiredIf,
				// 		params: [() => this.service.serviceType && this.service.serviceType.id == window.constants.ZOHO_SLA_SERVICE_TYPE_ID]
				// 	},
				// 	integer
				// },
				// amount: {
				// 	required,
				// 	numeric
				// },
				// status: {
				// 	required,
				// },
				// interval: {
				// 	required,
				// 	integer
				// }
				securityAwarenessTrainingStartDate: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.service.securityAwarenessTraining]
					}
				},
				binder: {
					url
				},
				backupSolution: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.service.managedBackups]
					}
				},
				isTaxable: {
					required
				},
				zohoTaxId: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.service.isTaxable]
					}
				},
				zohoTaxExemptionId: {
					requiredIf: {
						method: requiredIf,
						params: [() => !this.service.isTaxable]
					}
				},
				zohoTaxAuthorityId: {
					requiredIf: {
						method: requiredIf,
						params: [() => !this.service.isTaxable]
					}
				},
			},
			errorMessages: {
				securityAwarenessTrainingStartDate: {
					requiredIf: 'A start date is required if using security awareness training'
				},
				backupSolution: {
					requiredIf: 'Backup solution is required when using managed backups.'
				},
				project: {
					requiredObjectIf: 'A project is required for services of the selected type.'
				},
				zoho_subscription_id: {
					requiredIf: 'A Zoho Subscription is required for flat fee services.'
				}
			}
		}
	},
	watch: {
		// 'service.serviceType': function(newVal, oldVal) {
		// 	this.fieldChanged(this.service, newVal, oldVal, 'serviceType');
		// },
		'service.project': function(newVal, oldVal) {
			this.fieldChanged(this.service, newVal, oldVal, 'project');
		},
		'isProjectRequired': function() {
			this.fieldChanged(this.service, this.service.project, this.service.project, 'project');
		},
		'service.product': function(newVal, oldVal) {
			this.fieldChanged(this.service, newVal, oldVal, 'product');
		},
		'service.plan': function(newVal, oldVal) {
			this.fieldChanged(this.service, newVal, oldVal, 'plan');
		},
		'service.zoho_subscription_id': function(newVal, oldVal) {
			this.fieldChanged(this.service, newVal, oldVal, 'zoho_subscription_id');
		},
		'isSubscriptionRequired': function() {
			this.fieldChanged(this.service, this.service.zoho_subscription_id, this.service.zoho_subscription_id, 'zoho_subscription_id');
		},
		'service.client': function(newVal, oldVal) {
			this.fieldChanged(this.service, newVal, oldVal, 'client');
		},
		// 'service.price': function(newVal, oldVal) {
		// 	this.fieldChanged(this.service, newVal, oldVal, 'price');
		// },
		// 'service.hourlyRate': function(newVal, oldVal) {
		// 	this.fieldChanged(this.service, newVal, oldVal, 'hourlyRate');
		// },
		// 'service.quantity': function(newVal, oldVal) {
		// 	this.fieldChanged(this.service, newVal, oldVal, 'quantity');
		// },
		'service.binder': function(newVal, oldVal) {
			this.fieldChanged(this.service, newVal, oldVal, 'binder');
		},
		'service.securityAwarenessTrainingStartDate': function(newVal, oldVal) {
			this.fieldChanged(this.service, newVal, oldVal, 'securityAwarenessTrainingStartDate');
		},
		'service.backupSolution': function(newVal, oldVal) {
			this.fieldChanged(this.service, newVal, oldVal, 'backupSolution');
		},
		'service.isTaxable': function(newVal, oldVal) {
			this.fieldChanged(this.service, newVal, oldVal, 'isTaxable');
		},
		'service.zohoTaxId': function(newVal, oldVal) {
			this.fieldChanged(this.service, newVal, oldVal, 'zohoTaxId');
		},
		'service.zohoTaxExemptionId': function(newVal, oldVal) {
			this.fieldChanged(this.service, newVal, oldVal, 'zohoTaxExemptionId');
		},
		'service.zohoTaxAuthorityId': function(newVal, oldVal) {
			this.fieldChanged(this.service, newVal, oldVal, 'zohoTaxAuthorityId');
		},
	}
}

export const AddonsValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				deviceType: {
					required
				},
				name: {
					required
				},
				addonCode: {
					required
				},
				quantity: {
					required,
					integer
				},
				price: {
					required,
					numeric
				},
				amount: {
					numeric
				},
			},
		}
	},
	watch: {
		'addon.deviceType': function(newVal, oldVal) {
			this.fieldChanged(this.addon, newVal, oldVal, 'deviceType');
		},
		'addon.name': function(newVal, oldVal) {
			this.fieldChanged(this.addon, newVal, oldVal, 'name');
		},
		'addon.addonCode': function(newVal, oldVal) {
			this.fieldChanged(this.addon, newVal, oldVal, 'addonCode');
		},
		'addon.quantity': function(newVal, oldVal) {
			this.fieldChanged(this.addon, newVal, oldVal, 'quantity');
		},
		// 'addon.price': function(newVal, oldVal) {
		// 	this.fieldChanged(this.addon, newVal, oldVal, 'price');
		// },
		// 'addon.amount': function(newVal, oldVal) {
		// 	this.fieldChanged(this.addon, newVal, oldVal, 'amount');
		// },
	}
}

export const ServiceWizardOneValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				// theServiceType: {
				// 	requiredObject
				// },
				clientId: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.selectOrCreateClientValue == 'select']
					}
				},
				companyName: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.selectOrCreateClientValue == 'create']
					},
				},
				firstName: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.selectOrCreateClientValue == 'create']
					},
				},
				lastName: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.selectOrCreateClientValue == 'create']
					},
				},
				email: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.selectOrCreateClientValue == 'create']
					},
					email
				},
				zohoTaxExemptionId: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.selectOrCreateClientValue == 'create' && !this.theClient.isTaxable]
					}
				},
				zohoTaxAuthorityId: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.selectOrCreateClientValue == 'create' && !this.theClient.isTaxable]
					}
				}
			},
			errorMessages: {
				clientId: {
					requiredIf: 'Client is required.'
				},
				email: {
					required: 'Email is required.'
				},
				zohoTaxExemptionId: {
					requiredIf: 'Tax Exemption is required for tax-exempt customers.'
				},
				zohoTaxAuthorityId: {
					requiredIf: 'Tax Authority is required for tax-exempt customers.'
				},
			}
		}
	},
	computed: {
		clientId() { return this.theClient ? this.theClient.id : '' },
		companyName() { return this.theClient.companyName },
		firstName() { return this.theClient.firstName },
		lastName() { return this.theClient.lastName },
		email() { return this.theClient.email },
		zohoTaxExemptionId() { return this.theClient.zohoTaxExemptionId },
		zohoTaxAuthorityId() { return this.theClient.zohoTaxAuthorityId },
	},
	watch: {
		// 'theServiceType': function(newVal, oldVal) {
		// 	this.fieldChanged(this, newVal, oldVal, 'theServiceType');
		// },
		'clientId': function(newVal, oldVal) {
			this.fieldChanged(this, newVal, oldVal, 'clientId');
		},
		'theClient.companyName': function(newVal, oldVal) {
			this.fieldChanged(this.theClient, newVal, oldVal, 'companyName');
		},
		'theClient.firstName': function(newVal, oldVal) {
			this.fieldChanged(this.theClient, newVal, oldVal, 'firstName');
		},
		'theClient.lastName': function(newVal, oldVal) {
			this.fieldChanged(this.theClient, newVal, oldVal, 'lastName');
		},
		'theClient.email': function(newVal, oldVal) {
			this.fieldChanged(this.theClient, newVal, oldVal, 'email');
		},
		'theClient.zohoTaxExemptionId': function(newVal, oldVal) {
			this.fieldChanged(this.theClient, newVal, oldVal, 'zohoTaxExemptionId');
		},
		'theClient.zohoTaxAuthorityId': function(newVal, oldVal) {
			this.fieldChanged(this.theClient, newVal, oldVal, 'zohoTaxAuthorityId');
		},
	}
}

export const ServiceWizardTwoValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				selectedZSub: {
					requiredObjectIf:{
						method: requiredObjectIf,
						params: [() => this.isLinkingAccounts]
					}
				},
				selectedP9Client: {
					requiredObjectIf:{
						method: requiredObjectIf,
						params: [() => this.isLinkingAccounts]
					}
				},
			},
		}
	},
	watch: {
		selectedZSub: function(newVal, oldVal) {
			this.fieldChanged(this, newVal, oldVal, 'selectedZSub');
		},
		selectedP9Client: function(newVal, oldVal) {
			this.fieldChanged(this, newVal, oldVal, 'selectedP9Client');
		},
		isLinkingAccounts(){
			this.fieldChanged(this, null, null, 'selectedZSub');
			this.fieldChanged(this, null, null, 'selectedP9Client');
		}
	}
}

export const ClientValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				companyName: {
					required
				},
				firstName: {
					required
				},
				lastName: {
					required
				},
				email: {
					required,
					email
				},
				phone: {
					phone,
				},
				isTaxable: {
					required
				},
				zohoTaxId: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.client.isTaxable]
					}
				},
				zohoTaxExemptionId: {
					requiredIf: {
						method: requiredIf,
						params: [() => !this.client.isTaxable]
					}
				},
				zohoTaxAuthorityId: {
					requiredIf: {
						method: requiredIf,
						params: [() => !this.client.isTaxable]
					}
				}
			},
			errorMessages: {
				email: {
					required: 'Email is required.'
				},
				zohoTaxId: {
					requiredIf: 'Tax Rate is required for taxable customers.'
				},
				zohoTaxExemptionId: {
					requiredIf: 'Tax Exemption is required for tax-exempt customers.'
				},
				zohoTaxAuthorityId: {
					requiredIf: 'Tax Authority is required for tax-exempt customers.'
				},
			}
		}
	},
	watch: {
		'client.companyName': function(newVal, oldVal) {
			this.fieldChanged(this.client, newVal, oldVal, 'companyName');
		},
		'client.firstName': function(newVal, oldVal) {
			this.fieldChanged(this.client, newVal, oldVal, 'firstName');
		},
		'client.lastName': function(newVal, oldVal) {
			this.fieldChanged(this.client, newVal, oldVal, 'lastName');
		},
		'client.email': function(newVal, oldVal) {
			this.fieldChanged(this.client, newVal, oldVal, 'email');
		},
		'client.phone': function(newVal, oldVal) {
			this.fieldChanged(this.client, newVal, oldVal, 'phone');
		},
		'client.isTaxable': function(newVal, oldVal) {
			this.fieldChanged(this.client, newVal, oldVal, 'isTaxable');
		},
		'client.zohoTaxId': function(newVal, oldVal) {
			this.fieldChanged(this.client, newVal, oldVal, 'zohoTaxId');
		},
		'client.zohoTaxExemptionId': function(newVal, oldVal) {
			this.fieldChanged(this.client, newVal, oldVal, 'zohoTaxExemptionId');
		},
		'client.zohoTaxAuthorityId': function(newVal, oldVal) {
			this.fieldChanged(this.client, newVal, oldVal, 'zohoTaxAuthorityId');
		},
	}
}

export const SettingsValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				defaultProduct: {
					requiredObject
				},
				defaultPlan: {
					requiredObject
				},
			},
		}
	},
	watch: {
		'defaults.defaultProduct': function(newVal, oldVal) {
			this.fieldChanged(this.defaults, newVal, oldVal, 'defaultProduct');
		},
		'defaults.defaultPlan': function(newVal, oldVal) {
			this.fieldChanged(this.defaults, newVal, oldVal, 'defaultPlan');
		},
	}
}

export const DeviceTypeAddonValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				addon: {
					requiredObject
				},
				price: {
					required,
					numeric
				},
			},
		}
	},
	watch: {
		'row.addon': function(newVal, oldVal) {
			this.fieldChanged(this.row, newVal, oldVal, 'addon');
		},
		'row.price': function(newVal, oldVal) {
			this.fieldChanged(this.row, newVal, oldVal, 'price');
		},
	}
}

export const DeviceValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				deviceType: {
					required
				},
				name: {
					required
				},
			},
		}
	},
	watch: {
		'device.deviceType': function(newVal, oldVal) {
			this.fieldChanged(this.device, newVal, oldVal, 'deviceType');
		},
		'device.name': function(newVal, oldVal) {
			this.fieldChanged(this.device, newVal, oldVal, 'name');
		},
	}
}
export const LinkAccountValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				selectedZSub: {
					requiredObject
				},
				selectedP9Client: {
					requiredObject
				},
			},
		}
	},
	watch: {
		selectedZSub: function(newVal, oldVal) {
			this.fieldChanged(this, newVal, oldVal, 'selectedZSub');
		},
		selectedP9Client: function(newVal, oldVal) {
			this.fieldChanged(this, newVal, oldVal, 'selectedP9Client');
		},
	}
}

export const HourlyRateValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				newHourlyRate: {
					required,
					numeric
				},
			},
		}
	},
	watch: {
		newHourlyRate: function(newVal, oldVal) {
			this.fieldChanged(this, newVal, oldVal, 'newHourlyRate');
		},
	}
}

export const EmailDomainValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				domainValue: {
					required,
					emailDomain
				},
			},
			errorMessages: {
				domainValue: {
					emailDomain: 'Please enter a valid domain. Include only the portion following the \'@\' symbol.'
				}
			}
		}
	},
	watch: {
		domainValue: function(newVal, oldVal) {
			this.fieldChanged(this, newVal, oldVal, 'domainValue')
		},
	}
}

export const AuthorizedUserValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				selectedServices: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.newUserMode == 'existing']
					},
					minLengthIf: {
						method: minLengthIf,
						params: [1, () => this.newUserMode == 'existing']
					},
				},
				// selectedService: {
				// 	requiredIf: {
				// 		method: requiredIf,
				// 		params: [() => this.newUserMode == 'existing']
				// 	}
				// },
				linkExistingUser: {
					requiredIf: {
						method: requiredObjectIf,
						params: [() => this.newUserMode == 'existing']
					}
				},
				firstName: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.newUserMode != 'existing']
					}
				},
				lastName: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.newUserMode != 'existing']
					}
				},
				// title: {},
				email: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.newUserMode != 'existing']
					},
					email,
				},
				level: {
					required,
				},
				notes: {
					maxLength: {
						method: maxLength,
						params: [2000]
					}
				},
				street: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.requireAddress && this.newUserMode != 'existing'],
						getSubModel: (model) => model.address
					}
				},
				city: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.requireAddress && this.newUserMode != 'existing'],
						getSubModel: (model) => model.address
					}
				},
				state: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.requireAddress && this.newUserMode != 'existing'],
						getSubModel: (model) => model.address
					}
				},
				zip: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.requireAddress && this.newUserMode != 'existing'],
						getSubModel: (model) => model.address
					}
				},
				country: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.requireAddress && this.newUserMode != 'existing'],
						getSubModel: (model) => model.address
					}
				},
			},
			errorMessages: {
				notes: {
					maxLength: 'Notes can not be more than 2000 characters long.'
				},
				selectedServices: {
					minLengthIf: 'At least one service is required to which to add the seletcted user.'
				},
			}
		}
	},
	watch: {
		'authorizedUser.selectedServices': function(newVal, oldVal) {
			this.fieldChanged(this.authorizedUser, newVal, oldVal, 'selectedServices')
		},
		// 'authorizedUser.selectedService': function(newVal, oldVal) {
		// 	this.fieldChanged(this.authorizedUser, newVal, oldVal, 'selectedService')
		// },
		'authorizedUser.linkExistingUser': function(newVal, oldVal) {
			this.fieldChanged(this.authorizedUser, newVal, oldVal, 'linkExistingUser')
		},
		'authorizedUser.firstName': function(newVal, oldVal) {
			this.fieldChanged(this.authorizedUser, newVal, oldVal, 'firstName')
		},
		'authorizedUser.lastName': function(newVal, oldVal) {
			this.fieldChanged(this.authorizedUser, newVal, oldVal, 'lastName')
		},
		'authorizedUser.email': function(newVal, oldVal) {
			this.fieldChanged(this.authorizedUser, newVal, oldVal, 'email')
		},
		'authorizedUser.level': function(newVal, oldVal) {
			this.fieldChanged(this.authorizedUser, newVal, oldVal, 'level')
		},
		'authorizedUser.notes': function(newVal, oldVal) {
			this.fieldChanged(this.authorizedUser, newVal, oldVal, 'notes')
		},
		'authorizedUser.address.street': function(newVal, oldVal) {
			this.fieldChanged(this.authorizedUser, newVal, oldVal, 'street')
		},
		'authorizedUser.address.city': function(newVal, oldVal) {
			this.fieldChanged(this.authorizedUser, newVal, oldVal, 'city')
		},
		'authorizedUser.address.state': function(newVal, oldVal) {
			this.fieldChanged(this.authorizedUser, newVal, oldVal, 'state')
		},
		'authorizedUser.address.zip': function(newVal, oldVal) {
			this.fieldChanged(this.authorizedUser, newVal, oldVal, 'zip')
		},
		'authorizedUser.address.country': function(newVal, oldVal) {
			this.fieldChanged(this.authorizedUser, newVal, oldVal, 'country')
		},
	}
}

export const SupportNoteValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				title: {
					required,
					maxLength: {
						method: maxLength,
						params: [255]
					}
				},
				text: {
					maxLength: {
						method: maxLength,
						params: [1000]
					}
				},
				color: {
					required,
				},
			},
			errorMessages: {
				title: {
					maxLength: 'Note can not be more than 255 characters long.'
				},
				text: {
					maxLength: 'Note can not be more than 1000 characters long.'
				},
			}
		}
	},
	watch: {
		'supportNote.title': function(newVal, oldVal) {
			this.fieldChanged(this.supportNote, newVal, oldVal, 'title')
		},
		'supportNote.text': function(newVal, oldVal) {
			this.fieldChanged(this.supportNote, newVal, oldVal, 'text')
		},
		'supportNote.color': function(newVal, oldVal) {
			this.fieldChanged(this.supportNote, newVal, oldVal, 'color')
		},
	}
}


// export const CreateInvoiceValidation = {
// 	mixins: [BASE_VALIDATION],
// 	data(){
// 		return {
// 			validationRules: {
// 				dateRange: {
// 					required,
// 				},
// 			},
// 		}
// 	},
// 	watch: {
// 		'client.companyName': function(newVal, oldVal) {
// 			this.fieldChanged(this.client, newVal, oldVal, 'companyName');
// 		},
// 		'client.firstName': function(newVal, oldVal) {
// 			this.fieldChanged(this.client, newVal, oldVal, 'firstName');
// 		},
// 		'client.lastName': function(newVal, oldVal) {
// 			this.fieldChanged(this.client, newVal, oldVal, 'lastName');
// 		},
// 		'client.email': function(newVal, oldVal) {
// 			this.fieldChanged(this.client, newVal, oldVal, 'email');
// 		},
// 		'client.zohoTaxExemptionId': function(newVal, oldVal) {
// 			this.fieldChanged(this.client, newVal, oldVal, 'zohoTaxExemptionId');
// 		},
// 		'client.zohoTaxAuthorityId': function(newVal, oldVal) {
// 			this.fieldChanged(this.client, newVal, oldVal, 'zohoTaxAuthorityId');
// 		},
// 	}
// }
export const InvoiceValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				client: {
					requiredObject,
				},
				subscription: {
					requiredObject,
				},
				dateRangeStart: {
					required,
				},
				paymentTermsObject: {
					requiredObject
				},
				primaryContact: {
					required
				},
				isTaxable: {
					required
				},
				zohoTaxId: {
					requiredIf: {
						method: requiredIf,
						params: [() => this.invoice.isTaxable]
					}
				},
				zohoTaxExemptionId: {
					requiredIf: {
						method: requiredIf,
						params: [() => !this.invoice.isTaxable]
					}
				},
				zohoTaxAuthorityId: {
					requiredIf: {
						method: requiredIf,
						params: [() => !this.invoice.isTaxable]
					}
				}
			},
			errorMessages: {
				zohoTaxExemptionId: {
					requiredIf: 'Tax Exemption is required for tax-exempt customers.'
				},
				zohoTaxAuthorityId: {
					requiredIf: 'Tax Authority is required for tax-exempt customers.'
				},
			},
			creditNoteErrors: {},
		}
	},
	watch: {
		'invoice.client': function(newVal, oldVal){
			this.fieldChanged(this.invoice, newVal, oldVal, 'client');
		},
		'invoice.subscription': function(newVal, oldVal){
			this.fieldChanged(this.invoice, newVal, oldVal, 'subscription');
		},
		'invoice.dateRangeStart': function(newVal, oldVal){
			this.fieldChanged(this.invoice, newVal, oldVal, 'dateRangeStart');
		},
		'invoice.isTaxable': function(newVal, oldVal) {
			this.fieldChanged(this.invoice, newVal, oldVal, 'isTaxable');
		},
		'invoice.zohoTaxId': function(newVal, oldVal) {
			this.fieldChanged(this.invoice, newVal, oldVal, 'zohoTaxId');
		},
		'invoice.zohoTaxExemptionId': function(newVal, oldVal) {
			this.fieldChanged(this.invoice, newVal, oldVal, 'zohoTaxExemptionId');
		},
		'invoice.zohoTaxAuthorityId': function(newVal, oldVal) {
			this.fieldChanged(this.invoice, newVal, oldVal, 'zohoTaxAuthorityId');
		},
		'invoice.paymentTermsObject': function(newVal, oldVal) {
			this.fieldChanged(this.invoice, newVal, oldVal, 'paymentTermsObject');
		},
		'invoice.primaryContact': function(newVal, oldVal) {
			this.fieldChanged(this.invoice, newVal, oldVal, 'primaryContact');
		},
	},
	methods: {
		validateCreditNotes(){
			const creditNoteErrors = {}
			let errorCount = 0
			this.creditNotes.forEach(cn => {
				const itemErrors = {}
				cn.lineItems.forEach(li => {
					if (!li.description){
						itemErrors.description = 'Description is required.'
						errorCount++
					}
					if (Number(li.rate) <= 0){
						itemErrors.rate = 'Credit amount must be greater than zero.'
						errorCount++
					}
				})
				if (Object.keys(itemErrors).length > 0){
					creditNoteErrors[cn.id] = itemErrors
				}
			})
			this.creditNoteErrors = creditNoteErrors
			return errorCount == 0
		},
	}
}

export const CreditLineItemValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				description: {
					required,
				},
				quantity: {
					required,
					numeric,
					minValue: {
						method: minValue,
						params: [0.01]
					}
				},
				rate: {
					required,
					numeric,
					minValue: {
						method: minValue,
						params: [0]
					}
				},
			},
			errorMessages: {
				quantity: {
					minValue: 'Value must be greater than zero.'
				},
				rate: {
					minValue: 'value must not be negative.'
				},
			}
		}
	},
	watch: {
		'editingLineItem.description': function(newVal, oldVal) {
			this.fieldChanged(this.editingLineItem, newVal, oldVal, 'description')
		},
		'editingLineItem.quantity': function(newVal, oldVal) {
			this.fieldChanged(this.editingLineItem, newVal, oldVal, 'quantity')
		},
		'editingLineItem.rate': function(newVal, oldVal) {
			this.fieldChanged(this.editingLineItem, newVal, oldVal, 'rate')
		},
	}
}

export const VoidInvoiceValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				notes: {
					required,
					maxLength: {
						method: maxLength,
						params: [255]
					}
				},
			},
			errorMessages: {
				notes: {
					maxLength: 'Note can not be more than 255 characters long.'
				},
			}
		}
	},
	watch: {
		'notes': function(newVal, oldVal) {
			this.fieldChanged(this, newVal, oldVal, 'notes')
		},
	}
}

export const SLAPlanValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				title: {
					required,
				},
				rate: {
					required,
					numeric,
				},
				hours: {
					required,
					numeric,
				},
			},
		}
	},
	watch: {
		title: function(newVal, oldVal) {
			this.fieldChanged(this, newVal, oldVal, 'title')
		},
		rate: function(newVal, oldVal) {
			this.fieldChanged(this, newVal, oldVal, 'rate')
		},
		hours: function(newVal, oldVal) {
			this.fieldChanged(this, newVal, oldVal, 'hours')
		},
	}
}

export const ServiceTypeValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				name: {
					required,
				},
				abbreviation: {
					required,
					maxLength: {
						method: maxLength,
						params: [10]
					},
				},
				color: {
					required,
				},
				allowedBillingTypes: {
					minLength: {
						method: minLength,
						params: [1]
					}
				},
			},
			errorMessages: {
				allowedBillingTypes: {
					minLength: 'At least one billing type is required.'
				},
			}
		}
	},
	watch: {
		'serviceType.name': function(newVal, oldVal) {
			this.fieldChanged(this.serviceType, newVal, oldVal, 'name')
		},
		'serviceType.abbreviation': function(newVal, oldVal) {
			this.fieldChanged(this.serviceType, newVal, oldVal, 'abbreviation')
		},
		'serviceType.color': function(newVal, oldVal) {
			this.fieldChanged(this.serviceType, newVal, oldVal, 'color')
		},
		'serviceType.allowedBillingTypes': function(newVal, oldVal) {
			this.fieldChanged(this.serviceType, newVal, oldVal, 'allowedBillingTypes')
		},
	}
}

export const AgreementValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				serviceType: {
					requiredObjectIf: {
						method: requiredObjectIf,
						params: [()=> this.agreement.agreementLevel == window.constants.AGREEMENT_LEVEL_SERVICE ]
					}
				},
				name: {
					required,
				},
				notificationContact: {
					requiredObject
				},
				extensionDays: {
					integer,
					minValue: {
						method: minValue,
						params: [0]
					},
					maxValue: {
						method: maxValue,
						params: [365]
					}
				},
				inputAttachments: {
					requiredFileIf: {
						method: requiredFileIf,
						params: [()=> this.agreement.billingType !== window.constants.BILLING_TYPE_INTERNAL && !(this.agreement.attachments || []).length ]
					}
				},
				street: {
					required: {
						method: required,
						params: [],
						getSubModel: (model) => (model.notificationContact || {address:{}}).address
					}
				},
				city: {
					required: {
						method: required,
						params: [],
						getSubModel: (model) => (model.notificationContact || {address:{}}).address
					}
				},
				state: {
					required: {
						method: required,
						params: [],
						getSubModel: (model) => (model.notificationContact || {address:{}}).address
					}
				},
				zip: {
					required: {
						method: required,
						params: [],
						getSubModel: (model) => (model.notificationContact || {address:{}}).address
					}
				},
				country: {
					required: {
						method: required,
						params: [],
						getSubModel: (model) => (model.notificationContact || {address:{}}).address
					}
				},
				flatFee: {
					numeric,
				},
				projectHourlyRate: {
					numeric,
				},
				retainerAmount: {
					numeric,
				},
				additionalOneTimeRetainer: {
					numeric,
				},
				adminFee: {
					numeric,
				},
				monthlyRecurringFixedFee: {
					numeric,
				},
				retainedHours: {
					numeric,
				},
			},
			errorMessages: {
				extensionDays: {
					minValue: 'Value can not be less than than zero.',
					maxValue: 'Value can not be greater than than 365.'
				},
				inputAttachments: {
					requiredFileIf: 'Please include at least one attachment.'
				},
			}
		}
	},
	watch: {
		'agreement.serviceType': function(newVal, oldVal) {
			this.fieldChanged(this.agreement, newVal, oldVal, 'serviceType')
		},
		'agreement.name': function(newVal, oldVal) {
			this.fieldChanged(this.agreement, newVal, oldVal, 'name')
		},
		'agreement.extensionDays': function(newVal, oldVal) {
			this.fieldChanged(this.agreement, newVal, oldVal, 'extensionDays')
		},
		'agreement.inputAttachments': function(newVal, oldVal) {
			this.fieldChanged(this.agreement, newVal, oldVal, 'inputAttachments')
		},
		'agreement.notificationContact': function(newVal, oldVal) {
			this.fieldChanged(this.agreement, newVal, oldVal, 'notificationContact')
		},
		'agreement.notificationContact.address.street': function(newVal, oldVal) {
			this.fieldChanged(this.agreement, newVal, oldVal, 'street')
		},
		'agreement.notificationContact.address.city': function(newVal, oldVal) {
			this.fieldChanged(this.agreement, newVal, oldVal, 'city')
		},
		'agreement.notificationContact.address.state': function(newVal, oldVal) {
			this.fieldChanged(this.agreement, newVal, oldVal, 'state')
		},
		'agreement.notificationContact.address.zip': function(newVal, oldVal) {
			this.fieldChanged(this.agreement, newVal, oldVal, 'zip')
		},
		'agreement.notificationContact.address.country': function(newVal, oldVal) {
			this.fieldChanged(this.agreement, newVal, oldVal, 'country')
		},
		'agreement.flatFee': function(newVal, oldVal) {
			this.fieldChanged(this.agreement, newVal, oldVal, 'flatFee')
		},
		'agreement.projectHourlyRate': function(newVal, oldVal) {
			this.fieldChanged(this.agreement, newVal, oldVal, 'projectHourlyRate')
		},
		'agreement.retainerAmount': function(newVal, oldVal) {
			this.fieldChanged(this.agreement, newVal, oldVal, 'retainerAmount')
		},
		'agreement.additionalOneTimeRetainer': function(newVal, oldVal) {
			this.fieldChanged(this.agreement, newVal, oldVal, 'additionalOneTimeRetainer')
		},
		'agreement.adminFee': function(newVal, oldVal) {
			this.fieldChanged(this.agreement, newVal, oldVal, 'adminFee')
		},
		'agreement.monthlyRecurringFixedFee': function(newVal, oldVal) {
			this.fieldChanged(this.agreement, newVal, oldVal, 'monthlyRecurringFixedFee')
		},
		'agreement.retainedHours': function(newVal, oldVal) {
			this.fieldChanged(this.agreement, newVal, oldVal, 'retainedHours')
		},

	}
}

export const AgreementTerminationValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				terminationType: {
					required,
				},
				terminationNote: {
					required,
				},
				inputAttachments: {
					requiredFileIf: {
						method: requiredFileIf,
						params: [()=> this.terminationType != 'KDG' ]
					}
				},
			},
			errorMessages: {
				inputAttachments: {
					requiredFileIf: 'Please include at least one attachment.'
				},
			}
		}
	},
	watch: {
		'terminationType': function(newVal, oldVal) {
			this.fieldChanged(this, newVal, oldVal, 'terminationType')
		},
		'terminationNote': function(newVal, oldVal) {
			this.fieldChanged(this, newVal, oldVal, 'terminationNote')
		},
		'inputAttachments': function(newVal, oldVal) {
			this.fieldChanged(this, newVal, oldVal, 'inputAttachments')
		},
	}
}
export const ServiceLinksValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				linkToGoogleDrive: {
					url: {
						method: url,
						params: [],
						getFieldValue: (model) => (model.linkToGoogleDrive || {}).linkUrl
					}
				},
				linkToKDGRM: {
					url: {
						method: url,
						params: [],
						getFieldValue: (model) => (model.linkToKDGRM || {}).linkUrl
					}
				},
				linkToKali: {
					url: {
						method: url,
						params: [],
						getFieldValue: (model) => (model.linkToKali || {}).linkUrl
					}
				},
				linkToRDM: {
					url: {
						method: url,
						params: [],
						getFieldValue: (model) => (model.linkToRDM || {}).linkUrl
					}
				},
				linkToSynology: {
					url: {
						method: url,
						params: [],
						getFieldValue: (model) => (model.linkToSynology || {}).linkUrl
					}
				},
				linkToZohoCRM: {
					url: {
						method: url,
						params: [],
						getFieldValue: (model) => (model.linkToZohoCRM || {}).linkUrl
					}
				},
			},
		}
	},
	watch: {
		'model.linkToGoogleDrive': function(newVal, oldVal) {
			this.fieldChanged(this.model, newVal, oldVal, 'linkToGoogleDrive')
		},
		'model.linkToKDGRM': function(newVal, oldVal) {
			this.fieldChanged(this.model, newVal, oldVal, 'linkToKDGRM')
		},
		'model.linkToKali': function(newVal, oldVal) {
			this.fieldChanged(this.model, newVal, oldVal, 'linkToKali')
		},
		'model.linkToRDM': function(newVal, oldVal) {
			this.fieldChanged(this.model, newVal, oldVal, 'linkToRDM')
		},
		'model.linkToSynology': function(newVal, oldVal) {
			this.fieldChanged(this.model, newVal, oldVal, 'linkToSynology')
		},
		'model.linkToZohoCRM': function(newVal, oldVal) {
			this.fieldChanged(this.model, newVal, oldVal, 'linkToZohoCRM')
		},
	}
}

export const UserValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				userName: {
					required,
				},
				email: {
					required,
					email,
				},
				groups: {
					required,
				},
				isLocked: {}
			},
		}
	},
	watch: {
		'user.userName': function(newVal, oldVal) {
			this.fieldChanged(this.user, newVal, oldVal, 'userName')
		},
		'user.email': function(newVal, oldVal) {
			this.fieldChanged(this.user, newVal, oldVal, 'email')
		},
		'user.groups': function(newVal, oldVal) {
			this.fieldChanged(this.user, newVal, oldVal, 'groups')
		},
	}
}

export const ApplyCreditToInvoiceValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		let self = this
		return {
			validationRules: {
				selectedInvoice: {
					requiredObject,
				},
				amount: {
					required,
					maxValue: {
						method: self.customMaxValCheck,
						params: []
					},
					minValue: {
						method: minValue,
						params: [0.01]
					},
					numeric,
				},
			},
			errorMessages: {
				amount: {
					maxValue: self.maxValueError
				},
			}
		}
	},
	methods: {
		customMaxValCheck(value){
			return Number(value) <= this.maxAmount
		},
		maxValueError(){
			if (Number(this.amount) > Number((this.creditNote || {}).balance)){
				return 'Not enough credit available.'
			}
			if (Number(this.amount) > Number((this.selectedInvoice || {}).balance)){
				return 'Can\'t add more than the remaining balance on the invoice.'
			}
			return 'Amount is too high.'
		}
	},
	computed:{
		maxAmount(){
			return Math.min(Number((this.creditNote || {}).balance || Infinity), Number((this.selectedInvoice || {}).balance || Infinity))
		},
	},
	watch: {
		'selectedInvoice': function(newVal, oldVal) {
			this.fieldChanged(this, newVal, oldVal, 'selectedInvoice')
		},
		'amount': function(newVal, oldVal) {
			this.fieldChanged(this, newVal, oldVal, 'amount')
		},
	}
}
export const ConfirmRateChangeValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				changeNowOrLater: {},
				changingOnDate: {
					requiredIf: {
						method: requiredIf,
						params: [()=> this.changeNowOrLater == 'later' ]
					}
				},
			},
		}
	},
	watch: {
		'changingOnDate': function(newVal, oldVal) {
			this.fieldChanged(this, newVal, oldVal, 'changingOnDate')
		},
		'changeNowOrLater': function(newVal, oldVal) {
			this.fieldChanged(this, newVal, oldVal, 'changeNowOrLater')
		},
	}
}
export const TimelineActionValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				date: {
					required,
				},
				rateElevationPercent: {
					requiredIf: {
						method: requiredIf,
						params: [()=> this.item.actionType == 'RateElevation' ]
					}
				},
				email: {
					email,
					requiredIf: {
						method: requiredIf,
						params: [()=> this.item.actionType == 'Email' ]
					}
				},
			},
		}
	},
	watch: {
		'model.date': function(newVal, oldVal) {
			this.fieldChanged(this.model, newVal, oldVal, 'date')
		},
		'model.rateElevationPercent': function(newVal, oldVal) {
			this.fieldChanged(this.model, newVal, oldVal, 'rateElevationPercent')
		},
		'model.email': function(newVal, oldVal) {
			this.fieldChanged(this.model, newVal, oldVal, 'email')
		},
	}
}

// export const CreateServiceStep3Validation = {
// 	mixins: [AgreementValidation],
// 	data(){
// 		return {
// 			validationRules: {
// 				newOrExisting: {
// 					required
// 				},
// 				selectedExistingAgreement: {
// 					requiredObjectIf: {
// 						method: requiredObjectIf,
// 						params: [() => this.newOrExisting == 'Existing']
// 					},
// 				}
// 			},
// 			errorMessages: {
// 				selectedExistingAgreement: {
// 					requiredObjectIf: 'An agreement is required.'
// 				},
// 			}
// 		}
// 	},
// 	watch: {
// 		newOrExisting: function(newVal, oldVal) {
// 			this.fieldChanged(this, newVal, oldVal, 'newOrExisting')
// 		},
// 		selectedExistingAgreement: function(newVal, oldVal) {
// 			this.fieldChanged(this, newVal, oldVal, 'selectedExistingAgreement')
// 		},
// 	}
// }

export const CreateServiceStep3Validation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				serviceType: {
					requiredObject,
					mspCheck: {
						params: [()=>this.service.billingType, parseInt(this.client.p9CustomerId)],
						method(serviceType, billingType, p9CustomerId){
							return !(serviceType
								&& serviceType.id == window.constants.MSP_SERVICE_TYPE_ID
								&& billingType() != window.constants.BILLING_TYPE_AD_HOC
								&& billingType() != window.constants.BILLING_TYPE_INTERNAL
								&& p9CustomerId > 0
							)
						},
					}
				},
				billingType: {
					required
				},
			},
			errorMessages: {
				serviceType: {
					requiredObject: 'Please select the service type.',
					mspCheck: 'This client is already linked to a P9 account. Currently, there can only be one Standard MSP service per client.',
				},
			}
		}
	},
	watch: {
		'service.serviceType': function(newVal, oldVal) {
			this.fieldChanged(this.service, newVal, oldVal, 'serviceType')
		},
		'service.billingType': function(newVal, oldVal) {
			this.fieldChanged(this.service, newVal, oldVal, 'billingType')
			this.fieldChanged(this.service, this.service.serviceType, this.service.serviceType, 'serviceType')
		},
	}
}

export const InvoiceRateChangeValidation = {
	mixins: [BASE_VALIDATION],
	data(){
		return {
			validationRules: {
				price: {
					numeric
				},
				quantity: {
					integer
				},
				amount: {
					numeric
				},
			},
		}
	},
	watch: {
		'service.price': function(newVal, oldVal) {
			this.fieldChanged(this.service, newVal, oldVal, 'price');
		},
		'service.hourlyRate': function(newVal, oldVal) {
			this.fieldChanged(this.service, newVal, oldVal, 'hourlyRate');
		},
		'service.quantity': function(newVal, oldVal) {
			this.fieldChanged(this.service, newVal, oldVal, 'quantity');
		},
	}
}
