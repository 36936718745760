<template>
	<modal
		:title="title"
		color="danger"
		:text="text"
		:show="show"
		:closeOnBackdrop="true"
		@closed="show = false"
	>
		<div slot="footer" class="w-100">
			<CButton size="sm" class="float-right" color="danger" @click="show = false">
				Close
			</CButton>
		</div>
	</modal>
</template>

<script>
export default {
	name: 'AlertModal',
	data(){
		return {
			title: 'Error',
			text: '',
			show: false,
		}
	},
	methods: {
		showAlert(message, title){
			title = title || 'Error'
			this.title = title
			this.text = message
			this.show = true
		}
	}
}

</script>
<!--

// <template>
// 	<modal
// 		:title="alert.title"
// 		color="danger"
// 		:text="alert.text"
// 		:show="Boolean(alert)"
// 		:closeOnBackdrop="true"
// 		@closed="show = false"
// 	>
// 		<div slot="footer" class="w-100">
// 			<CButton size="sm" class="float-right" color="danger" @click="alert = null">
// 				Close
// 			</CButton>
// 		</div>
// 	</modal>
// </template>

// <script>
// export default {
// 	name: 'AlertModal',
// 	props: ['value'],
// 	computed: {
// 		alert: {
// 			get() { return this.value },
// 			set(v) { this.$emit('input', v) }
// 		}
// 	},
// 	// data(){
// 	// 	return {
// 	// 		title: 'Error',
// 	// 		text: '',
// 	// 		show: false,
// 	// 	}
// 	// },
// 	methods: {
// 		showAlert(message, title){
// 			title = title || 'Error'
// 			// this.title = title
// 			// this.text = message
// 			// this.show = true

// 			this.alert = {
// 				title: title,
// 				text: message,
// 			}
// 		}

// 	}
// }
// </script>
-->
