<template>
	<lookup
		:preloadedOptions="options"
		:label="label !== false ? (label || 'Bill To') : false"
		v-model="selected"
		:isValid="isValid"
		:invalidFeedback="invalidFeedback"
		displayKey="fullName"
		@change="$emit('change', $event)"
		labelClass="col-form-label col-sm-3"
		:fieldClass="fieldClass || 'col-sm-8'"
		disabled="disabled"
	>
		<template #tooltip>
			<CIcon v-if="Boolean(label)" name="cid-info-circle" class="icon-tooltip" v-c-tooltip="'Want to change this user? Go to the Edit Client screen and update the main contact.'"/>
		</template>
		<template #extra>
			<slot name="ccButton" class="col-sm-1"></slot>
		</template>
	</lookup>
</template>

<script>

import Lookup from '../generic/Lookup'

export default {
	props: ['value', 'isValid', 'invalidFeedback', 'label', 'clientId', 'level', 'options', 'fieldClass'],
	components: {Lookup},
	computed: {
		selected: {
			get(){
				return this.value;
			},
			set(v){
				this.$emit('input', v);
			}
		},
	}
}
</script>
