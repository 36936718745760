<template>
    <Popup
        ref="actionItemPopup"
        :wrapper-attr="{id:'actionItemPopup'}"
        :title="`Action Item ${type ? '- ' + type : ''}`"
        :native-scroll="true"
		:equal-padding="true"
        width="60%"
        height="65%"
        @hidden="onHidden">
        <LoadPanel elementId="#actionItemPopupContent" :shading="false" :visible="loading" />
        <CRow :key="contentKey" id="actionItemPopupContent" class="h-100" v-if="authorized">
            <CCol lg="6">
                <div class="d-flex justify-content-between align-items-center">
                    <h6><strong>Employee: </strong>{{actionItem.user.fullName}}</h6>
                    <div v-if="actionItem.approvable">
                        <CustomDxButtonVue
                            :text="'Approve Credit'"
                            :custom-class="'primary-outline'"
                            @click="applyCreditNote(actionItem.creditNote)"
                        />
                    </div>
                </div>
                <h6><strong>Date: </strong>{{actionItem.timestamp | formatDateTime}}</h6>
                <h6 v-if="actionItem.user.supervisor"><strong>Supervisor: </strong>{{actionItem.user.supervisor.fullName}}</h6>
                <h6 v-if="actionItem.clientName"><strong>Client: </strong>{{actionItem.clientName}}</h6>
                <h6 v-if="actionItem.projectName"><strong>Service: </strong>{{actionItem.projectName}}</h6>
                <div class="p-4 simple-border" v-if="actionItem.creditNote && actionItem.creditNote.invoiceId == null && actionItem.approvable">
                    <div class="mb-2">
                        <h6 class="mb-4"><strong>Update Credit Note Amount</strong></h6>
                        <div class="mt-2 d-flex justify-content-between">
                            <CurrencyLookupVue
                                :amount="Number(actionItem.creditNote.total)" 
                                :placeholder="'Update Credit Amount'"
                                class="mr-2"
                                @valueChanged="onCreditValueChange($event)"
                            />
                            <CustomDxButtonVue
                                :text="'Save'"
                                :custom-class="'primary w-25'"
                                :disabled="savingCreditNote"
                                @click="onSaveCreditNote"
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4 mb-4">
                    <DxScrollView height="22em" :element-attr="{class: 'pb-4'}">
                        <div class="font-italic">"{{actionItem.content}}"</div>
                    </DxScrollView>
                </div>
                <div style="position: fixed; width: 47%; bottom: 5px;">
                    <div
                        v-if="alert === 'Opened'"
                        class="text-center pointer alert alert-success"
                        @click="onClickClosed()">Close Issue
                    </div>
                    <div
                        v-if="alert === 'Resolving'"
                        class="text-center pointer alert alert-warning"
                        @click="onClickResolution()">Resolution Pending
                    </div>
                    <div
                        v-if="alert === 'Closed'"
                        class="text-center pointer alert alert-secondary"
                        @click="onClickReopen()">Re-Open
                    </div>
                </div>
            </CCol>
            <CCol lg="6" class="h-100">
                <form @submit="submit($event)" v-if="actionItem.isOpen" class="mb-3">
                    <div>
                        <DxHtmlEditor
                            :key="editorKey"
                            :mentions="mentions"
                            v-model="actionItemComment.textHtml"
                            ref="htmlEditor"
                            placeholder="Type in resolution." />
                        <button class="btn editor-send-btn"> <CIcon name="cid-send"/></button>
                    </div>
                </form>
                <DxScrollView :height="actionItem.isOpen ? 'calc(100% - 142px)' : '100%'">
                    <div class="action-item-comments">
                        <div class="comment-body" v-for="comment in actionItem.comments" :key="comment.id">
                            <div class="d-flex align-items-center justify-content-between mb-2">
                                <CommentHeader :user="comment.user" :timestamp="comment.timestamp" />
                                <CBadge v-if="comment.commentBadge != 'None'" :color="badgeColor(comment.commentBadge)">
                                    {{comment.commentBadge}}
                                </CBadge>
                            </div>
                            <div class="comment-body-text"><span v-html="comment.textHtml"></span></div>
                        </div>
                    </div>
                </DxScrollView>
            </CCol>
        </CRow>
        <CCard class="h-100" v-else>
            <CCardBody>
                <h4>Access Denied.</h4>
                <p>You do not have permission to access this section.</p>
            </CCardBody>
        </CCard>
    </Popup>
</template>


<script>
import { AxiosWrapper } from '../../mixins'
import DxTextArea from 'devextreme-vue/text-area';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import CommentHeader from '../../components/CommentHeader'
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import DxButton from 'devextreme-vue/button';
import { DxHtmlEditor } from 'devextreme-vue/html-editor';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import CustomDxButtonVue from '../../components/generic/CustomDxButton.vue';
import CurrencyLookupVue from '../../components/lookups/CurrencyLookup.vue';
import { Lookup } from '../../components/lookups';

export default {
    name:'ActionItemModal',
    components:{
        DxTextArea,
        DxValidator,
        DxRequiredRule,
        CommentHeader,
        DxLoadPanel,
        DxButton,
        DxHtmlEditor,
        DxScrollView,
        CustomDxButtonVue,
        CurrencyLookupVue,
        Lookup
    },
    mixins: [ AxiosWrapper ],
    data(){
        return {
            type: '',
            loading: true,
            editorKey: this.generateUUID(),
            actionItem:{user:{supervisor:{}}, text:''},
            mentionText:'',
            actionItemComment:{},
            mentions: null,
            alert: 'Opened',
            contentKey:0,
            authorized: true,
            savingCreditNote: false,
        }
    },
    async created(){
        this.editorKey = this.generateUUID()
    },
    mounted (){
		this.$connectionHub.$on('new-action-comment', (comment) => {
            if(this.actionItem.id == comment.actionItem.id){
                this.actionItem.comments.unshift(comment)
            }
		});
  	},
    methods:{
        async show(options){
            this.loading = true
            this.$refs.actionItemPopup.show()
            this.type = options.type
            await this.load(options.id)
        },
        async load(actionItemId){
			try{
				const params = { userId: this.Current_User_ID, actionItemId: actionItemId }
				this.mentions = await this.getMentions(`api/action-items/mentions`, { params })
			}
			catch(e){
                console.log(e)
			}
            try{
                const response = await this.get(`api/action-items/${actionItemId}`)
                this.actionItem = response.data
                this.alert = this.actionItem.isOpen ? 'Opened' : 'Closed'
                this.loading = false
                this.contentKey++
                if(this.actionItem){
                    this.post('api/action-items/comments/seen', this.actionItem.comments.map(s => s.id))
                }
            }
            catch(e){
                console.log(e)
                this.authorized = false
            }
        },
        async submit(e){
            e.preventDefault()
            this.actionItemComment.text = this.$refs.htmlEditor.$el.getElementsByClassName('dx-htmleditor-content')[0].innerText
            if(this.actionItemComment.text === '') return;
            this.loading = true
            this.actionItemComment.userId = this.Current_User_ID
            this.actionItemComment.actionItemId = this.actionItem.id
            this.actionItemComment.mentionedUsers = []
            this.actionItemComment.timestamp = new Date()
            let dxMentions = this.$refs.htmlEditor.$el.getElementsByClassName('dx-htmleditor-content')[0].getElementsByClassName('dx-mention')
            for (let index = 0; index < dxMentions.length; index++) {
                this.actionItemComment.mentionedUsers.push(dxMentions[index].getAttribute('data-id'))
            }
            try{
                await this.post('api/action-items/comment', this.actionItemComment)
                this.actionItemComment = {}
                this.$refs.htmlEditor.instance.reset()
                await this.load(this.actionItem.id)
            }
            finally{
                this.loading = false
            }
        },
        onClickClosed(){
            this.alert = 'Resolving'
            this.actionItemComment.commentBadge = 'Resolution'
            this.$refs.htmlEditor.$el.classList.add('warning-border')
        },
        onClickResolution(){
            this.alert = this.actionItem.isOpen ? 'Opened' : 'Closed'
            this.actionItemComment.commentBadge = 'None'
            this.$refs.htmlEditor.$el.classList.remove('warning-border')
        },
        async onClickReopen(){
            this.loading = true
            await this.post('api/action-items/comment', {
                commentBadge: 'ReOpened',
                actionItemId: this.actionItem.id,
                userId: this.Current_User_ID
            })
            await this.load(this.actionItem.id)
        },
        badgeColor(badge){
           if(badge === 'Resolution') return 'success'
           else if(badge === 'ReOpened') return 'secondary'
        },
        onHidden(){
            this.$emit('hidden')
        },
        onCreditValueChange(value){
            this.actionItem.creditNote.total = value;
        },
        async onSaveCreditNote(){
            try{
                this.savingCreditNote = true;
                let response = await this.post(`api/invoices/upateCreditNote`, this.actionItem.creditNote);
                window.showSuccessToast("Credit is saved successfully.");
                this.onHidden();
            }
            catch(e){
                window.showErrorToast(e.message);
            }
            finally{
                this.savingCreditNote = false;
            }
        },

        async applyCreditNote(creditNote){
            try{
                this.savingCreditNote = true;
                let input = {
                    creditNoteId: creditNote.id,
                    amount: creditNote.total,
                    zohoCustomerId: creditNote.client.zohoCustomerId
                }
                let response = await this.post(`api/invoices/applyCreditNoteOnInvoice`, input);
                this.actionItem.creditNote = response.data;
                this.actionItem.approvable = false;
                window.showSuccessToast("Credit note is applied");
                this.savingCreditNote = false;
                this.onHidden();
            }
            catch(e){
                window.showErrorToast(e.message);
                this.savingCreditNote = false;
            }
        },
    },
}
</script>
<style>
#actionItemPopup .dx-htmleditor{
   height: 125px;
}
#actionItemPopup .dx-htmleditor-content{
    height: 95px
}
.action-item-comments .comment-body-text p{
    margin-bottom: 5px;
}
.action-item-comments .comment-body{
    margin-bottom: 20px;
}
.simple-border{
    border: 1px solid #ddd !important;
    border-radius: 6px !important;
}
.warning-border{
    border: 2px solid #ffc04e !important;
}
#actionItemPopup .dx-htmleditor.dx-htmleditor-outlined.dx-state-active.warning-border,
#actionItemPopup .dx-htmleditor.dx-htmleditor-outlined.dx-state-focused.warning-border{
    border-color: #ffc04e !important
}
#actionItemPopup .editor-send-btn{
    position: absolute;
    right: 15px;
    top: 86px;
}
#actionItemPopup .col-lg-6 .dx-scrollview-content{
    padding-right: 15px;
}
#creditNotesInvoicesSelectBox .dx-texteditor-container{
    padding: 7px;
}
</style>
