import Vue from 'vue';
import moment from 'moment';

Vue.filter('formatDateTime', function(value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY hh:mm a')
    }
});
Vue.filter('shortDate',function(value){
    if(value){
        return moment(String(value)).format('MM/DD/YYYY')
    }
});
Vue.filter('timeOnly',function(value){
    if(value){
        return moment.utc(String(value)).local().format('hh:mma')
    }
});
Vue.filter('formatUtcToLocalDateTime', function(value) {
    if (value) {
        return moment.utc(String(value)).local().format('MM/DD/YYYY hh:mm a')
    }
});
Vue.filter('shortDateLocal',function(value){
    if(value){
        return moment.utc(String(value)).local().format('MM/DD/YYYY')
    }
});
Vue.filter('timeOnlyLocal',function(value){
    if(value){
        return moment.utc(String(value)).local().format('hh:mma')
    }
});
Vue.filter('elapsedTime', function(value) {
    if (value) {
        return moment(value).fromNow();
    }
});
Vue.filter('MMslashDD',function(value){
    if(value){
        return moment(String(value)).format('MM/DD')
    }
});
Vue.filter('MMM_DD',function(value){
    if(value){
        return moment(String(value)).format('MMMM DD')
    }
});
Vue.filter('DDD',function(value){
    if(value){
        return moment(String(value)).format('DDD')
    }
});
Vue.filter('DDD',function(value){
    if(value){
        return moment(String(value)).format('DDD')
    }
});
Vue.filter('dayAndDate',function(value){
    if(value){
        return moment(String(value)).format('dddd MM/DD/YYYY')
    }
});
Vue.filter('percentage',function(value){
    return typeof value == 'string' || typeof value == 'number'?  (Number(value) * 100).toFixed(2)+'%' : ''
});
Vue.filter('currency',function(value){
    return typeof value == 'string' || typeof value == 'number'?  '$' + (Number(value)).toFixed(2) : ''
});
Vue.filter('twoDecimals',function(value){
    return typeof value == 'string' || typeof value == 'number'?  (Number(value)).toFixed(2) : ''
});
Vue.filter('oneDecimal',function(value){
    return typeof value == 'string' || typeof value == 'number'?  (Number(value)).toFixed(1) : ''
});
Vue.filter('USFormat',function(value){
    if(value){
        return Number(value).toLocaleString("en-US")
    }
});
Vue.filter('minutesToDecimal', function(val){
    if(val){
        return Math.round(val / 60 * 100) / 100
    }
    return ''
})

Vue.directive('color', (el, binding) => {
	el.style.color = binding.value
})
Vue.directive('bg-color', (el, binding) => {
	el.style.backgroundColor = binding.value
})
Vue.directive('width', (el, binding) => {
	el.style.width = binding.value
})
Vue.directive('height', (el, binding) => {
	el.style.height = binding.value
})
Vue.directive('top', (el, binding) => {
	el.style.top = binding.value
})
Vue.directive('bottom', (el, binding) => {
	el.style.bottom = binding.value
})
Vue.directive('right', (el, binding) => {
	el.style.right = binding.value
})
Vue.directive('left', (el, binding) => {
	el.style.left = binding.value
})
Vue.directive('fragments', {
    inserted: function(el) {
      const children = Array.from(el.children)
      const parent = el.parentElement
      children.forEach((item) => { parent.appendChild(item) })
      parent.removeChild(el)
    }
});
