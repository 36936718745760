var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DxSelectBox",
    {
      attrs: {
        "search-enabled": true,
        "data-source": _vm.statuses,
        "display-expr": "name",
        "value-expr": "id",
        placeholder: "Select Stage",
        "item-template": "item",
        disabled: _vm.disabled,
      },
      on: {
        opened: function ($event) {
          return _vm.$emit("opened", $event)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "item",
          fn: function (ref) {
            var data = ref.data
            return [
              _c(
                "div",
                {
                  style:
                    "padding:10px 15px !important; background:" + data.bgClr,
                },
                [
                  _c("div", [
                    _c(
                      "span",
                      [
                        data.icon
                          ? _c("Icon", {
                              attrs: { name: data.icon, "icon-pack": "atc" },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(data.name) + " ")]),
                  ]),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.statusId,
        callback: function ($$v) {
          _vm.statusId = $$v
        },
        expression: "statusId",
      },
    },
    [
      _vm._v(" "),
      _vm.required
        ? _c(
            "DxValidator",
            [
              _c("DxRequiredRule", {
                attrs: { message: "Project is required" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }