var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        text: _vm.text,
        title: _vm.modalTitle,
        color: _vm.confirmColor,
        show: _vm.show,
        size: _vm.modalSize,
        "close-on-backdrop": false,
      },
      on: { closed: _vm.cancel },
    },
    [
      _vm.innerComponent || _vm.rawHtml
        ? _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.innerComponent
                ? _c(
                    _vm.innerComponent,
                    _vm._b({ tag: "div" }, "div", _vm.innerComponentData, false)
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.rawHtml
                ? _c("div", { domProps: { innerHTML: _vm._s(_vm.rawHtml) } })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "w-100", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "CButton",
            {
              staticClass: "float-right ml-2",
              attrs: { size: "sm", color: _vm.confirmColor },
              on: { click: _vm.ok },
            },
            [_vm._v("\n\t\t\t" + _vm._s(_vm.confirmBtnText) + "\n\t\t")]
          ),
          _vm._v(" "),
          _c(
            "CButton",
            {
              staticClass: "float-right",
              attrs: { size: "sm", color: "secondary" },
              on: { click: _vm.cancel },
            },
            [_vm._v("\n\t\t\tCancel\n\t\t")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }