var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.multiple
        ? _c(
            "DxTagBox",
            {
              key: _vm.key,
              attrs: {
                "search-enabled": true,
                displayExpr: "name",
                "value-expr": "id",
                "data-source": _vm.departments,
                placeholder: "Select Departments",
                disabled: _vm.disabled,
                "read-only": _vm.readOnly,
                label: _vm.label,
                "label-mode": _vm.labelMode,
                "show-clear-button": _vm.showClearButton,
                multiline: _vm.multiline,
                "apply-value-mode": _vm.applyValueMode,
              },
              on: { "value-changed": _vm.onValueChanged },
              model: {
                value: _vm.selectedDepartmentsMultiple,
                callback: function ($$v) {
                  _vm.selectedDepartmentsMultiple = $$v
                },
                expression: "selectedDepartmentsMultiple",
              },
            },
            [
              _vm.required
                ? _c(
                    "DxValidator",
                    [
                      _c("DxRequiredRule", {
                        attrs: { message: "Departments are required" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c(
            "DxSelectBox",
            {
              attrs: {
                "search-enabled": true,
                displayExpr: "name",
                "value-expr": "id",
                "data-source": _vm.departments,
                placeholder: "Select Departments",
                disabled: _vm.disabled,
                "read-only": _vm.readOnly,
                "show-clear-button": _vm.showClearButton,
                label: _vm.label,
                "label-mode": _vm.labelMode,
              },
              on: { "value-changed": _vm.onValueChanged },
              model: {
                value: _vm.selectedDepartmentSingle,
                callback: function ($$v) {
                  _vm.selectedDepartmentSingle = $$v
                },
                expression: "selectedDepartmentSingle",
              },
            },
            [
              _vm.required
                ? _c(
                    "DxValidator",
                    [
                      _c("DxRequiredRule", {
                        attrs: { message: "Department is required" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }