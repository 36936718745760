var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Popup",
    {
      ref: "file-preview-popup",
      attrs: {
        title: "Attachment - " + _vm.name,
        height: _vm.size + "%",
        width: _vm.size + "%",
        "no-padding": true,
        "wrapper-attr": { class: "file-preview-popup" },
      },
      scopedSlots: _vm._u([
        {
          key: "header-after",
          fn: function () {
            return [
              _c(
                "div",
                [
                  _c("DxButton", {
                    attrs: { type: "plus" },
                    on: {
                      click: function ($event) {
                        return _vm.increaseSize()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("DxButton", {
                    attrs: { type: "minus" },
                    on: {
                      click: function ($event) {
                        return _vm.decreaseSize()
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      !_vm.loading
        ? _c("div", [
            _vm.getFileType(_vm.preview.path) == "Img"
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "width",
                        rawName: "v-width",
                        value: "100%",
                        expression: "'100%'",
                      },
                    ],
                  },
                  [
                    _c("div", { staticClass: "center" }, [
                      _c("div", [
                        _c("img", {
                          staticClass: "img-style",
                          attrs: { src: _vm.preview.data },
                        }),
                      ]),
                    ]),
                  ]
                )
              : _c("iframe", {
                  staticStyle: { overflow: "auto", height: "100vh" },
                  attrs: {
                    width: "100%",
                    frameborder: "0",
                    src: _vm.preview.data,
                  },
                }),
          ])
        : _c("spinner"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }