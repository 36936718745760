<template>
	<div role="group" :class="containerClass || 'form-group form-row lookup-field'">
		<!-- <label v-if="label" class="col-form-label col-sm-3"> {{label}}</label> -->
		<label v-if="label" :class="labelClass || 'col-form-label w-180'"> {{label}}</label>
		<div :class="fieldClass || 'width-300-c'">
			<v-select
				v-model="selected"
				:label="_displayKey"
				:options="sortedOptions"
				:placeholder="placeholderText"
				:class="{'is-invalid': !_isValid}"
				:invalidFeedback="invalidFeedback || ''"
				:clearable="clearable !== undefined ? clearable : true"
				:disabled="disabled"
				@input="onChange"
				:append-to-body="true"
				:multiple="multiple"
				:selectable="(option) => !option.heading"
			>
				<template #option="option">
					<h4 v-if="option.heading" class="mb-0" @click.prevent>{{ option[_displayKey] }}</h4>
					<span v-else class="ms-3">{{ option[_displayKey] }}</span>
				</template>
			</v-select>
			<input type="hidden" class="form-control is-invalid" />
			<div v-if="!_isValid" class="invalid-feedback">{{_invalidFeedback}}</div>
		</div>
		<slot name="extra"></slot>
	</div>
</template>

<style>
.is-invalid .vs__dropdown-toggle {
	border-color: #e55353;
}
.width-300-c{
	width: 300px;
}
</style>

<script>
import { AxiosWrapper } from '../../mixins';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
	props: ['value','label', 'placeholder', 'valueKey', 'displayKey',
		'isValid', 'invalidFeedback', 'options', 'clearable',
		'containerClass', 'labelClass', 'fieldClass', 'includeProperties', 'externalFilter',
		'disabled', 'sort', 'multiple'],
	components: {vSelect},
	mixins: [AxiosWrapper],
	data() {
		return {

		}
	},
	computed: {
		_isValid(){
			return this.isValid != null ? this.isValid : true
		},
		_invalidFeedback(){
			return this.invalidFeedback || '';
		},
		_valueKey(){
			return this.valueKey || 'id';
		},
		_displayKey(){
			return this.displayKey || 'name';
		},
		placeholderText(){
			return (this.placeholder !== false) ? this.placeholder || ('Select ' + (this.label ? this.label : '')) : '';
		},
		selected: {
			get(){
				return this.value ? this.options.find(item => this.matches(this.value, item)) : null
			},
			set(v){
				this.$emit('input', v);
			}
		},
		sortedOptions(){
			const filter = (this.externalFilter != null && this.externalFilter.constructor === Object) ? this.externalFilter : {};
			const options = (this.options || [])
				.filter(item => {
					let include = true;
					Object.keys(filter).forEach(key => {
						include = include && (!(filter[key]) || (typeof item === 'string' ? item == filter[key] : item[key] == filter[key]))
					});
					return include
				})
				// Make sure options are objects
				.map(item => (typeof item === 'string' ? this.stringToObject(item) : item ))
			if (this.sort !== false){
				return options.sort((a,b) => ((a[this._displayKey].toString().toLowerCase() < b[this._displayKey].toString().toLowerCase()) ? -1 : 1 ))
			}
			return options
		},
	},
	watch: {
		externalFilter(){
			this.$set(this, 'options', this.options)
			this.$forceUpdate()
		},
	},
	methods: {
		onChange(v){
			this.$emit('change', v)
		},
		stringToObject(value){
			const option = {}
			option[this._valueKey] = value
			option[this._displayKey] = value
			return option;
		},
		matches(a, b){
			a = typeof a == 'string' ? this.stringToObject(a) : a
			b = typeof b == 'string' ? this.stringToObject(b) : b
			return a[this._valueKey] === b[this._valueKey]
		}
	},
}
</script>
<style>
.vs__search, .vs__search:focus{
	font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
}
</style>

