var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("lookup", {
    attrs: {
      optionsEndpoint: "/api/users/permissionGroups",
      label: _vm.label || "Roles",
      displayKey: "displayName",
      valueKey: "name",
      labelClass: "col-form-label col-sm-3 required",
      fieldClass: "col-sm-9",
      isValid: _vm.isValid,
      invalidFeedback: _vm.invalidFeedback,
      multiple: true,
      horizontal: "",
      selectableFunction: _vm.isOptionSelectable,
      disabled: _vm.disable,
    },
    on: {
      change: function ($event) {
        return _vm.$emit("change", $event)
      },
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v
      },
      expression: "selected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }