<template>
	<CSelectWrapper
		:label="label !== undefined ? label : 'Level'"
		:options="options"
		v-model="level"
	>
		<template #tooltip><slot name="tooltip" /></template>
	</CSelectWrapper>
</template>

<script>
export default {
	props: ['value', 'label'],
	data(){
		return {
			options: [
				{label: 'Admin', value: 'Admin', },
				{label: 'Support', value: 'Support', },
				{label: 'Restricted Support', value: 'RestrictedSupport', },
				{label: 'Banned', value: 'Banned', },
				{label: 'Unauthorized', value: 'Unauthorized', },
			]
		}
	},
	computed: {
		level: {
			get(){
				return this.value
			},
			set(v){
				this.$emit('input', v)
			}
		}
	}
}
</script>
