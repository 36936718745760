<template>
    <span class="badge" :class="{
        'badge-success': type == 'External',
        'badge-info': type == 'InternalCharge',
        'badge-warning': type == 'Internal',
        'badge-secondary': type == 'None'}">
        {{ formatLabel(type) }}
    </span>
</template>

<script>
import { TextFormatter } from '../mixins'
export default {
    mixins: [TextFormatter],
    props: ['type']
}
</script>
