<template>
	<div name="authUsersPopupForm">
	<CheckPermission :value="constants.PERMISSION_UPDATE_SERVICES">
		<PopupForm size="lg" :title="title" :show="showEditForm" @click-submit="submit" @click-cancel="cancel" class="short-modal authorized-user-popup-form" >
			<spinner v-show="saveRequest" />
			<CForm v-if="authorizedUser && !saveRequest" @submit.prevent="submit">
				<CAlert color="danger" v-if="responseError">{{responseError}}</CAlert>
				<InputRadioGroup
					v-if="!authorizedUser.id && (!service || service.id != constants.EMPTY_GUID) && existingUserCount"
					label="New or Existing User?"
					v-model="newUserMode"
					:options="[{label:'Add New', value: 'new'}, {label:'Select Existing', value: 'existing'}, ]"
					class="custom-form-radios"
					@change="changeNewUserMode"
				>
					<template #tooltip>
						<CIcon name="cid-info-circle" class="icon-tooltip" v-c-tooltip="{placement: 'bottom', boundaries: 'window', content:'Do you want to create a new authorized user, or add an existing user to the selected service?'}"/>
					</template>
				</InputRadioGroup>

				<!-- <SubscriptionLookup
					v-if="(!service || service.id != constants.EMPTY_GUID) && !authorizedUser.id"
					v-model="authorizedUser.selectedService"
					:clientId="client.id"
					:isValid="isValid('selectedService')"
					:invalidFeedback="validationErrors.selectedService"
					:addLabelClasses="{required: isFieldRequired('selectedService')}"
					labelClass="col-form-label col-sm-3"
					fieldClass="col-sm-9"
					@change="authorizedUser.linkExistingUser = {}"
					@loaded-options="serviceOptionsLoaded"
					:multiple="false"
				/> -->

				<div v-show="newUserMode == 'existing'">
					<AuthorizedUserLookupSimplified
						v-model="authorizedUser.linkExistingUser"
						:otherServicesOnly="true"
						label="Select User"
						:service="authorizedUser.selectedService"
						:clientId="client.id"
						:isValid="isValid('linkExistingUser')"
						:invalidFeedback="validationErrors.linkExistingUser"
						:labelClass="{required: isFieldRequired('linkExistingUser'), 'col-form-label col-sm-3': true}"
						:level="level"
						@loaded-options="updateExistingUsers"
						@change="authorizedUser.selectedServices = []"
					/>
				</div>
				<SubscriptionLookup
					v-if="!service && !authorizedUser.id"
					v-model="authorizedUser.selectedServices"
					:clientId="client.id"
					:isValid="isValid('selectedServices')"
					:invalidFeedback="validationErrors.selectedServices"
					:queryParams="serviceLookupQueryParams"
					:labelClass="{required: isFieldRequired('selectedServices'), 'col-form-label col-sm-3': true}"
					fieldClass="col-sm-9"
					@loaded-options="serviceOptionsLoaded"
					:multiple="true"
				/>
				<span v-if="newUserMode == 'new'">
					<!-- <VSelectWrapper
						v-if="!service && authorizedUser.id"
						v-model="authorizedUser.selectedService"
						label="Service"
						displayKey="name"
						labelClass="col-form-label col-sm-3"
						fieldClass="col-sm-9"
						:options="linkedServices"
						:isValid="isValid('selectedService')"
						:invalidFeedback="validationErrors.selectedService"
						:addLabelClasses="{required: isFieldRequired('selectedService')}"
						:clearable="false"
						@change="onSelectServiceUser"
					/> -->

					<CAlert v-if="authorizedUser.contactPerson" color="info">NOTE: Changes to this user's name and email address will be saved for the contact person in Zoho Books.</CAlert>
					<CInput
						label="First Name"
						horizontal
						v-model="authorizedUser.firstName"
						:isValid="isValid('firstName')"
						:invalidFeedback="validationErrors.firstName"
						:addLabelClasses="{required: isFieldRequired('firstName')}"
					>
					</CInput>
					<CInput
						label="Last Name"
						horizontal
						v-model="authorizedUser.lastName"
						:isValid="isValid('lastName')"
						:invalidFeedback="validationErrors.lastName"
						:addLabelClasses="{required: isFieldRequired('lastName')}"
					>
					</CInput>
					<CInput
						label="Title"
						horizontal
						v-model="authorizedUser.title"
						:isValid="isValid('title')"
						:invalidFeedback="validationErrors.title"
						:addLabelClasses="{required: isFieldRequired('title')}"
					>
					</CInput>
					<CInput
						label="Email"
						horizontal
						v-model="authorizedUser.email"
						:isValid="isValid('email')"
						:invalidFeedback="validationErrors.email"
						:addLabelClasses="{required: isFieldRequired('email')}"
					>
					</CInput>
					<CInput
						label="Phone"
						horizontal
						v-model="authorizedUser.phone"
					>
					</CInput>
				</span>

				<div v-if="service || authorizedUser.selectedService || authorizedUser.selectedServices.length">
					<AuthUserLevelSelect
						v-model="authorizedUser.level"
						:isValid="isValid('level')"
						:invalidFeedback="validationErrors.level"
						:addLabelClasses="{required: isFieldRequired('level')}"
					>
						<template #tooltip>
							<label class="col-form-label col-sm-3"> Level
								<CIcon name="cid-info-circle" class="icon-tooltip" v-c-tooltip="{placement: 'right', boundaries: 'window', content:'If unsure of which level to choose, select Unauthorized and add a note below.'}"/>
							 </label>
						</template>
					</AuthUserLevelSelect>

					<CTextarea
						label="Notes"
						horizontal
						v-model="authorizedUser.notes"
						:isValid="isValid('notes')"
						:invalidFeedback="validationErrors.notes"
						:addLabelClasses="{required: isFieldRequired('notes')}"
					/>
				</div>
				<div v-else-if="authorizedUser.serviceLevelSettings && authorizedUser.id">
					<ServiceLevelOptions
						v-if="client"
						v-model="serviceLevelSettings"
						:authorizedUser="authorizedUser"
						:client="client"
						:removeServices.sync="servicesRemoved"
						:addServices.sync="servicesAdded"
						@changed-services="$emit('changed-services', $event)"
					 />
				</div>

				<CInputCheckbox
					v-if="service || authorizedUser.selectedService || authorizedUser.selectedServices.length"
					:checked.sync="authorizedUser.receiveFeedbackEmails"
                    class="custom-checkbox"
					label="Allow this person to receive feedback emails?"
				/>
				<CInputCheckbox
					v-if="service || authorizedUser.selectedService"
					:checked.sync="authorizedUser.canReceiveEmails"
                    class="custom-checkbox"
					label="Allow this person to receive emails from service?"
				/>
				<CInputCheckbox
					v-if="showAlwaysCc"
					:checked.sync="authorizedUser.alwaysCC"
                    class="custom-checkbox"
					label="Always CC this person on invoices?"
				/>
				<CInputCheckbox
                    v-if="service || authorizedUser.selectedService"
                    :checked.sync="authorizedUser.leftGoogleReview"
                    class="custom-checkbox"
                    label="Has this user left a Google review?"
				/>
				<!-- <CAlert v-else color="info">This person has opted out of the feedback emails.</CAlert> -->
				<!-- <CAlert v-else color="info" class="mt-2 py-2">You can add a user level, a note, and edit feedback settings for this client within each service.</CAlert> -->
				<span v-if="newUserMode == 'new'">
					<CInputCheckbox
						v-if="!authorizedUser.isBillingUser"
						:checked.sync="authorizedUser.makeContactUser"
                        class="custom-checkbox"
						label="Make this person available to CC on an invoice?"
						:description="makeContactUserDescription"
					>
						<template #description>
							<small class="form-text text-muted w-100" :class="authorizedUser.contactPerson ? 'danger' :'info'">
								({{makeContactUserDescription}})
							</small>
						</template>
					</CInputCheckbox>
					<CAlert v-else color="info" class="mt-2 py-2">This is the person who will recieve invoices.</CAlert>
				</span>
				<AddressForm
					v-if="newUserMode == 'new'"
					v-model="authorizedUser.address"
					label="Address"
					:openByDefault="requireAddress"
					:validationErrors="validationErrors"
					:isValidCallback="isValid"
					:billingAddress="client.billingAddress"
					:shippingAddress="client.shippingAddress"
					class="mt-4"
				/>
			</CForm>
		</PopupForm>
	</CheckPermission>
	</div>
</template>

<script>
import PopupForm from '../../components/generic/PopupForm'
import AddressForm from '../../components/AddressForm'
import { AuthorizedUserValidation } from '../../mixins/Validations'
import { AxiosWrapper } from '../../mixins'
import AuthUserLevelSelect from '../../components/AuthUserLevelSelect'
import SubscriptionLookup from '../../components/lookups/SubscriptionLookup'
import VSelectWrapper from '../../components/generic/VSelectWrapper'
import AuthorizedUserLookupSimplified from '../../components/lookups/AuthorizedUserLookupSimplified'
import ServiceLevelOptions from './ServiceLevelOptions.vue'

export default {
	mixins: [ AuthorizedUserValidation, AxiosWrapper ],
	props: ['value', 'client', 'service', 'requireAddress', 'level'],
	components: {
		PopupForm,
		AddressForm,
		AuthUserLevelSelect,
		VSelectWrapper,
		SubscriptionLookup,
		AuthorizedUserLookupSimplified,
		ServiceLevelOptions,
	},
	data(){
		return {
			saveRequest: null,
			responseError: '',
			newUserMode: 'new',
			existingUserCount: 0,
			serviceLevelSettings: [],
			servicesAdded: [],
			servicesRemoved: [],
		}
	},
	computed: {
		authorizedUser: {
			get() {
				const v = this.value
				if (v){
					v.address = v.address || {}
					v.linkExistingUser = v.linkExistingUser || {}
				}
				return v
			},
			set(v) { this.$emit('input', v) }
		},
		showEditForm(){
			return !!this.authorizedUser
		},
		submitMethod(){
			return (this.authorizedUser.id) ? 'put' : 'post'
		},
		submitUrl(){
			if (this.authorizedUser.selectedServices.length === 1){
				this.authorizedUser.selectedService = this.authorizedUser.selectedServices[0]
			}
			return '/api/authorizedUsers/'
				+ (((this.authorizedUser.selectedService || {}).id && this.authorizedUser.selectedService.id != this.constants.EMPTY_GUID) ? ('service/' + this.authorizedUser.selectedService.id) : ('client/' + this.client.id))
				+ ((this.authorizedUser.id) ? ('/' + this.authorizedUser.id) : '')
		},
		title(){
			return ((this.authorizedUser && this.authorizedUser.id) ? 'Edit' : 'Add') + ' Authorized User'
		},
		linkedServices(){
			return this.authorizedUser.serviceAuthorizedUsers ? this.authorizedUser.serviceAuthorizedUsers.map(sau => ({
				id: sau.serviceId,
				name: sau.service.displayName,
				notes: sau.notes,
				level: sau.level,
				receiveFeedbackEmails: sau.receiveFeedbackEmails,
				canReceiveEmails: sau.canReceiveEmails,
				leftGoogleReview: sau.leftGoogleReview,
				alwaysCC: sau.alwaysCC,
			})) : []
		},
		makeContactUserDescription(){
			return this.authorizedUser.contactPerson
				? 'Unchecking this box will delete the contact person in Zoho Books.'
				: 'Checking this box will create a contact person in Zoho Books if one does not already exist.'
		},
		serviceLookupQueryParams(){
			return (this.authorizedUser.linkExistingUser && this.authorizedUser.linkExistingUser.id)
				? {excludeWithUser: this.authorizedUser.linkExistingUser.id }
				: {}
		},
		showAlwaysCc(){
			return (this.service || this.authorizedUser.selectedService || this.authorizedUser.selectedServices.length) && !this.authorizedUser.isBillingUser && this.authorizedUser.makeContactUser
		}
	},
	methods: {
		serviceOptionsLoaded(services){
			if (!this.authorizedUser.selectedService){
				if (services.length === 1){
					this.authorizedUser.selectedService = services[0]
					this.authorizedUser.selectedServices = [services[0]]
				}
			}
		},
		changeNewUserMode(){
			this.authorizedUser.linkExistingUser = {}
		},
		updateExistingUsers(existingUsers){
			this.existingUserCount = existingUsers.length
			if (this.existingUserCount == 0){
				this.newUserMode = 'new'
				this.changeNewUserMode()
			}
		},
		submit(){
			if (this.service && this.service.id != this.constants.EMPTY_GUID){
				this.authorizedUser.selectedServices = [this.service]
			}
			if (!this.authorizedUser.makeContactUser){
				// Make sure this isn't selected if we can't CC the user.
				this.authorizedUser.alwaysCC = false
			}
			this.responseError = ''
			if (!this.saveRequest && this.validate(this.authorizedUser)){
				if (this.newUserMode == 'existing'){
					this.linkExistingUserToService()
				} else {
					this.authorizedUser.serviceLevelSettings = this.serviceLevelSettings
					this.authorizedUser.addedServices = this.servicesAdded
					this.authorizedUser.removedServices = this.servicesRemoved
					this.save()
				}
			}
		},
		async linkExistingUserToService(){
			try {
				if (this.authorizedUser.selectedServices.length === 0 && (this.authorizedUser.selectedService || {}).id){
					this.authorizedUser.selectedServices.push(this.authorizedUser.selectedService)
				}
				let data = {
					authorizedUserId: this.authorizedUser.linkExistingUser.id,
					serviceId: (this.authorizedUser.selectedService || {}).id,
					serviceIds: this.authorizedUser.selectedServices.map(s => s.id),
					level: this.authorizedUser.level,
					notes: this.authorizedUser.notes,
					receiveFeedbackEmails: this.authorizedUser.receiveFeedbackEmails,
					leftGoogleReview: this.authorizedUser.leftGoogleReview,
					canReceiveEmails: this.authorizedUser.canReceiveEmails,
					alwaysCC: this.authorizedUser.alwaysCC,
				}
				const response = await (this.saveRequest = this.post('/api/authorizedUsers/addExistingUserToService', data))
				this.$emit('saved', response.data)
				this.$emit('input', null)
			} catch (e) {
				console.error(e)
				if (typeof e.response.data == 'string'){
					this.responseError = e.response.data
				}
			} finally {
				this.saveRequest = null
			}
		},
		async save(){
			try {
				if ((this.authorizedUser.selectedService || {}).id == this.constants.EMPTY_GUID){
					this.authorizedUser.selectedService = null
				}
				const response = await (this.saveRequest = this[this.submitMethod](this.submitUrl, this.authorizedUser))
				this.$emit('saved', response.data)
				this.$emit('input', null)
			} catch (e) {
				console.error(e)
				if (typeof e.response.data == 'string'){
					this.responseError = e.response.data
				}
			} finally {
				this.saveRequest = null
			}
		},
		cancel(){
			this.$emit('input', null)
			this.$emit('onCancel')
		},
		onSelectServiceUser(){
			this.authorizedUser.level = this.authorizedUser.selectedService.level
			this.authorizedUser.notes = this.authorizedUser.selectedService.notes
			this.authorizedUser.receiveFeedbackEmails = this.authorizedUser.selectedService.receiveFeedbackEmails
			this.authorizedUser.leftGoogleReview = this.authorizedUser.selectedService.leftGoogleReview
			this.authorizedUser.canReceiveEmails = this.authorizedUser.selectedService.canReceiveEmails
			this.authorizedUser.alwaysCC = this.authorizedUser.selectedService.alwaysCC
		}
	},
	mounted(){
		this.responseError = ''
		if (this.authorizedUser && this.service){
			this.authorizedUser.selectedService = this.service
		} else if (this.authorizedUser && this.linkedServices.length){
			this.serviceLevelSettings = this.value.serviceLevelSettings.map(e => this.deepClone(e))
			this.authorizedUser.selectedService = null
		}
	}
}
</script>
<style lang="scss">
.authorized-user-popup-form{
    .custom-checkbox{
        display: flex;
        align-items: baseline; 
        .form-check-input{
            width: 25px;
            margin-top: 5px;
        }
        .form-check-label{
            margin-left: 10px;
        }
    }
}
</style>