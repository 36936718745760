var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("lookup", {
        key: _vm.key,
        attrs: {
          optionsEndpoint: _vm.optionsEndpoint,
          label: _vm.label || "Authorized User",
          isValid: _vm.isValid,
          invalidFeedback: _vm.invalidFeedback,
          displayKey: _vm.displayKey || "fullName",
          labelClass: _vm.labelClass || "col-form-label col-sm-3",
          fieldClass: "col-sm-9",
          includeProperties: _vm._includeProperties,
          appendOption: _vm.appendOption,
        },
        on: {
          "update:appendOption": function ($event) {
            _vm.appendOption = $event
          },
          "update:append-option": function ($event) {
            _vm.appendOption = $event
          },
          change: _vm.onChange,
          "loaded-options": function ($event) {
            return _vm.$emit("loaded-options", $event)
          },
        },
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }