var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c(
      "td",
      {
        directives: [
          {
            name: "height",
            rawName: "v-height",
            value: "100px",
            expression: "'100px'",
          },
        ],
        staticClass: "position-relative",
        attrs: { colspan: "7" },
      },
      [
        _c("div", { staticClass: "vertical-center" }, [
          _vm.loading
            ? _c(
                "span",
                [
                  _c("CSpinner", {
                    attrs: {
                      size: "sm",
                      component: "span",
                      "aria-hidden": "true",
                    },
                  }),
                  _vm._v(" Loading... "),
                ],
                1
              )
            : _c(
                "span",
                [
                  _vm._v("No data "),
                  _c("Icon", {
                    attrs: { name: "slash-circle", "icon-pack": "bootstrap" },
                  }),
                ],
                1
              ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }