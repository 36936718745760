var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DxField",
        { attrs: { label: "Type", required: true } },
        [
          _c(
            "DxSelectBox",
            {
              attrs: { placeholder: "Select Type", items: _vm.leaveTypes },
              model: {
                value: _vm.userLeave.type,
                callback: function ($$v) {
                  _vm.$set(_vm.userLeave, "type", $$v)
                },
                expression: "userLeave.type",
              },
            },
            [
              _c(
                "DxValidator",
                [
                  _c("DxRequiredRule", {
                    attrs: { message: "Type is required" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("DxField", { attrs: { label: "Start Date", required: true } }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c(
              "DxDateBox",
              {
                ref: "startDateSelector",
                attrs: {
                  type: "datetime",
                  placeholder: "MM/dd/yyyy hh:mm a",
                  "display-format": "MM/dd/yyyy hh:mm a",
                  "invalid-date-message":
                    "The date must have the following format: MM/dd/yyyy",
                  "element-attr": { class: "w-100" },
                  disabled: !_vm.userLeave.type,
                },
                model: {
                  value: _vm.userLeave.startDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.userLeave, "startDate", $$v)
                  },
                  expression: "userLeave.startDate",
                },
              },
              [
                _c(
                  "DxValidator",
                  [
                    _c("DxRequiredRule", {
                      attrs: { message: "Start Date is required" },
                    }),
                    _vm._v(" "),
                    _c("DxCustomRule", {
                      attrs: {
                        reevaluate: true,
                        "validation-callback": _vm.isDateInPast,
                        message:
                          _vm.ActionableIn == "UserManagement"
                            ? "You cannot back-date leaves for yourself"
                            : "Start Date should not be in the past",
                      },
                    }),
                    _vm._v(" "),
                    _c("DxAsyncRule", {
                      attrs: {
                        reevaluate: true,
                        "validation-callback": _vm.validateDates,
                        "validation-message": "Please Change Start Date",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("DxButton", {
              attrs: {
                icon: "clock",
                "element-attr": { class: "ml-2" },
                disabled: !_vm.userLeave.type,
              },
              on: { click: _vm.setStartDate },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("DxField", { attrs: { label: "End Date", required: true } }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c(
              "DxDateBox",
              {
                ref: "endDateSelector",
                attrs: {
                  type: "datetime",
                  placeholder: "MM/dd/yyyy hh:mm a",
                  "display-format": "MM/dd/yyyy hh:mm a",
                  "invalid-date-message":
                    "The date must have the following format: MM/dd/yyyy",
                  "element-attr": { class: "w-100" },
                  disabled: !_vm.userLeave.type,
                },
                model: {
                  value: _vm.userLeave.endDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.userLeave, "endDate", $$v)
                  },
                  expression: "userLeave.endDate",
                },
              },
              [
                _c(
                  "DxValidator",
                  { ref: "endDateValidator" },
                  [
                    _c("DxRequiredRule", {
                      attrs: { message: "End Date is required" },
                    }),
                    _vm._v(" "),
                    _c("DxCustomRule", {
                      attrs: {
                        reevaluate: true,
                        "validation-callback": function () {
                          return _vm.userLeave.endDate > _vm.userLeave.startDate
                        },
                        message: "End date should be after Start Date",
                      },
                    }),
                    _vm._v(" "),
                    _c("DxCustomRule", {
                      attrs: {
                        reevaluate: true,
                        "validation-callback": _vm.isDateInPast,
                        message:
                          _vm.ActionableIn == "UserManagement"
                            ? "You cannot back-date leaves for yourself"
                            : "End Date should not be in the past",
                      },
                    }),
                    _vm._v(" "),
                    _c("DxAsyncRule", {
                      attrs: {
                        reevaluate: true,
                        "validation-callback": _vm.validateDates,
                        "validation-message": "Please Change End Date",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("DxButton", {
              attrs: {
                icon: "clock",
                "element-attr": { class: "ml-2" },
                disabled: !_vm.userLeave.type,
              },
              on: { click: _vm.setEndDate },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }