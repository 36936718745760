<template>
	<span>
		<lookup
			:optionsEndpoint="optionsEndpoint"
			:label="label || 'Authorized User'"
			v-model="selected"
			:isValid="isValid"
			:invalidFeedback="invalidFeedback"
			:displayKey="displayKey || 'fullName'"
			@change="onChange"
			labelClass="col-form-label col-sm-3"
			:fieldClass="allowNew ? 'col-sm-8' : 'col-sm-9'"
			:includeProperties="_includeProperties"
			:appendOption.sync="appendOption"
			:disabled="disabled"
			:key="key"
		>
			<template #extra>
				<span v-if="allowNew" name="addButton" class="col-sm-1">
					<CButton :disabled="disabled" type="button" color="primary" size="sm" @click="openAuthedUserForm" class="btn-input-height btn-fit-right" v-tooltip="'Add new authorized user'"><CIcon name="cil-plus" /></CButton>
					<AuthorizedUserPopupForm
						v-model="newAuthedUser"
						:client="(service || {}).client || client"
						:service="service"
						:key="userFormKey"
						:requireAddress="requireAddress"
						:level="level"
						@saved="savedNewAuthedUser"
					></AuthorizedUserPopupForm>
				</span>
			</template>
		</lookup>
		<span v-if="requireAddress && selected">
			<AddressForm
				v-model="selected.address"
				label="Address"
				:openByDefault="true"
				:validationErrors="addressValidationErrors"
				:isValidCallback="addressIsValidCallback"
				:validationPasses="validationPasses"
				:billingAddress="client.billingAddress"
			/>
		</span>
	</span>
</template>

<script>

import Lookup from '../generic/Lookup'
import AddressForm from '../AddressForm'
import AuthorizedUserPopupForm from '../../views/authorized-users/AuthorizedUserPopupForm'
import { newAuthUser, blankAddress } from '../../mixins/view-mixins/AuthorizedUsers'

export default {
	name: 'AuthorizedUserLookup',
	props: ['value', 'isValid', 'invalidFeedback', 'label', 'service', 'client', 'level', 'allowNew',
		'requireAddress', 'includeProperties', 'addressValidationErrors', 'addressIsValidCallback', 'validationPasses',
		'serviceUsersOnly', 'displayKey', 'disabled'
	],
	components: {Lookup, AuthorizedUserPopupForm, AddressForm},
	data(){
		return {
			newAuthedUser: null,
			userFormKey: 0,
			appendOption: null,
		}
	},
	computed: {
		key(){ return 'cid' + this.client.id },
		selected: {
			get(){
				return this.value;
			},
			set(v){
				this.$emit('input', v);
			}
		},
		optionsEndpoint(){
			return ((this.serviceUsersOnly && this.service)
				? ('/api/authorizedUsers/service/' + this.service.id)
				: ('/api/authorizedUsers/client/' + this.client.id))
				+ '?distinct=true&includeClientLevel=true'
				+ (this.level ? '&level=' + this.level : '')
				;
		},
		_includeProperties(){
			const props = (this.includeProperties || [])
			if (this.requireAddress && !props.includes('address')){
				props.push('address')
			}
			return props
		}
	},
	methods: {
		openAuthedUserForm(){
			this.newAuthedUser = newAuthUser('Admin')
			this.userFormKey++
		},
		savedNewAuthedUser(authedUser){
			this.appendOption = this.deepClone(authedUser)
			this.selected = this.deepClone(authedUser)
		},
		onChange(value){
			if (this.requireAddress && value && !value.address){
				value.address = blankAddress()
			}
			this.$emit('change', value)
		}
	}
}
</script>
