<template>
	<lookup
		:preloadedOptions="options"
		:label="label !== false ? (label || 'Payment Terms') : false"
		valueKey="paymentTerms"
		displayKey="paymentTermsLabel"
		v-model="selected"
		labelClass="col-form-label col-sm-3 required"
		:fieldClass="fieldClass || 'col-sm-9'"
		:isValid="isValid"
		:invalidFeedback="invalidFeedback"
		:placeholder="placeholder"
		:clearable="clearable !== undefined ? clearable : true"
		:containerClass="containerClass || ''"
		:fieldCol="fieldCol"
		:style="style"
		:sort="false"
		:disabled="disabled"
		@change="$emit('change', $event)"
	>
		<template #description>
			<p v-if="client" class="text-dark pt-2">
				Client's default payment terms: {{client.paymentTermsObject.paymentTermsLabel}}
				<CButton
					v-if="client.paymentTermsObject.paymentTerms != selected.paymentTerms"
					color="primary"
					size="sm"
					class="ml-2 py-0"
					@click="clickedChangeClientPaymentTerms"
				>
					Change to the selected terms
				</CButton>
			</p>
		</template>
		<template #tooltip>
			<slot name="nestedtooltip"></slot>
		</template>
	</lookup>
</template>

<script>
import { AxiosWrapper } from '../../mixins'

import Lookup from '../generic/Lookup'

export default {
	name: 'PaymentTermsLookup',
	props: ['value', 'isValid', 'invalidFeedback', 'label', 'placeholder', 'clearable', 'containerClass', 'fieldCol', 'minWidth', 'fieldClass', 'client', 'disabled'],
	components: {Lookup},
	mixins: [AxiosWrapper],
	data(){
		return {
			options: window.constants.PAYMENT_TERMS_OPTIONS,
			apiRequest: null,
		}
	},
	computed: {
		selected: {
			get(){
				return {
					paymentTerms: Number(this.value.paymentTerms),
					paymentTermsLabel: this.value.paymentTermsLabel
				}
			},
			set(v){
				this.$emit('input', v);
			},
		},
		style(){
			return this.minWidth ? {
				'min-width': this.minWidth
			} : {};
		}
	},
	methods: {
		clickedChangeClientPaymentTerms(){
			const a = this.client.paymentTermsObject.paymentTermsLabel
			const b = this.selected.paymentTermsLabel
			window.showConfirmModal({
				text: "Change the client's payment terms from " + a + ' to ' + b + '?',
				onConfirm: this.changeClientPaymentTerms
			})
		},
		async changeClientPaymentTerms(){
			try {
				const data = {
					PaymentTermsObject: this.selected,
				}
				const response = await (this.apiRequest = this.put('/api/clients/setPaymentTerms/' + this.client.id, this.selected))
				this.client.paymentTermsObject = response.data
			} catch (e) {
				console.error(e);
			} finally {
				this.apiRequest = null
			}
		},
	},
}
</script>
