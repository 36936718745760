<template>
    <DxTagBox 
        v-model="selected" 
        :search-enabled="true"
        displayExpr="displayName" 
        :data-source="groups"
        placeholder="Select Groups"
        :disabled="disabled"
        @value-changed="onValueChanged">
        <DxValidator v-if="required"> <DxRequiredRule message="Groups are required"/> </DxValidator>
    </DxTagBox>
</template>

<script>
import DxTagBox from 'devextreme-vue/tag-box';
import { AxiosWrapper } from '../../mixins/AxiosWrapper'
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
export default {
    name:'DepartmentsLookup',
    props:{
        'value': {
            type: Array,
            default: () => []
        },  
        'required':{
            type: Boolean,
            default: false
        },
        'disabled':{
            type: Boolean,
            default: false
        },
    },
    components:{ DxTagBox, DxValidator, DxRequiredRule },
    mixins:[ AxiosWrapper ],
    data(){
        return {
            groupsRaw:[],
            groups: {
                loadMode: 'raw',
                load: () => {
                    const promise = new Promise((resolve) => {
                        this.get('api/users/permissionGroups').then(response =>{
                            this.groupsRaw = response.data
                            this.$emit('on-load', this.groupsRaw)
                            resolve(this.groupsRaw);
                        })
                    });
                    return promise;
                },
            },
        }
    },
    computed:{
        selected:{
            get(){ return this.value || [] },
            set(v){ this.$emit('input', v) }
        }
    },
    methods:{
        onValueChanged(e){
            this.$emit('value-changed', e)
        }
    }
}
</script>