<template>
	<DxPopup
        :wrapper-attr="{id: 'dxAlertPopup', class: mode}"
		v-if="popup.show"
		:visible="popup.show"
		:on-hidden="popup.closeButtonOptions"
		:drag-enabled="true"
		:hide-on-outside-click="false"
		:show-close-button="false"
		:show-title="false"
		:width="width"
		:height="height">
        <div>
            <div v-if="loading" class="spinner-wrapper" style="min-height:100px"><CSpinner aria-hidden="true"/></div>
            <div class="text-center" v-else>
                <strong>{{title}}</strong>
                <p class="mt-2">{{text}}</p>
                <DxButton :text="confirmButtonText" :type="mode" @click="confirm" width="100%" />
            </div>
        </div>
	</DxPopup>
</template>

<script>
import {DxPopup, DxToolbarItem} from 'devextreme-vue/popup';
import { DxButton } from 'devextreme-vue/button'
export default {
	name:'DxConfirmPopup',
	components:{
        DxPopup,
        DxToolbarItem,
        DxButton
    },
	data(){
		return {
            loading: false,
			popup:{
				show: false,
				closeButtonOptions: () => { 
                    this.popup.show = false; 
                    this.onHidden();
                }
			},
            title: 'Alert',
            text: '',
            height: 'auto',
            width: 350,
            confirmButtonText: 'Okay',
            showLoader: false,
            onConfirm: async function(){},
            onHidden: function(){},
            mode: 'default'
		}
	},
	methods:{
		show(options){
			this.title = options.title || 'Alert'
			this.text = options.text || ''
			this.height = options.height || 'auto'
			this.width = options.width || 350
			this.confirmButtonText = options.confirmButtonText || 'Okay'
			this.onConfirm = options.onConfirm || async function(){}
			this.onHidden = options.onHidden || function(){}
            this.showLoader = options.showLoader || false
			this.popup.show = true
            this.mode = options.mode || 'default'
		},
		cancel(){
			this.popup.show = false
            this.onCancel()
		},
		async confirm(){
            if(this.showLoader) this.loading = true
            try{
			    await this.onConfirm()
            }
            finally{
                this.loading = false
                this.popup.show = false
            }
		}
	}
}
</script>