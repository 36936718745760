<template>
	<div role="group" :class="containerClass || 'form-group form-row lookup-field'">
		<!-- <label v-if="label" class="col-form-label col-sm-3"> {{label}}</label> -->
		<label v-if="label" :class="labelClass || 'col-form-label w-180'"> {{label}} <slot name="tooltip"></slot> </label>

		<div :class="fieldClass" class="width-300">
			<!-- TODO: Use VSelectWrapper for this -->
			<v-select
				v-show="!requestOptions"
				v-model="selected"
				:label="_displayKey"
				:options="sortedOptions"
				:placeholder="placeholderText"
				:class="{'is-invalid': !_isValid}"
				:invalidFeedback="invalidFeedback || ''"
				:clearable="clearable !== undefined ? clearable : true"
				:disabled="disabled"
				@input="onChange"
				:append-to-body="true"
				:multiple="multiple"
				:selectable="selectableFunc"
			/>
			<span class="form-control" v-if="requestOptions"><spinner class="spinner-inline-sm" /> Loading Options...</span>
			<input type="hidden" class="form-control is-invalid" />
			<slot name="description"></slot>
			<div v-if="!_isValid" class="invalid-feedback">{{_invalidFeedback}}</div>
		</div>
		<slot name="extra"></slot>
	</div>
</template>

<style>
.is-invalid .vs__dropdown-toggle {
	border-color: #e55353;
}
</style>

<script>
import { AxiosWrapper } from '../../mixins';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

const removeDups = (arr, keyName) => {
	const list = {};
	arr.forEach(el => {
		list[el[keyName]] = el
	})
	return Object.values(list);
}

export default {
	name: 'Lookup',
	props: ['value', 'optionsEndpoint', 'label', 'placeholder', 'valueKey', 'displayKey',
		'isValid', 'invalidFeedback', 'preloadedOptions', 'clearable',
		'containerClass', 'labelClass', 'fieldClass', 'includeProperties', 'externalFilter',
		'disabled', 'sort', 'multiple', 'appendOption', 'selectableFunction', 'queryParams',
		'autoSelectOnlyOption',
	],
	components: {vSelect},
	mixins: [AxiosWrapper],
	data() {
		return {
			requestOptions: null,
			options: this.preloadedOptions ? this.preloadedOptions.map(item=>item) : [],
		}
	},
	computed: {
		_isValid(){
			return this.isValid != null ? this.isValid : true
		},
		_invalidFeedback(){
			return this.invalidFeedback || '';
		},
		_valueKey(){
			return this.valueKey || 'id';
		},
		_displayKey(){
			return this.displayKey || 'name';
		},
		placeholderText(){
			return (this.placeholder !== false) ? this.placeholder || ('Select ' + (this.label ? this.label : '')) : '';
		},
		selected: {
			get(){
				return this.multiple
					? (this.value ? this.options.filter(item => this.value.some(el => el[this._valueKey] === item[this._valueKey])) : null)
					: (this.value ? this.options.find(item => item[this._valueKey] === this.value[this._valueKey]) : null)
			},
			set(v){
				// this.$emit('input', v ? v.value : null);
				this.$emit('input', v);
			}
		},
		sortedOptions(){
			let self = this;
			const filter = (self.externalFilter != null && self.externalFilter.constructor === Object) ? self.externalFilter : {};
			const options = removeDups(self.options, self._valueKey)
				.filter(item => {
					let include = true;
					Object.keys(filter).forEach(key => {
						include = include && (!(filter[key]) || item[key] == filter[key])
					});
					return include
				})
			if (this.sort !== false){
				return options.sort((a,b) => ((a[self._displayKey].toString().toLowerCase() < b[self._displayKey].toString().toLowerCase()) ? -1 : 1 ))
			}
			return options
		},
		selectableFunc(){
			return this.selectableFunction ? this.selectableFunction : (() => true);
		},
	},
	watch: {
		preloadedOptions(v){
			this.options = v
		},
		externalFilter(){
			this.$set(this, 'options', this.options)
			this.$forceUpdate()
		},
		appendOption(v){
			if (v){
				this.options.push(v)
				this.$emit('update:appendOption', null)
			}
		},
		optionsEndpoint(){
			this.loadOptions()
		},
		queryParams(n, o){
			// Make sure this property actually changed. This was causing a problem in at least one of the forms.
			if (JSON.stringify(n) != JSON.stringify(o)){
				this.loadOptions()
			}
		},
		autoSelectOnlyOption(v){
			if (v){
				this.checkAutoSelect()
			}
		}
	},
	methods: {
		checkAutoSelect(){
			if (this.autoSelectOnlyOption && this.selected == null && this.sortedOptions.length === 1){
				this.selected = this.sortedOptions[0]
				this.onChange(this.selected)
			}
		},
		addOption(option){
			if (!this.options.includes(option)){
				this.options.push(option);
			}
		},
		onChange(v){
			this.$emit('change', v)
		},
		async loadOptions(){
			if (!this.optionsEndpoint){
				return;
			}
			let self = this;
			if (self.requestOptions){
				this.cancelAxiosCall()
			}
			try {
				let url = self.optionsEndpoint
				if (self.queryParams){
					Object.keys(self.queryParams).forEach(k=>{
						url += (url.includes('?') ? '&' : '?') + k + '=' + encodeURIComponent(self.queryParams[k])
					});
				}
				const response = await (self.requestOptions = self.get(url))
				self.options = response.data.map(function(option){
					let opt = {};
					opt[self._valueKey] = option[self._valueKey];
					opt[self._displayKey] = option[self._displayKey];
					if (self.includeProperties){
						if (self.includeProperties == '*'){
							Object.keys(option).forEach(key => {
								if (key != self._valueKey && key != self._displayKey){
									opt[key] = option[key];
								}
							})
						}
						else {
							self.includeProperties.forEach(prop => {
								opt[prop] = option[prop];
							})
						}
					}
					return opt;
				});
				self.$emit('loaded-options', self.options)
				self.checkAutoSelect()
			} catch (e) {
				console.error(e);
			} finally {
				self.requestOptions = null;
			}
		}
	},
	mounted(){
		this.loadOptions()
	}
}
</script>
