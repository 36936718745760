var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CustomCInput",
    _vm._g(
      _vm._b(
        {
          attrs: { disabled: _vm.disabled },
          on: { change: _vm.format, blur: _vm.format },
          scopedSlots: _vm._u(
            [
              {
                key: "label",
                fn: function () {
                  return [
                    _vm.label
                      ? _c(
                          "label",
                          { class: _vm.addLabelClasses },
                          [
                            _vm._v("\n\t\t" + _vm._s(_vm.label) + " "),
                            _vm._t("tooltip"),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
          model: {
            value: _vm.model,
            callback: function ($$v) {
              _vm.model = $$v
            },
            expression: "model",
          },
        },
        "CustomCInput",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }