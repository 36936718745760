<template>
	<lookup
		:optionsEndpoint="optionsEndpoint"
		label="Available P9 Accounts"
		v-model="selected"
		:isValid="isValid"
		:invalidFeedback="invalidFeedback"
		:includeProperties="['cid']"
		displayKey="name"
		fieldClass="w-75"
		@change="$emit('change', $event)"
		@loaded-options="loadedOptions"
	></lookup>
</template>

<script>

import Lookup from '../generic/Lookup'

export default {
	name: 'PlanLookup',
	props: ['value', 'isValid', 'invalidFeedback', 'currentCid'],
	components: {Lookup},
	// data(){
	// 	return {
	// 		value: this.initValue,
	// 	}
	// },
	computed: {
		optionsEndpoint(){
			return '/api/clients/unlinkedP9Clients' + (this.currentCid ? ('?current=' + this.currentCid) : '')
		},
		selected: {
			get(){
				return this.value;
			},
			set(v){
				// this.value = v;
				// this.$emit('input', v ? v.value : null);
				this.$emit('input', v);
			}
		}
	},
	methods: {
		loadedOptions(options){
			if (this.currentCid){
				const preselected = options.find(o => o.cid == this.currentCid)
				this.selected = preselected
			}
		}
	}
}
</script>
