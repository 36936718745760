export function authHeader() {
    // return authorization header with jwt token
    let userString = localStorage.getItem('user');
    if (userString && userString != '""') {
        let user = JSON.parse(atob(userString.replace(/"/g, '')));
        return { 'Authorization': 'Bearer ' + user.Token };
    } else {
        return {};
    }
}
