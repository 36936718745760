<template>
	<VSelectWrapper
		label="State/Province"
		:options="options"
		labelClass="col-form-label col-sm-3"
		fieldClass="col-sm-9"
		v-model="selected"
		:sort="false"
		:isValid="isValid"
		:invalidFeedback="invalidFeedback"
	/>
</template>

<script>
import VSelectWrapper from './generic/VSelectWrapper.vue'
export default {
	components: { VSelectWrapper },
	props: ['value', 'isValid', 'invalidFeedback'],
	watch: {
		value(v){
			// Match option based on the name instead of the ID if needed.
			if (v && !this.keys.includes(v)){
				this.selected = this.options.find(o => o.name.toLowerCase() == v.toLowerCase())
			}
		}
	},
	computed: {
		selected: {
			get() { return this.value },
			set(v) { this.$emit('input', v ? v.id : null) },
		},
		keys(){
			return this.options
				.filter(o => !o.disabled)
				.map(o => o.id)
		},
		options(){
			return [
				{ id: 'usa', name: 'United States', disabled: true, heading: true},
				{ id: 'AL', name: 'Alabama'},
				{ id: 'AK', name: 'Alaska'},
				{ id: 'AZ', name: 'Arizona'},
				{ id: 'AR', name: 'Arkansas'},
				{ id: 'CA', name: 'California'},
				{ id: 'CO', name: 'Colorado'},
				{ id: 'CT', name: 'Connecticut'},
				{ id: 'DE', name: 'Delaware'},
				{ id: 'DC', name: 'District Of Columbia'},
				{ id: 'FL', name: 'Florida'},
				{ id: 'GA', name: 'Georgia'},
				{ id: 'HI', name: 'Hawaii'},
				{ id: 'ID', name: 'Idaho'},
				{ id: 'IL', name: 'Illinois'},
				{ id: 'IN', name: 'Indiana'},
				{ id: 'IA', name: 'Iowa'},
				{ id: 'KS', name: 'Kansas'},
				{ id: 'KY', name: 'Kentucky'},
				{ id: 'LA', name: 'Louisiana'},
				{ id: 'ME', name: 'Maine'},
				{ id: 'MD', name: 'Maryland'},
				{ id: 'MA', name: 'Massachusetts'},
				{ id: 'MI', name: 'Michigan'},
				{ id: 'MN', name: 'Minnesota'},
				{ id: 'MS', name: 'Mississippi'},
				{ id: 'MO', name: 'Missouri'},
				{ id: 'MT', name: 'Montana'},
				{ id: 'NE', name: 'Nebraska'},
				{ id: 'NV', name: 'Nevada'},
				{ id: 'NH', name: 'New Hampshire'},
				{ id: 'NJ', name: 'New Jersey'},
				{ id: 'NM', name: 'New Mexico'},
				{ id: 'NY', name: 'New York'},
				{ id: 'NC', name: 'North Carolina'},
				{ id: 'ND', name: 'North Dakota'},
				{ id: 'OH', name: 'Ohio'},
				{ id: 'OK', name: 'Oklahoma'},
				{ id: 'OR', name: 'Oregon'},
				{ id: 'PA', name: 'Pennsylvania'},
				{ id: 'RI', name: 'Rhode Island'},
				{ id: 'SC', name: 'South Carolina'},
				{ id: 'SD', name: 'South Dakota'},
				{ id: 'TN', name: 'Tennessee'},
				{ id: 'TX', name: 'Texas'},
				{ id: 'UT', name: 'Utah'},
				{ id: 'VT', name: 'Vermont'},
				{ id: 'VA', name: 'Virginia'},
				{ id: 'WA', name: 'Washington'},
				{ id: 'WV', name: 'West Virginia'},
				{ id: 'WI', name: 'Wisconsin'},
				{ id: 'WY', name: 'Wyoming'},
				{ id: 'can', name: 'Canada', disabled: true, heading: true},
				{ id: 'ON', name: 'Ontario' },
				{ id: 'QC', name: 'Quebec' },
				{ id: 'BC', name: 'British Columbia' },
				{ id: 'AB', name: 'Alberta' },
				{ id: 'MB', name: 'Manitoba' },
				{ id: 'SK', name: 'Saskatchewan' },
				{ id: 'NS', name: 'Nova Scotia' },
				{ id: 'NB', name: 'New Brunswick' },
				{ id: 'NL', name: 'Newfoundland And Labrador' },
				{ id: 'PE', name: 'Prince Edward Island' },
				{ id: 'NT', name: 'Northwest Territories' },
				{ id: 'NU', name: 'Nunavut' },
				{ id: 'YT', name: 'Yukon' },
				{ id: 'ENG', name: 'England', disabled: true, heading: true},
				{ id: 'BEDS', name: 'Bedfordshire' },
				{ id: 'BERKS', name: 'Berkshire' },
				{ id: 'BUCKS', name: 'Buckinghamshire' },
				{ id: 'CAMBS', name: 'Cambridgshire' },
				{ id: 'CHES', name: 'Cheshire' },
				{ id: 'CORN', name: 'Cornwall' },
				{ id: 'CUMB', name: 'Cumberland' },
				{ id: 'DERBS', name: 'Derbyshire' },
				{ id: 'DEV', name: 'Devon' },
				{ id: 'DOR', name: 'Dorset' },
				{ id: 'CO_DUR', name: 'Durham' },
				{ id: 'ESSEX', name: 'Essex' },
				{ id: 'GLOS', name: 'Gloucestershire' },
				{ id: 'HANTS', name: 'Hampshire' },
				{ id: 'HERE', name: 'Herefordshire' },
				{ id: 'HERTS', name: 'Hertfordshire' },
				{ id: 'HUNTS', name: 'Huntingdonshire' },
				{ id: 'KENT', name: 'Kent' },
				{ id: 'LANCS', name: 'Lancashire' },
				{ id: 'LEICS', name: 'Leicestershire' },
				{ id: 'LINCS', name: 'Lincolnshire' },
				{ id: 'MX', name: 'Middlesex' },
				{ id: 'NORF', name: 'Norfolk' },
				{ id: 'NORTHANTS', name: 'Northamptonshire' },
				{ id: 'NORTHUMB', name: 'Northumberland' },
				{ id: 'NOTTS', name: 'Nottinghamshire' },
				{ id: 'OXON', name: 'Oxfordshire' },
				{ id: 'RUT', name: 'Rutland' },
				{ id: 'SHROPS', name: 'Shropshire' },
				{ id: 'SOM', name: 'Somerset' },
				{ id: 'STAFFS', name: 'Staffordshire' },
				{ id: 'SUFF', name: 'Suffolk' },
				{ id: 'SY', name: 'Surrey' },
				{ id: 'SX', name: 'Sussex' },
				{ id: 'WARKS', name: 'Warwickshire' },
				{ id: 'WESTM', name: 'Westmorland' },
				{ id: 'WILTS', name: 'Wiltshire' },
				{ id: 'WORCS', name: 'Worcestershire' },
				{ id: 'YORKS', name: 'Yorkshire' },
				{ id: 'SCOT', name: 'Scotland', disabled: true, heading: true},
				{ id: 'ABERS', name: 'Aberdeenshire' },
				{ id: 'ANG', name: 'Angus (Forfarshire)' },
				{ id: 'ARG', name: 'Argyllshire' },
				{ id: 'AYR', name: 'Ayrshire' },
				{ id: 'BANFS', name: 'Banffshire' },
				{ id: 'BERW', name: 'Berwickshire' },
				{ id: 'BUTE', name: 'Bute' },
				{ id: 'CAITH', name: 'Caithness' },
				{ id: 'CLACK', name: 'Clackmannanshire' },
				{ id: 'DUMF', name: 'Dumfriesshire' },
				{ id: 'DUNB', name: 'Dunbartonshire' },
				{ id: 'ELOTH', name: 'East Lothian (Haddingtonshire)' },
				{ id: 'MLOTH', name: 'Midlothian (Edinburghshire)' },
				{ id: 'MORA', name: 'Moray (Elginshire)' },
				{ id: 'FIFE', name: 'Fife (Kingdom of)' },
				{ id: 'INVER', name: 'Inverness-shire' },
				{ id: 'KINCAS', name: 'Kincardineshire' },
				{ id: 'KINROS', name: 'Kinross-shire' },
				{ id: 'KIRKS', name: 'Kirkcudbrightshire' },
				{ id: 'LANAS', name: 'Lanarkshire' },
				{ id: 'WLOTH', name: 'West Lothian (Linlithgowshire)' },
				{ id: 'NAIRN', name: 'Nairnshire' },
				{ id: 'ORKIS', name: 'Orkney Islands' },
				{ id: 'PEEBS', name: 'Peeblesshire' },
				{ id: 'PERTHS', name: 'Perthshire' },
				{ id: 'RENFS', name: 'Renfrewshire' },
				{ id: 'ROSCRO', name: 'Ross & Cromarty' },
				{ id: 'ROXB', name: 'Roxburghshire' },
				{ id: 'SELKS', name: 'Selkirkshire' },
				{ id: 'ZETIS', name: 'Shetland (Zetland) Islands' },
				{ id: 'STIRL', name: 'Stirlingshire' },
				{ id: 'SUTHS', name: 'Sutherland' },
				{ id: 'WIGS', name: 'Wigtonshire' },
				{ id: 'WEL', name: 'Wales', disabled: true, heading: true},
				{ id: 'Angl', name: 'Anglesey' },
				{ id: 'Brecks', name: 'Breconshire' },
				{ id: 'C_a_r_n', name: 'Caernarfonshire' },
				{ id: 'Cards', name: 'Cardiganshire' },
				{ id: 'Carm', name: 'Carmarthenshire' },
				{ id: 'Denb', name: 'Denbighshire' },
				{ id: 'Flints', name: 'Flintshire' },
				{ id: 'Glam', name: 'Glamorgan' },
				{ id: 'Meri', name: 'Merionethshire' },
				{ id: 'Mon', name: 'Monmouth' },
				{ id: 'Mont', name: 'Montgomeryshire' },
				{ id: 'Pembs', name: 'Pembrokeshire' },
				{ id: 'Rads', name: 'Radnorshire' },
			]
		}
	}
}
</script>
