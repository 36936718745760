var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DxSelectBox",
    {
      attrs: {
        "search-enabled": true,
        "data-source": _vm.services,
        "display-expr": "displayName",
        "value-expr": "id",
        placeholder: "Select Project",
        disabled: _vm.disabled,
        "read-only": _vm.readOnly,
        hint: _vm.hint,
        "item-template": "item",
      },
      on: { "value-changed": _vm.onSelectService },
      scopedSlots: _vm._u([
        {
          key: "item",
          fn: function (ref) {
            var data = ref.data
            return [
              _c(
                "div",
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(data.displayName) +
                      "\n            "
                  ),
                  _c("DispositionBadge", { attrs: { type: data.projectType } }),
                  _vm._v(" "),
                  _c("InFlightStageBadge", { attrs: { stage: data.stage } }),
                  _vm._v(" "),
                  data.isBilling2
                    ? _c("span", { staticClass: "billing-type-badge mr-1" }, [
                        _vm._v("Billing 2.0"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.serviceId,
        callback: function ($$v) {
          _vm.serviceId = $$v
        },
        expression: "serviceId",
      },
    },
    [
      _vm._v(" "),
      _vm.required
        ? _c(
            "DxValidator",
            [
              _c("DxRequiredRule", {
                attrs: { message: "Project is required" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }