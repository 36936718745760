var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("lookup", {
    attrs: {
      optionsEndpoint: _vm.optionsEndpoint,
      label: "Available P9 Accounts",
      isValid: _vm.isValid,
      invalidFeedback: _vm.invalidFeedback,
      includeProperties: ["cid"],
      displayKey: "name",
      fieldClass: "w-75",
    },
    on: {
      change: function ($event) {
        return _vm.$emit("change", $event)
      },
      "loaded-options": _vm.loadedOptions,
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v
      },
      expression: "selected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }