var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { attrs: { id: _vm.target }, on: { click: _vm.clickTarget } },
        [_vm._t("target")],
        2
      ),
      _vm._v(" "),
      _c(
        "DxPopover",
        {
          attrs: {
            visible: _vm.show,
            "on-hidden": _vm.close,
            "hide-on-outside-click": _vm.hideOnOutsideClick,
            "show-title": Boolean(_vm.title),
            width: _vm.width,
            height: _vm.height,
            title: _vm.title,
            target: "#" + _vm.target,
            position: _vm.position,
            wrapperAttr: _vm.wrapperAttr,
          },
        },
        [
          _vm.nativeScroll
            ? _c(
                "DxScrollView",
                { attrs: { height: _vm.height } },
                [_vm._t("content")],
                2
              )
            : _vm._t("content"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }