var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.itemsPerPageSelect || _vm.tableFilter
        ? _c("div", { staticClass: "row my-2 mx-0" }, [
            _vm.tableFilter
              ? _c("div", { staticClass: "col-sm-6 form-inline p-0" }, [
                  _c("label", { staticClass: "mr-2" }, [_vm._v("Filter:")]),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "form-control table-filter",
                    attrs: { type: "text", placeholder: "type string..." },
                    domProps: { value: _vm.tableFilterState },
                    on: {
                      input: function ($event) {
                        return _vm.tableFilterChange($event.target.value)
                      },
                      change: function ($event) {
                        return _vm.tableFilterChange(
                          $event.target.value,
                          "change"
                        )
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.itemsPerPageSelect
              ? _c(
                  "div",
                  {
                    staticClass: "col-sm-6 p-0",
                    class: { "offset-sm-6": !_vm.tableFilter },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-inline justify-content-sm-end" },
                      [
                        _c("label", { staticClass: "mr-2" }, [
                          _vm._v("Items per page:"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            staticClass: "form-control",
                            on: { change: _vm.paginationChange },
                          },
                          [
                            _c(
                              "option",
                              {
                                attrs: {
                                  value: "",
                                  selected: "",
                                  disabled: "",
                                  hidden: "",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.perPageItems) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l([5, 10, 20, 50], function (number, key) {
                              return _c(
                                "option",
                                { key: key, attrs: { val: number } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(number) +
                                      "\n            "
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("over-table"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "on-scroll",
              rawName: "v-on-scroll",
              value: _vm.onScroll,
              expression: "onScroll",
            },
          ],
          staticClass: "table-body-scroll",
          class:
            "position-relative " + (_vm.responsive ? "table-responsive" : ""),
        },
        [
          _c(
            "table",
            { class: _vm.tableClasses },
            [
              _c(
                "thead",
                [
                  _vm._t("thead-top"),
                  _vm._v(" "),
                  _c(
                    "tr",
                    [
                      _vm._l(_vm.columnNames, function (name, index) {
                        return [
                          _c(
                            "th",
                            {
                              key: index,
                              class: [
                                _vm.headerClass(index),
                                _vm.sortingIconStyles,
                              ],
                              style: _vm.headerStyles(index),
                              on: {
                                click: function ($event) {
                                  return _vm.changeSort(
                                    _vm.rawColumnNames[index],
                                    index
                                  )
                                },
                              },
                            },
                            [
                              _vm._t(_vm.rawColumnNames[index] + "-header", [
                                _c("div", { staticClass: "d-inline" }, [
                                  _vm._v(_vm._s(name)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm.isSortable(index)
                                ? _vm._t(
                                    "sorting-icon",
                                    [
                                      _c("CIcon", {
                                        class: _vm.iconClasses(index),
                                        attrs: {
                                          width: "18",
                                          content:
                                            _vm.$options.icons.cilArrowTop,
                                        },
                                      }),
                                    ],
                                    { state: _vm.getIconState(index) }
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.columnFilter
                    ? _c(
                        "tr",
                        { staticClass: "table-sm" },
                        [
                          _vm._l(_vm.rawColumnNames, function (colName, index) {
                            return [
                              _c(
                                "th",
                                { key: index, class: _vm.headerClass(index) },
                                [
                                  _vm._t(
                                    _vm.rawColumnNames[index] + "-filter",
                                    [
                                      !_vm.fields ||
                                      _vm.fields[index].filter !== false
                                        ? _c("input", {
                                            staticClass: "w-100 table-filter",
                                            domProps: {
                                              value:
                                                _vm.columnFilterState[colName],
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.columnFilterEvent(
                                                  colName,
                                                  $event.target.value,
                                                  "input"
                                                )
                                              },
                                              change: function ($event) {
                                                return _vm.columnFilterEvent(
                                                  colName,
                                                  $event.target.value,
                                                  "change"
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                2
                              ),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "tbody",
                {
                  staticClass: "position-relative",
                  style: _vm.clickableRows ? "cursor:pointer;" : null,
                },
                [
                  _vm._l(_vm.currentItems, function (item, itemIndex) {
                    return [
                      _c(
                        "tr",
                        {
                          key: itemIndex,
                          class: item._classes,
                          attrs: { tabindex: _vm.clickableRows ? 0 : null },
                          on: {
                            click: function ($event) {
                              return _vm.rowClicked(
                                item,
                                itemIndex + _vm.firstItemIndex
                              )
                            },
                          },
                        },
                        [
                          _vm._l(_vm.rawColumnNames, function (colName, index) {
                            return [
                              _vm.$scopedSlots[colName]
                                ? _vm._t(colName, null, {
                                    item: item,
                                    index: itemIndex + _vm.firstItemIndex,
                                  })
                                : _c(
                                    "td",
                                    {
                                      key: index,
                                      class: _vm.cellClass(
                                        item,
                                        colName,
                                        index
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(String(item[colName])) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                            ]
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.$scopedSlots.details
                        ? _c(
                            "tr",
                            {
                              key: "details" + itemIndex,
                              staticClass: "p-0",
                              staticStyle: { border: "none !important" },
                            },
                            [
                              _c(
                                "td",
                                {
                                  staticClass: "p-0",
                                  staticStyle: { border: "none !important" },
                                  attrs: { colspan: _vm.colspan },
                                },
                                [
                                  _vm._t("details", null, {
                                    item: item,
                                    index: itemIndex + _vm.firstItemIndex,
                                  }),
                                ],
                                2
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  }),
                  _vm._v(" "),
                  _vm.currentItems && !_vm.currentItems.length && !_vm.loading
                    ? _c("tr", [
                        _c(
                          "td",
                          { attrs: { colspan: _vm.colspan } },
                          [
                            _vm._t("no-items-view", [
                              _c("div", { staticClass: "text-center my-5" }, [
                                _c(
                                  "h2",
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.passedItems &&
                                          _vm.passedItems.length
                                          ? "No filtering results "
                                          : "No items"
                                      ) + "\n                    "
                                    ),
                                    _c("CIcon", {
                                      staticClass: "text-danger mb-2",
                                      attrs: {
                                        width: "30",
                                        content: _vm.$options.icons.cilBan,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _vm.footer && _vm.currentItems.length > 0
                ? _c("tfoot", [
                    _c(
                      "tr",
                      [
                        _vm._l(_vm.columnNames, function (name, index) {
                          return [
                            _c(
                              "th",
                              {
                                key: index,
                                class: [
                                  _vm.headerClass(index),
                                  _vm.sortingIconStyles,
                                ],
                                style: _vm.headerStyles(index),
                                on: {
                                  click: function ($event) {
                                    return _vm.changeSort(
                                      _vm.rawColumnNames[index],
                                      index
                                    )
                                  },
                                },
                              },
                              [
                                _vm._t(_vm.rawColumnNames[index] + "-header", [
                                  _c("div", { staticClass: "d-inline" }, [
                                    _vm._v(_vm._s(name)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.isSortable(index)
                                  ? _vm._t(
                                      "sorting-icon",
                                      [
                                        _c("CIcon", {
                                          class: _vm.iconClasses(index),
                                          attrs: {
                                            width: "18",
                                            content:
                                              _vm.$options.icons.cilArrowTop,
                                          },
                                        }),
                                      ],
                                      { state: _vm.getIconState(index) }
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._t("customFooter"),
              _vm._v(" "),
              _vm._t("caption"),
            ],
            2
          ),
          _vm._v(" "),
          _vm.loading
            ? _vm._t("loading", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "absolute",
                      left: "0",
                      top: "0",
                      bottom: "0",
                      right: "0",
                      "background-color": "rgb(255,255,255,0.4)",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          bottom: "50%",
                          left: "50%",
                          transform: "translateX(-50%)",
                        },
                      },
                      [_c("CSpinner", { attrs: { color: "success" } })],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("under-table"),
      _vm._v(" "),
      _vm.pagination
        ? _c(
            "CPagination",
            _vm._b(
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.totalPages > 1,
                    expression: "totalPages > 1",
                  },
                ],
                attrs: { activePage: _vm.page, pages: _vm.totalPages },
                on: {
                  "update:activePage": function ($event) {
                    _vm.page = $event
                  },
                  "update:active-page": function ($event) {
                    _vm.page = $event
                  },
                },
              },
              "CPagination",
              typeof _vm.pagination === "object" ? _vm.pagination : null,
              false
            )
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }