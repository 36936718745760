var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("lookup", {
    key: _vm.clientId,
    style: _vm.style,
    attrs: {
      optionsEndpoint: _vm._optionsEndpoint,
      label: _vm.label !== false ? _vm.label || "Service" : false,
      isValid: _vm.isValid,
      invalidFeedback: _vm.invalidFeedback,
      placeholder: _vm.placeholder,
      clearable: _vm.clearable !== undefined ? _vm.clearable : true,
      containerClass: _vm.containerClass || "",
      fieldClass: _vm.fieldClass,
      labelClass: _vm.labelClass,
      includeProperties: _vm._includeProperties,
      multiple: _vm.multiple,
      queryParams: _vm.queryParams,
    },
    on: {
      change: function ($event) {
        return _vm.$emit("change", $event)
      },
      "loaded-options": function ($event) {
        return _vm.$emit("loaded-options", $event)
      },
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v
      },
      expression: "selected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }