// import * as userService from '../_services';
// import Moment from 'moment';

// function calcRemaining(t,o,k) {
//   switch(t)
//   {
//     case 'all':
//       return o.work_minutes[k] - (o.nonbillable[k] + o.billable[k])
//     case 'billable':
//       return Math.max(0,o.qual_target[k] - o.billable[k])
//     default:
//       throw 'Unexpected t: ' + t
//   }
// }

// function calcFullRemaining(o,k) {
//   const remainderToQualifying = Math.max(0,o.qual_target[k] - o.billable[k])
//   const remainderToFull       = Math.max(0,o.full_target[k] - o.billable[k] - remainderToQualifying)
//   return remainderToFull
// }


// function getTotal(acc,k,x){
//   acc.billable[k] += x.billable
//   acc.nonbillable[k] += x.nonbillable
//   acc.pto[k] += x.locum
//   acc.work_minutes[k] += x.work_minutes
//   acc.qual_target[k] += x.qual_target
//   acc.full_target[k] += x.full_target
// }

export const users = {
  namespaced: true,
  state: {
    agg: {},
    rawAgg: {},
    approvalList: {},
    userList: {},
    type: 'all',
    balances: []
  },
  actions: {
    // async getAggregate({commit}, data) {
    //   commit('getRequest')
    //   try
    //   {
    //     const agg = await userService.getAggregate(data.start, data.end, data.email)
    //     commit('setRawAgg',{ 'agg':agg,'day':data.day })
    //     commit('setAgg')
    //   }
    //   catch(error)
    //   {
    //     commit('getAllFailure', error)
    //   }
    // },
    // async getBalances({ commit },data){
    //   commit('setBalances',null)
    //   const balances = await userService.getBalances(data.userId,data.day)
    //   commit('setBalances',balances)
    // },
    // async getAllUsers({ commit }){
    //   commit('setUsers',null)
    //   const users = await userService.getUsers()
    //   commit('setUsers',users)
    // },
    // async deleteRequest({ commit,dispatch },data){
    //   await userService.deleteRequest(data.request_email,data.request)
    //   dispatch('getUserRequestList',{
    //     'email':data.request_email,
    //   })
    // },
    // async approveDeleteRequest({ commit,dispatch,rootState },data){
    //   await userService.deleteRequest(data.request_email,data.request)
    //   dispatch('getApprovalList', {
    //     email: rootState.authentication.details.email,
    //   })
    // },
    // async postCompAdd({ commit,dispatch },data){
    //   await userService.postCompAdd(data.user_id,data.quantity)
    //   dispatch('alert/success', 'Good Job', { root: true });
    //   data.success()
    // },
    // sendRequestOff({commit,dispatch}, data) {
    //   commit('getRequest');
    //   userService.sendRequestOff(data.email, data.requests)
    //     .then(
    //       success => {
    //         dispatch('alert/success', 'Good Job', { root: true });
    //         dispatch('getUserRequestList',{
    //           'email':data.email,
    //         })
    //         data.success()
    //       },
    //       error => {
    //         commit('getAllFailure', error)
    //         dispatch('alert/error', error, { root: true });
    //       }
    //     );
    // },
    // async getApprovalList({commit,dispatch}, data) {
    //   commit('getRequest');
    //   try
    //   {
    //     const approvalList = await userService.getApprovalList(data.email)
    //     commit('setApprovalList',await Promise.all(approvalList.map(async (x) => {
    //       const balances = await userService.getBalances(x.user_id,x.day)
    //       const balance = balances.find((y) => y.leave_type_name == x.leave_type_name) || {}
    //       x.balance = balance.total ? balance.minutes / 60 + ' hours' : 'N/A'
    //       return x
    //     })))
    //   }
    //   catch(error)
    //   {
    //     commit('getAllFailure', error)
    //   }
    // },
    // getUserRequestList({commit,dispatch}, data) {
    //   commit('getRequest');
    //   userService.getUserRequestList(data.email)
    //     .then(
    //       userList => {
    //         commit('setUserRequest', userList)
    //       },
    //       error => commit('getAllFailure', error)
    //     );
    // },
    // sendApproval({ commit,dispatch,rootState }, data) {
    //   commit('getRequest');
    //   userService.sendApproval(data.request_email, data.request)
    //     .then(
    //       success => {
    //         dispatch('alert/success', 'Good Job', { root: true });
    //       },
    //       error => {
    //         commit('getAllFailure', error)
    //         dispatch('alert/error', error, { root: true });
    //       },
    //     )
    //     .finally(_=>{
    //         dispatch('getApprovalList', {
    //           email: rootState.authentication.details.email,
    //         })
    //       }
    //     );
    // },
    // deny({commit,dispatch}, data) {
    //   dispatch('alert/error', 'I would rather not', { root: true })
    // },
    // setType({commit}, data) {
    //   commit('setType', data)
    //   commit('setAgg',data)
    // }
  },
  methods: {

  },
  mutations: {
    getRequest(state) {
      state.all = {
        loading: true
      };
    },
    // setApprovalList(state,approvalList){
    //   state.approvalList = approvalList.map(x=>
    //     [{type:'string', display: x.user_id},
    //      {type:'string', display: x.leave_type_name},
    //      {type:'date'  , display: x.day},
    //      {type:'time'  , display: x.start},
    //      {type:'period', display: x.duration},
    //      {type:'string', display: x.balance},

    //   ].concat(
    //     x.requestdelete ? [
    //       [
    //         {type:'button', action: store =>( store.dispatch('users/approveDeleteRequest', {
    //           request_email: x.user_id,
    //           request: x.id
    //         })),display: 'Approve Removal'}
    //       ]
    //     ]:
    //     [
    //       [
    //         {type:'button', action: store =>( store.dispatch('users/sendApproval', {
    //           request_email: x.user_id,
    //           request: x.id
    //         })),display: 'Approve'},
    //         {type:'button',  action: store =>( store.dispatch('users/deny', {
    //         })),display: 'Deny'},
    //       ]
    //     ]
    //   )
    //   )
    // },
    // setBalances(state,balances){
    //   state.balances = balances ? balances.map(x => ({
    //     ...x,
    //   })) : null
    // },
    setUsers(state,users){
      state.userList = users
    },
    // setUserRequest(state,userList){
    //   state.userList = userList.map(x=>
    //     [{type:'string', display: x.leave_type_name},
    //      {type:'date'  , display: x.day},
    //      {type:'time'  , display: x.start},
    //      {type:'period', display: x.duration},
    //      {type:'string', display: x.requestdelete ? "Pending Removal" : x.approved ? 'Approved' : x.denied ? 'Denied' : 'Pending'},
    //   ].concat(
    //     x.requestdelete ?
    //     []
    //     :
    //     [[
    //       {type:'button', action: store =>( store.dispatch('users/deleteRequest', {
    //         request_email: x.user_id,
    //         request: x.id
    //       })),display: 'Delete'},
    //     ]]
    //   )
    //   )
    // },
    // setAgg(state) {
    //   const { billable,nonbillable,pto } = state.rawAgg
    //   state.agg =  state.type == 'all' ?  { billable,nonbillable,pto } : {billable}
    //   state.agg['qual_remaining'] = {
    //     week: calcRemaining(state.type,state.rawAgg,'week'),
    //     month: calcRemaining(state.type,state.rawAgg,'month'),
    //     year: calcRemaining(state.type,state.rawAgg,'year'),
    //     title: state.type == 'all' ? "Remaining" : "Initial Qualifying"
    //   }
    //   if(state.type != 'all'){
    //     state.agg['init_remaining'] = {
    //       week: calcFullRemaining(state.rawAgg, 'week'),
    //       month: calcFullRemaining(state.rawAgg, 'month'),
    //       year: calcFullRemaining(state.rawAgg, 'year'),
    //       title: "Full Qualifying"
    //     }
    //   }
    // },
    // setRawAgg(state,data) {
    //   var that = this
    //   const momentDay = Moment(data.day)
    //   state.rawAgg = data.agg.reduce((acc, x) => {
    //     if (momentDay.isSame(x.day, 'week')) {
    //       getTotal(acc,'week',x)
    //     }
    //     if (momentDay.isSame(x.day, 'month')) {
    //       getTotal(acc,'month',x)
    //     }
    //     if (momentDay.isSame(x.day, 'year')) {
    //       getTotal(acc,'year',x)
    //     }
    //     return acc
    //   }, {
    //     billable    : {title:'Billable'   ,week: 0, month: 0, year: 0},
    //     nonbillable : {title:'Nonbillable',week: 0, month: 0, year: 0},
    //     pto         : {title:'P.T.O'      ,week: 0, month: 0, year: 0},
    //     work_minutes: {title:'Expected'   ,week: 0, month: 0, year: 0},
    //     qual_target : {title:'Target'     ,week: 0, month: 0, year: 0},
    //     full_target : {title:'Full Target',week: 0, month: 0, year: 0}
    //   })
    // },
    setType(state,value) {
      state.type = value
    },
    getAllFailure(state, error) {
      state.all = {
        error
      };
    }
  },

}
