<template>
    <div v-fragments>
        <span class="badge" v-if="type == 'item'" :class="{
            'badge-secondary': name == 'Draft',
            'badge-danger': name == 'Reverted' || name == 'Overdue',
            'badge-warning': name == 'Pending',
            'badge-primary': name == 'ZohoInvoiceDraft',
            'badge-success': name == 'Paid',
            'badge-info': name == 'InternalCharge'}">
            {{formatLabel(name)}} <span v-if="name == 'Overdue'">{{days}}d</span>
        </span>
        <span class="badge" v-if="type == 'import'" :class="{
            'badge-success-light': name == 'ImportCompleted',
            'badge-secondary-light': name == 'Draft',
            'badge-danger-light': name == 'Reverted',
            'badge-warning-light': name == 'PartiallyReverted'}">
            {{formatLabel(name)}}
        </span>
    </div>
</template>

<script>
import { TextFormatter } from '../mixins'
export default {
    mixins: [TextFormatter],
    props: ['type','days', 'name']
}
</script>