<template>
	<div class="form-group">
		<table class="table">
			<tr>
				<th>Service</th>
				<th>Level <CIcon name="cid-info-circle" class="icon-tooltip" v-c-tooltip="{placement: 'bottom', boundaries: 'window', content:'If unsure of which level to choose, select Unauthorized and add a note below.'}"/></th>
				<th>Notes</th>
				<th>Feedback</th>
				<th>Receive Emails</th>
				<th v-if="authorizedUser.makeContactUser">Always CC <CIcon name="cid-info-circle" class="icon-tooltip" v-c-tooltip="{placement: 'bottom', boundaries: 'window', content:'Select this person to be copied on invoices by default.'}"/></th>
				<th>Left Google Review</th>
				<th style="width: 10%"></th>
			</tr>
			<tr v-for="service in linkedServices" :key="service.serviceId">
				<td>{{service.serviceName}}</td>
				<td>
					<AuthUserLevelSelect
						:label="null"
						v-model="service.level"
					/>
				</td>
				<td >
					<CTextareaWrapper
						v-model="service.notes"
					/>
				</td>
				<td>
					<Toggle
						v-model="service.feedbackEnabled"
					/>
				</td>
				<td>
					<Toggle v-model="service.canReceiveEmails" />
				</td>
				<td v-if="authorizedUser.makeContactUser">
					<Toggle v-model="service.alwaysCC" />
				</td>
				<td>
					<Toggle v-model="service.leftGoogleReview" />
				</td>
				<td>
					<CButton @click="removeFromService(service.serviceId)" v-c-tooltip="'Remove user from service'"><CIcon name="cil-trash" /></CButton>
				</td>
			</tr>
			<tbody v-if="!apiRequest">
				<tr v-for="service in unlinkedServices" :key="service.id">
					<td>{{service.name}}</td>
					<td :colspan="authorizedUser.makeContactUser ? 5 : 4">
						<CButton variant="outline" color="secondary" @click="addToService(service)"><CIcon name="cil-plus" /> Add To Service</CButton>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import AuthUserLevelSelect from '../../components/AuthUserLevelSelect'
import { AxiosWrapper } from '../../mixins'
export default {
	props: ['value', 'authorizedUser', 'client', 'addServices', 'removeServices'],
	components: {AuthUserLevelSelect},
	mixins: [AxiosWrapper],
	data(){
		return {
			apiRequest: null,
			allServices: [],
		}
	},
	computed: {
		linkedServices:{
			get() {return this.value },
			set(v) { this.$emit('input', v) }
		},
		unlinkedServices(){
			if (this.allServices.length){
				const linkedServiceIds = this.linkedServices.map(s => s.serviceId)
				return this.allServices.filter(s => !linkedServiceIds.includes(s.id))
			}
			return []
		},
	},
	methods: {
		addToService(service){
			try {
				const data = {
					serviceName: service.name,
					authorizedUserId: this.authorizedUser.id,
					serviceId: service.id,
					level: 'Unauthorized',
					receiveFeedbackEmails: false,
					canReceiveEmails: service.canReceiveEmails,
					alwaysCC: false,
					notes: null,
				}
				this.$emit('update:removeServices', this.removeServices.filter(id => id != service.id))
				const added = this.addServices;
				added.push(data)
				this.$emit('update:addServices', added)
				this.linkedServices.push(data)
			}
			finally {
				this.apiRequest = null
			}
		},
		removeFromService(serviceId){
			this.$emit('update:addServices', this.addServices.filter(e => e.serviceId != serviceId))
			const removed = this.removeServices;
			removed.push(serviceId)
			this.$emit('update:removeServices', removed)
			this.linkedServices = this.linkedServices.filter(s => s.serviceId != serviceId)
		},
	},
	async mounted(){
		try {
			const response = await (this.apiRequest = this.get('/api/services/getSubscriptionOptions/'+this.client.id))
			this.allServices = response.data
		}
		finally {
			this.apiRequest = null
		}

	}
}
</script>
