<template>
    <span v-if="(userLeaves || []).length > 0" class="pointer">
		<Icon name="cid-flight-takeoff" :el-attr="{id:id}"/>
        <b-popover :target="id" triggers="hover" placement="top">
			<div v-for="userLeave in userLeaves" :key="userLeave.id">
        		<Icon :name="userLeave.type == 'Flex' ? 'cil-clock' : 'cid-flight-takeoff'" :el-attr="{id: id}"/>
				{{userLeave.type}} - {{userLeaveTooltip(userLeave, date)}}
				<span class="badge badge-primary" v-if="userLeave.leaveStatus == 'Pending'">Pending</span>
			</div>
        </b-popover>
    </span>
</template>

<script>

export default {
    props:['userLeaves', 'date'],
    data(){
        return{
            id: this.generateUniqueCode()
        }
    },
    methods:{
        userLeaveTooltip(userLeave, weekDate){
            if(userLeave.dates){
                var leaveDateDetails = userLeave.dates.find(s=> this.compareDate(s.date, '===', weekDate))
                return leaveDateDetails ? leaveDateDetails.timespanStr : ''
            }
            return ''
        },
    }
}
</script>
