<template>
    <div v-fragments>
        <span v-bg-color="(stage || {}).bgClr || 'default'" class="badge pointer" :id="uniqueCode">
            <Icon v-if="(stage || {}).icon" size="14px" :name="(stage || {}).icon" icon-pack="atc" />
            {{(stage || {}).name}}
        </span>
        <DxTooltip v-if="tooltip" :target="'#'+uniqueCode" show-event="mouseenter" hide-event="mouseleave" position="bottom">
            {{tooltip}}
        </DxTooltip>
    </div>
</template>

<script>
import { DxTooltip } from 'devextreme-vue/tooltip';

export default {
    props: ['stage', 'tooltip'],
    components:{DxTooltip},
    data(){
        return{
            uniqueCode: this.generateUniqueCode()
        }
    }
}
</script>
