var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("lookup", {
    attrs: {
      optionsEndpoint: _vm.optionsEndpoint,
      label: _vm.label || "Zoho Project",
      placeholder: "Select Zoho Project",
      isValid: _vm.isValid,
      invalidFeedback: _vm.invalidFeedback,
      displayKey: "name",
      containerClass: _vm.containerClass || "",
      fieldClass: _vm.fieldClass,
      labelClass: _vm.labelClass,
      includeProperties: _vm.includeProperties,
      disabled: _vm.disabled,
    },
    on: {
      change: function ($event) {
        return _vm.$emit("change", $event)
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "description",
          fn: function () {
            return [
              _vm._t("description"),
              _vm._v(" "),
              _vm.enableImport
                ? _c(
                    "span",
                    [
                      !_vm.apiRequest
                        ? _c(
                            "p",
                            { staticClass: "form-text text-muted w-100" },
                            [
                              _vm._v(
                                "Don't see the project you're looking for? "
                              ),
                              _c(
                                "CButton",
                                {
                                  attrs: { color: "link" },
                                  on: { click: _vm.importProjects },
                                },
                                [_vm._v("Click Here")]
                              ),
                            ],
                            1
                          )
                        : _c("spinner", { staticClass: "spinner-inline-sm" }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v
      },
      expression: "selected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }