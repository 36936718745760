var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    [
      _c(
        "CCardHeader",
        {
          class: _vm.headerClass || "pointer d-flex justify-content-between",
          attrs: { color: _vm.color },
          on: {
            click: function ($event) {
              _vm.collapse = !_vm.collapse
            },
          },
        },
        [
          _vm._t("header-content", [_c("strong", [_vm._v(_vm._s(_vm.title))])]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-md-flex align-items-center flex-wrap mt-2 mt-md-0",
            },
            [
              _vm._t("headerButton"),
              _vm._v(" "),
              _c("CIcon", { attrs: { name: _vm.icon } }),
            ],
            2
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "CCollapse",
        { attrs: { show: !_vm.collapse } },
        [_c("CCardBody", [_vm._t("default")], 2)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }