<template>
	<lookup
		optionsEndpoint="/api/invoices/taxExemptions"
		:label="label !== false ? (label || 'Tax Exemption') : false"
		valueKey="zohoTaxExemptionId"
		displayKey="taxExemptionCode"
		labelClass="col-form-label col-sm-3 required"
		:fieldClass="fieldClass || 'col-sm-9'"
		v-model="selected"
		:isValid="isValid"
		:invalidFeedback="invalidFeedback"
		:placeholder="placeholder"
		:clearable="clearable !== undefined ? clearable : true"
		:containerClass="containerClass || ''"
		:fieldCol="fieldCol"
		:style="style"
		:sort="false"
		:disabled="disabled"
		:autoSelectOnlyOption="autoSelectOnlyOption"
		@change="$emit('change', $event)"
		@loaded-options="loadedOptions"
	></lookup>
</template>

<script>

import Lookup from '../generic/Lookup'

export default {
	props: ['value', 'isValid', 'invalidFeedback', 'label', 'placeholder', 'clearable', 'containerClass', 'fieldCol', 'minWidth', 'fieldClass', 'disabled', 'autoSelectOnlyOption'],
	components: {Lookup},
	computed: {
		selected: {
			get(){
				return this.value;
			},
			set(v){
				this.$emit('input', v);
			}
		},
		style(){
			return this.minWidth ? {
				'min-width': this.minWidth
			} : {};
		}
	},
	methods: {
		loadedOptions(options){
			if (this.selected && this.selected.zohoTaxExemptionId){
				const filtered = options.filter(o => o.zohoTaxExemptionId == this.selected.zohoTaxExemptionId)
				if (filtered.length){
					this.$emit('preselected-option-text', filtered[0].taxExemptionCode)
				}
			}
			this.$emit('loaded-options', options)
		}
	}
}
</script>
