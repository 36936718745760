var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _c(
      "table",
      { staticClass: "table" },
      [
        _c("tr", [
          _c("th", [_vm._v("Service")]),
          _vm._v(" "),
          _c(
            "th",
            [
              _vm._v("Level "),
              _c("CIcon", {
                directives: [
                  {
                    name: "c-tooltip",
                    rawName: "v-c-tooltip",
                    value: {
                      placement: "bottom",
                      boundaries: "window",
                      content:
                        "If unsure of which level to choose, select Unauthorized and add a note below.",
                    },
                    expression:
                      "{placement: 'bottom', boundaries: 'window', content:'If unsure of which level to choose, select Unauthorized and add a note below.'}",
                  },
                ],
                staticClass: "icon-tooltip",
                attrs: { name: "cid-info-circle" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("th", [_vm._v("Notes")]),
          _vm._v(" "),
          _c("th", [_vm._v("Feedback")]),
          _vm._v(" "),
          _c("th", [_vm._v("Receive Emails")]),
          _vm._v(" "),
          _vm.authorizedUser.makeContactUser
            ? _c(
                "th",
                [
                  _vm._v("Always CC "),
                  _c("CIcon", {
                    directives: [
                      {
                        name: "c-tooltip",
                        rawName: "v-c-tooltip",
                        value: {
                          placement: "bottom",
                          boundaries: "window",
                          content:
                            "Select this person to be copied on invoices by default.",
                        },
                        expression:
                          "{placement: 'bottom', boundaries: 'window', content:'Select this person to be copied on invoices by default.'}",
                      },
                    ],
                    staticClass: "icon-tooltip",
                    attrs: { name: "cid-info-circle" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("th", [_vm._v("Left Google Review")]),
          _vm._v(" "),
          _c("th", { staticStyle: { width: "10%" } }),
        ]),
        _vm._v(" "),
        _vm._l(_vm.linkedServices, function (service) {
          return _c("tr", { key: service.serviceId }, [
            _c("td", [_vm._v(_vm._s(service.serviceName))]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("AuthUserLevelSelect", {
                  attrs: { label: null },
                  model: {
                    value: service.level,
                    callback: function ($$v) {
                      _vm.$set(service, "level", $$v)
                    },
                    expression: "service.level",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("CTextareaWrapper", {
                  model: {
                    value: service.notes,
                    callback: function ($$v) {
                      _vm.$set(service, "notes", $$v)
                    },
                    expression: "service.notes",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("Toggle", {
                  model: {
                    value: service.feedbackEnabled,
                    callback: function ($$v) {
                      _vm.$set(service, "feedbackEnabled", $$v)
                    },
                    expression: "service.feedbackEnabled",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("Toggle", {
                  model: {
                    value: service.canReceiveEmails,
                    callback: function ($$v) {
                      _vm.$set(service, "canReceiveEmails", $$v)
                    },
                    expression: "service.canReceiveEmails",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.authorizedUser.makeContactUser
              ? _c(
                  "td",
                  [
                    _c("Toggle", {
                      model: {
                        value: service.alwaysCC,
                        callback: function ($$v) {
                          _vm.$set(service, "alwaysCC", $$v)
                        },
                        expression: "service.alwaysCC",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("Toggle", {
                  model: {
                    value: service.leftGoogleReview,
                    callback: function ($$v) {
                      _vm.$set(service, "leftGoogleReview", $$v)
                    },
                    expression: "service.leftGoogleReview",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c(
                  "CButton",
                  {
                    directives: [
                      {
                        name: "c-tooltip",
                        rawName: "v-c-tooltip",
                        value: "Remove user from service",
                        expression: "'Remove user from service'",
                      },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.removeFromService(service.serviceId)
                      },
                    },
                  },
                  [_c("CIcon", { attrs: { name: "cil-trash" } })],
                  1
                ),
              ],
              1
            ),
          ])
        }),
        _vm._v(" "),
        !_vm.apiRequest
          ? _c(
              "tbody",
              _vm._l(_vm.unlinkedServices, function (service) {
                return _c("tr", { key: service.id }, [
                  _c("td", [_vm._v(_vm._s(service.name))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      attrs: {
                        colspan: _vm.authorizedUser.makeContactUser ? 5 : 4,
                      },
                    },
                    [
                      _c(
                        "CButton",
                        {
                          attrs: { variant: "outline", color: "secondary" },
                          on: {
                            click: function ($event) {
                              return _vm.addToService(service)
                            },
                          },
                        },
                        [
                          _c("CIcon", { attrs: { name: "cil-plus" } }),
                          _vm._v(" Add To Service"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
              0
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }