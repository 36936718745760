<template>
	<DxPopup
        v-if="popup.isPopup"
		:visible="popup.show"
		:on-hidden="closeButtonOptions"
		:drag-enabled="false"
		:scroll-enabled="true"
		:hide-on-outside-click="hideOnOutsideClick"
		:show-close-button="showCloseButton"
		:show-title="showTitle"
		:width="width"
		:height="height"
		:title="title"
        :max-height="maxHeight"
        :wrapper-attr="elAttr"
		titleTemplate="title">
            <slot v-if="overrideTitleSlot" name="title" />
			<template #title v-else>
				<div class="dx-toolbar dx-widget dx-visibility-change-handler dx-collection dx-popup-title dx-has-close-button" role="toolbar">
					<div class="d-flex align-items-center justify-content-between">
						<div class="dx-item dx-toolbar-item dx-toolbar-label">
							{{title}} <slot name="header-before" />
						</div>
						<div class="dx-toolbar-center">
							<slot name="header-center" />
						</div>
						<div class="d-flex align-items-center justify-content-between">
							<slot name="header-after" />
							<DxButton class="popupCloseBtn" icon="close" styling-mode="text" @click="closeButtonOptions" />
						</div>
					</div>
				</div>
			</template>

			<DxScrollView width="100%" height="100%" show-scrollbar="always" v-if="nativeScroll">
				<div :class="{'pr-10px': !noPadding}">
					<slot />
				</div>
			</DxScrollView>
            <div v-else v-height="'100%'">
                <slot />
            </div>
	</DxPopup>
</template>

<script>
import { DxPopup} from 'devextreme-vue/popup';
import DxButton from 'devextreme-vue/button';
import { DxScrollView } from 'devextreme-vue/scroll-view'; 
export default{
	name:'Popup',
	props:{
		'hide-on-outside-click': {
			type: Boolean,
			default: true
 		},
		'width':{
			type: String,
			default: '50%'
		},
		'height':{
			type: String,
			default: 'auto'
		},
        'max-height':{
			type: String,
			default: '90%'
		},
		'title':{
			type: String,
			default: ''
		},
		'show-title':{
			type: Boolean,
			default: true
		},
		'destroyOnClose':{
			type: Boolean,
			default: false
		},
		'contentCloseBtn':{
			type: Boolean,
			default: false
		},
        'show-close-button':{
            type: Boolean,
            default: true
        },
		'native-scroll':{
			type: Boolean,
			default: true
		},
        'wrapper-attr':{
			type: Object,
			default: () => {}
		},
        'body-class':{
            type: String,
			default: ''
        },
        'no-padding':{
            type: Boolean,
            default: false
        },
        'override-title-slot':{
            type: Boolean,
            default: false
        },

	},
	components:{
		DxPopup,
		DxScrollView,
		DxButton
	},
	methods:{
		show(){
            this.popup.isPopup = true
			this.popup.show = true
		},
		hide(){
            this.closeButtonOptions()
		},
        closeButtonOptions(){ 
            this.popup.show = false; 
            this.$emit('hidden')
            if(this.destroyOnClose){
                this.popup.isPopup = false
            }
        }
	},
	data(){
		return {
			popup:{
				show: false,
                isPopup: false
			}
		}
	},
    computed:{
        elAttr(){
            let _class = ''
            if(this.noPadding){
                _class = 'popup-no-padding'
            }
            else{
                if(this.nativeScroll){
                    _class = 'popup-scrollbar-padding'
                }
                else{
                    _class = 'popup-equal-padding'
                }
            }
            if(this.wrapperAttr){
                if(this.wrapperAttr.hasOwnProperty('class')) this.wrapperAttr.class += ` ${_class}`
                else this.wrapperAttr.class = _class
                return this.wrapperAttr
            }
            else{
                return { class: _class }
            }
        }
    }
}
</script>
<style>
.popupCloseBtn{
	margin-right: -10px;
    margin-left: 10px;
}
.popup-scrollbar-padding .dx-popup-content {
	padding: 20px 5px 20px 20px !important;
}
.popup-equal-padding .dx-popup-content{
	padding: 20px !important;
}
.popup-no-padding .dx-popup-content {
	padding: 0 !important;
}
.dx-popup-content{
    height:100%
}
.dx-popup-content .dx-popup-footer{
    bottom: 0;
    position: fixed;
    padding: 15px;
    background: #f8f8f8;
    width: 100%;
}
.pr-10px{
    padding-right: 10px;
}
</style>