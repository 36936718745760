import moment from 'moment-timezone'
import { DayOfWeek } from '../_helpers/constants'
import { camelCase } from 'lodash';

const _shallowClone = (obj) => {
	return Object.assign({}, obj)
}
const _deepClone = (obj, maxDepth, currentDepth) => {
	maxDepth = maxDepth || 32
	currentDepth = currentDepth || 0
	if (currentDepth > maxDepth){
		console.error('Deep Clone exceeded max depth (' + maxDepth + ')')
		return obj;
	}
	const obj2 = _shallowClone(obj)
	Object.keys(obj2).forEach(k => {
		if (obj2[k] && typeof obj2[k] === 'object' && obj2[k].constructor === Object){
			obj2[k] = _deepClone(obj2[k], maxDepth, currentDepth+1)
		}
	});
	return obj2
}

//https://stackoverflow.com/a/14438954/8633449
const onlyUnique = (value, index, self) => {
	return self.indexOf(value) === index;
}

export const HelperMethods = {
	methods: {
		distinctArray(items){
			return [...new Set(items)]
		},
		shallowClone(obj){
			return _shallowClone(obj)
		},
		deepClone(obj, maxDepth){
			maxDepth = maxDepth || 32
			return _deepClone(obj, maxDepth, 0)
		},
        find(list, id){
            return this.cleanSource(list.find(s=>s.id == id))
		},
        findIndex(list, id){
            return list.findIndex(s=>s.id == id)
		},
        findDeep(list, id){
			return list.find(s=>s.id == id)
		},
		unique(items){
			return items.filter(onlyUnique)
		},
		tryParse(value){
			try {
				return JSON.parse(value)
			} catch {
				return false
			}
		},
        removeItem(list, val, prop){
            if(!prop) prop = 'id'
            return list.filter(s=> s[prop] != val)
        },
        compareDate(start, operator, end){
            if(!start || !end) return null
			if (typeof start == 'string') {
				start = start.substring(0, 10) + ' 00:00:00'
			}
			if (typeof end == 'string') {
				end = end.substring(0, 10) + ' 00:00:00'
			}
			const date1 = new Date(start); date1.setHours(0, 0, 0, 0)
            const date2 = new Date(end); date2.setHours(0, 0, 0, 0)
            let result = false
            if(operator == '===') result = date1.toDateString() == date2.toDateString()
            else if(operator == '>') result = date1 > date2
            else if(operator == '<') result = date1 < date2
            else if(operator == '>=') result = date1 >= date2
            else if(operator == '<=') result = date1 <= date2
            return result
        },
        nextWeeks(number){
            let firstMonday = this.weekDayOfDate('monday')
            let mondayDates = [firstMonday]
            let formattedMondays = [moment(firstMonday).format('MM/DD/YYYY')]
            for (let index = 0; index < number; index++) {
                let d = new Date(mondayDates[index])
                d.setDate((d.getDate()+7))
                let nextMonday = this.weekDayOfDate('monday', d)
                mondayDates.push(nextMonday)
                formattedMondays.push(moment(nextMonday).format('MM/DD/YYYY'))
            }
            return formattedMondays
        },
        weekDayOfDate(day, result)
		{
            if(typeof day == 'number') day = DayOfWeek.getDayOfWeek(day)
            var days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
            let dayNumber = days.findIndex(s => s == day.toLowerCase()) + 1
            if(!result) result = new Date();
            else result = new Date(result);
            const first = result.getDate() - result.getDay() + dayNumber;
            return new Date(result.setDate(first));
		},
        getFirstLastAndDayOfWeek(){
            let today = new Date();
            let firstDayOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
            let lastDayOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 6);

            let startDate = new Date(firstDayOfWeek);
            let endDate = new Date(lastDayOfWeek);

            return { startDate, endDate };
        },

        getFirstAndLastDateOfMonth() {
            let today = new Date();
            let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
          
            return { firstDayOfMonth, lastDayOfMonth };
        },

        getQuarterDates() {
            let today = new Date();
            let currentMonth = today.getMonth();
            let currentYear = today.getFullYear();
            let quarterStartMonth, quarterEndMonth;
          
            switch (Math.floor(currentMonth / 3)) {
              case 0:
                quarterStartMonth = 0;
                quarterEndMonth = 2;
                break;
              case 1:
                quarterStartMonth = 3;
                quarterEndMonth = 5;
                break;
              case 2:
                quarterStartMonth = 6;
                quarterEndMonth = 8;
                break;
              case 3:
                quarterStartMonth = 9;
                quarterEndMonth = 11;
                break;
              default:
                return null;
            }
          
            let quarterStartDate = new Date(currentYear, quarterStartMonth, 1);
            let quarterEndDate = new Date(currentYear, quarterEndMonth + 1, 0);
            return { quarterStartDate, quarterEndDate };
        },

        addDays(date, days){
            const newDate = new Date(date);
            newDate.setDate(newDate.getDate() + days);
            return newDate;
        },
        until(date1, date2){
            date1 = moment(date1)
            date2 = moment(date2)
            var dur = moment.duration( moment(date2).diff(date1) );
            return {
                yearsRemain: dur.years(),
                monthsRemain: dur.months(),
                daysRemain: dur.days(),
                hoursRemain: dur.hours(),
                minutesRemain: dur.minutes(),
                secondsRemain: dur.seconds(),
                duration: dur
            }
        },
        isNotNullOrEmpty(prop, ...args){
            if(prop == null || prop == undefined) return false
            if(typeof prop == 'string')
                return prop != '' && prop != null && prop != undefined
            if(typeof prop == 'object'){
                let propNotEmpty = true
                propNotEmpty = prop != null && prop != undefined
                propNotEmpty = propNotEmpty ? Object.keys(prop).length !== 0 : propNotEmpty
                let argNotEmpty = true
                for (let arg of args){
                    argNotEmpty = Boolean(prop[arg])
                    if(!argNotEmpty) break
                }
                return propNotEmpty && argNotEmpty
            }
        },
        validateUniqueProp(list, exceptId, prop, value){
            let isValidated = list.filter(s=>s.id != exceptId)
                                .some(s => s[prop].replace(' ','').toLowerCase() == value.replace(' ','').toLowerCase())
            return !isValidated;
        },
        validateEmptySpaces(str){
            return !(str.startsWith(' ') || str.endsWith(' '))
        },
        isDateRangeConflicting(range1, range2){
            return (this.compareDate(range1.startDate, '<=', range2.endDate) && this.compareDate(range2.startDate, '<=', range1.endDate))
        },
        isDateRangeConflictingMultiple(range, rangeList){
            let isConflicting = false
            for (let index = 0; index < rangeList.length; index++) {
                isConflicting = this.isDateRangeConflicting(range, rangeList[index])
                if(isConflicting) break
            }
            return isConflicting
        },
        isDatetimeRangeConflictingMultiple(range, rangeList){
            let isConflicting = false
            for (let index = 0; index < rangeList.length; index++) {
                let range2 = rangeList[index]
                isConflicting = new Date(range.startDate) <= new Date(range2.endDate) && new Date(range2.startDate) <= new Date(range.endDate)
                if(isConflicting) break
            }
            return isConflicting
        },
        tdShade(one, two, ...args){
            let result = Number(one) >= Number(two) ? 'td-green' : 'td-red'
            if(args.length > 0){
                result += ` ${args.join(' ')}`
            }
            return result
        },
        async getMentions(url, params){
			url = url || 'api/users/get'
            let mentions = [{
                dataSource: [],
                searchExpr: 'text',
                displayExpr: 'text',
                valueExpr: 'id',
            }]
            const response =  params ? await this.get(url, params) : await this.get(url)
            if(response){
                mentions[0].dataSource = response.data.map(obj => {
                    return {
                        id: obj.id,
                        text: obj.userName,
                        icon: obj.picture ? obj.picture : '/img/avatars/user-thumbnail.png',
                    }
                })
                return mentions
            }
            return mentions
        },
        toCamel(obj) {
            if (Array.isArray(obj)) {
                return obj.map(v => this.toCamel(v));
              } else if (obj != null && obj.constructor === Object) {
                return Object.keys(obj).reduce(
                  (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.toCamel(obj[key]),
                  }),
                  {},
                );
              }
              return obj;
        },
        formatNumber(val){
            return Number(val).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})
        },
        formatSnakeCase(val){
            if(val){
                return val.replace(/_/g, ' ').replace(/\w\S*/g, function(word){
                    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
                })
            }
            return ''
        },
        formatUtcToLocalDateTime(val, format){
            if(!format) format = 'MM/DD/YYYY hh:mm a'
            return val ? moment.utc(String(val)).local().format(format) : ''
        },
		formatNonMomentDateTime(date, format) {
			const pad = (number) => (number < 10 ? '0' : '') + number;

			const tokens = {
				YYYY: date.getFullYear(),
				MM: pad(date.getMonth() + 1),
				DD: pad(date.getDate()),
				hh: pad(date.getHours()),
				mm: pad(date.getMinutes()),
				ss: pad(date.getSeconds()),
				a: date.getHours() > 12 ? 'PM' : 'AM'
				// Add more tokens as needed
			};

			return format.replace(/YYYY|MM|DD|HH|mm|ss/g, (match) => tokens[match]);
		},
        htmlToPlainText(html){
            var temp = document.createElement('div');
            temp.innerHTML = html;
            return temp.textContent || temp.innerText || ''
        },
        stringifyDate(date){
            return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
        },
        stringifyDateRange(range){
			const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            return `{start:"${range.start.toLocaleDateString('en-US', options)}",end:"${range.end.toLocaleDateString('en-US', options)}"}`
        },
		stringifyDateTimeRange(range){
            let response = `{start:"${range.start.getFullYear()}-${range.start.getMonth() + 1}-${range.start.getDate()} ${range.start.getHours()}:${range.start.getMinutes()}:${range.start.getSeconds()}",end:"${range.end.getFullYear()}-${range.end.getMonth() + 1}-${range.end.getDate()} ${range.end.getHours()}:${range.end.getMinutes()}:${range.end.getSeconds()}"}`
			return response
        },
        generateUniqueCode(length, characters) {
            length = length || 6
            if(!characters) characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
            let code = '';
            for (let i = 0; i < length; i++) {
              const randomIndex = Math.floor(Math.random() * characters.length);
              code += characters[randomIndex];
            }
            return code;
        },
        replaceWithBr(str) {
            return str.replace(/\n/g, "<br />")
        },

        shortDate(date){
            if(date){
                let formatedDate = new Date(date);
                if(formatedDate == 'Invalid Date'){
                    formatedDate = moment(date, 'DD/MM/YYYY');
                    return formatedDate.toDate().toLocaleString([], { day: '2-digit', month: '2-digit', year: 'numeric'});
                }
                return new Date(date).toLocaleString([], { day: '2-digit', month: '2-digit', year: 'numeric'});
            }
            return 'Unknown'
        },

        formatShortDate(date){
            if(date){
                let formatedDate = new Date(date);
                if(formatedDate == 'Invalid Date'){
                    formatedDate = moment(date, 'DD/MM/YYYY');
                    return formatedDate.toDate().toLocaleString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric'});
                }
                return new Date(date).toLocaleString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric'});
            }
        },

        formatDate(date){
			if(date){
				let formatedDate = new Date(date);
				if(formatedDate == 'Invalid Date'){
					formatedDate = moment(date, 'DD/MM/YYYY');
                   return formatedDate.toDate().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
				}
				return new Date(formatedDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
			}
			return 'Unknown'
		},
        formateDateWithTime(date){
            if(date){
                return new Date(date).toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                })
            }
            return 'Unknown'
        },

        formatShortDateWithTime(date){
            if(date){
             date = new Date(date);
              const options = {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
              };

              const dateString = date.toLocaleDateString('en-US', options);
              return dateString;
            }
            return '';
        },

        getMentionedUserIds(ref){
			let dxMentions = this.$refs[ref].$el.getElementsByClassName('dx-htmleditor-content')[0].getElementsByClassName('dx-mention')
			return Array.prototype.slice.call(dxMentions).map(mention => mention.getAttribute('data-id'))
		},
		commentImgSource(file){
            let ext = file.split('.').pop()
            if(ext == 'jpg' || ext == 'png' || ext == 'jpeg') return '/img-icon.png'
            else if(ext == 'pdf') return '/pdf.png'
            else if(ext == 'docs' || ext == 'docx') return '/docs.png'
            else if(ext == 'zip') return '/zip.png'
        },
		getFileType(path){
			let ext = path.split('.').pop()
			if(ext == 'jpg' || ext == 'png' || ext == 'jpeg' || ext == 'svg') return 'Img'
            else return 'Doc'
		},

        getFirstCharOfName(name){
           return name.charAt(0).toUpperCase();
        },

        getRandomColor(){
            const red = Math.floor(Math.random() * 256);
            const green = Math.floor(Math.random() * 256);
            const blue = Math.floor(Math.random() * 256);
            const backgroundColor = `rgb(${red}, ${green}, ${blue})`;

            // Calculate text color based on luminance
            const luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;
            const textColor = luminance > 0.5 ? 'black' : 'white';

            return { backgroundColor, textColor };
        },

        getStatusColor(status){
            switch(status.toLowerCase()){
                case 'banned': 
                    return '#F04438';
                case 'admin':
                    return '#12B76A';
                case 'restricted support': 
                    return '#F4A118';
                case 'unauthorized': 
                    return '#98A2B3';
                default: 
                    return '#2E90FA';              
            }
        },

        async copyToClipboard(data, text){
            try{
                await navigator.clipboard.writeText(data);
                window.showToast({text: text, title: 'Alert', color: 'success'});
            }
            catch(error){
                console.log(error);
                window.showErrorToast('Failed to copy to clipboard');
            }
        },

        updateTextColor(backgroundColor) {
            const luminance = this.calculateLuminance(backgroundColor);
            return luminance > 0.5 ? 'black' : 'white';
        },

        calculateLuminance(color) {
            let rgbColor = this.hexToRgb(color);
            return (0.299 * rgbColor.r + 0.587 * rgbColor.g + 0.114 * rgbColor.b) / 255;
        },

        hexToRgb(hex) {
            if(!hex.includes('#')){
                hex = this.standardizeColor(hex)
            }
            hex = hex.replace(/^#/, '');
            const r = parseInt(hex.substring(0, 2), 16);
            const g = parseInt(hex.substring(2, 4), 16);
            const b = parseInt(hex.substring(4, 6), 16);
    
            return { r, g, b };
        },

        standardizeColor(str){
            var ctx = document.createElement("canvas").getContext("2d");
            ctx.fillStyle = str;
            return ctx.fillStyle;
        },
        wordCapitalize(str){
			return str.charAt().toUpperCase() + str.slice(1);
		},
        getFileSizeInMBs(size){
			const fileSizeMb = size / (1024* 1024);
			return fileSizeMb.toFixed(2)+" MB";
		},        
        getBillingTypeName(data){
            if(data != null){
                switch(data.billingType.billingTypeOption){
                    case 'AdHoc': 
                        return `Ad-Hoc (${data.agreementType})`;
                    default: 
                        return `${data.agreementType}`;
                }
            }
            else return '';
		},
        getAgreementType(data){
            if(data != null){
                switch(data.agreementLevel){
                    case 'Client': 
                        return 'Client-Level Agreement';
                    case 'ServiceUnderClient': 
                        return 'Service Under Client-Level Agreement';
                    case 'Service': 
                        return 'Service-Level Agreement';
                    default: 
                        return 'Client-Level Agreement';
                }
            }
		},
        setBackgroundOfUser(authorizedUsers){
            authorizedUsers.forEach(authUser => {
                let {backgroundColor, textColor} = this.getRandomColor();
                authUser.textClr = textColor;
                authUser.bgClr = backgroundColor;
            });
            return authorizedUsers;
        },
        // getObjectByStringProp(from, ...selectors) {
        //     return [...selectors]
        //     .map(s => s.replace(/\[([^\[\]]*)\]/g, '.$1.')
        //     .split('.')
        //     .filter(t => t !== '')
        //     .reduce((prev, cur) => prev && prev[cur], from));
        // }
        checkUserLikeDislikeNote(likes){
            if(likes){
                return likes.some(like => like.userId == this.Current_User_ID);
            }
            return false;
        }
	},
    computed:{
        currencyFormat(){
            return {type:'currency',precision:2}
        },
        percentFormat(){
            return {type:'percent',precision:2}
        },
        numberBoxPercentIcon(){
            return {
                location:'after', name:'percent', options: { icon: 'bi bi-percent', stylingMode: 'text', elementAttr: {class: 'bg-light'}}
            }
        }
    }
};
