<template>
	<Popup ref="file-preview-popup" :title="`Attachment - ${name}`" :height="size + '%'" :width="size + '%'" :no-padding="true" :wrapper-attr="{class:'file-preview-popup'}">
		<template #header-after>
			<div>
				<DxButton type="plus" @click="increaseSize()" />
				<DxButton type="minus" @click="decreaseSize()" />
			</div>
		</template>
		<div v-if="!loading">
			<div v-width="'100%'" v-if="getFileType(preview.path) == 'Img'" >
				<div class="center" >
					<div>
						<img :src="preview.data" class="img-style" />
					</div>
				</div>
			</div>
			<iframe
				width="100%"
				style="overflow: auto; height: 100vh;"
				frameborder="0"
				:src="preview.data"
				v-else  />
		</div>
		<spinner v-else />
	</Popup>
</template>

<script>
import { AxiosWrapper, HelperMethods } from '../../mixins'
import DxGallery from 'devextreme-vue/gallery';
import DxButton from 'devextreme-vue/button';

export default{
	mixins: [AxiosWrapper, HelperMethods],
	components: {DxGallery, DxButton},
	data(){
		return {
			size: 50,
			loading: false,
			files: [],
			modalKey: 0,
			name: '',
			preview: { path: '', type: '', data: '' }
		}
	},
	methods:{
		show(file){
			this.$refs['file-preview-popup'].show()
			this.name = file.name
			this.preview.path = file.path
			this.loading = true
			this.get(`api/files`, { params: { path: file.path } }).then(response => {
			 	let ext = file.path.split('.').pop()
				if(ext != 'jpg' && ext != 'png' && ext != 'jpeg' && ext != 'pdf'){
					window.location.href = window.location.origin + file
				}
				this.preview.data =  response.data
				this.loading = false
				this.$forceUpdate()
			}).finally(() => {
				this.loading = false
			})
		},
		increaseSize(){
			this.size += 10
			if(this.size > 100){
				this.size = 100
			}
		},
		decreaseSize(){
			this.size -= 10
			if(this.size < 50){
				this.size = 50
			}
		}
	}
}
</script>
<style lang="scss">
.file-preview-popup{
	.center {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.img-style{
		max-width: 100%;
	}
}
</style>
