<template>
	<lookup
		optionsEndpoint="/api/services/unlinkedZohoSubscriptions"
		:label="label || 'Zoho Subscription'"
		v-model="selected"
		:isValid="isValid"
		:invalidFeedback="invalidFeedback"
		displayKey="displayValue"
		:includeProperties="['customer_name']"
		:externalFilter="clientFilter"
		fieldClass="w-75"
		@change="$emit('change', $event)"
		@loaded-options="$emit('loaded-options', $event)"
		:disabled="disabled"
	></lookup>
</template>

<script>

import Lookup from '../generic/Lookup'

export default {
	name: 'UnlinkedSubscriptionLookup',
	props: ['value', 'isValid', 'invalidFeedback', 'label', 'clientFilter', 'disabled'],
	components: {Lookup},
	// data(){
	// 	return {
	// 		value: this.initValue,
	// 	}
	// },
	computed: {
		selected: {
			get(){
				return this.value;
			},
			set(v){
				// this.value = v;
				// this.$emit('input', v ? v.value : null);
				this.$emit('input', v);
			}
		}
	},
	// // Because this is used in a pop-up form...
	// methods: {
	// 	submit(){
	// 		this.$emit('submitted', this.selected);
	// 	}
	// }
}
</script>
