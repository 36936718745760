
import axios from 'axios';
import { authHeader } from '../_helpers'
import { TextFormatter } from './TextFormatter'

const formatLabel = TextFormatter.methods.formatLabel
const timeZoneHeader = { 'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone}

axios.interceptors.request.use(
	config => {
		config.headers = { ...config.headers,...authHeader(), ...timeZoneHeader }
		return config
	},
	error => Promise.reject(error)
)
axios.interceptors.response.use(
	(response) => {
		if (response.data.responseClass == 'ApiResponse'){
			if (!response.data.success){
				Object.keys(response.data.errors).forEach(key => {
					window.showErrorToast(response.data.errors[key], formatLabel(key))
				})
			}
			if (response.data.toasts){
				response.data.toasts.forEach((tm, index) => {
                    if(tm){
                        tm.color = tm.color.toLowerCase()
                        tm.autohide = 5000 + (2000 * index)
                        window.showToast(tm)
                    }
				})
			}
            if(response.data.json){
                response.data.data = JSON.parse(response.data.data)
            }
			return response.data
		} else if (response.data.responseClass == 'FileResponse'){
			//https://blog.web-worker.in/convert-base64-string-file-javascript/
			var sliceSize = 512;
			var byteCharacters = window.atob(response.data.data.fileString);
			var byteArrays = [];
			for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
				var slice = byteCharacters.slice(offset, offset + sliceSize);
				var byteNumbers = new Array(slice.length);
				for (var i = 0; i < slice.length; i++) {
					byteNumbers[i] = slice.charCodeAt(i);
				}
				var byteArray = new Uint8Array(byteNumbers);
				byteArrays.push(byteArray);
			}
			var blob = new Blob(byteArrays, {type: response.data.data.contentType});
			let link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.download = response.data.data.fileName
			link.click()
			return response.data
		}
		return response
	},
	(error) => {
		if(error.response && !error.__CANCEL__){
			if (error.response.status === 401){
                console.log("You do not have permission to access the resource.")
                //window.showErrorToast("You do not have permission to access the resource.")
				//window.location.href = "#/login"
			}
			if (error.response.status === 404){
				window.showErrorToast("Page or resource not found")
				window.location.href = "#/dashboard"
			}
			if(error.response.status === 400){
				if (typeof error.response.data == 'string'){
					window.showErrorToast(error.response.data)
				} else if (error.response.data && error.response.data.errors){
					Object.keys(error.response.data.errors).forEach(key => window.showErrorToast((error.response.data.errors[key]), formatLabel(key)))
				}
			}
			if (error.response.data && error.response.data.toasts){
				error.response.data.toasts.forEach((tm, index) => {
					tm.color = tm.color.toLowerCase()
					tm.autohide = 5000 + (2000 * index)
					window.showToast(tm)
				})
			}
		}
		return Promise.reject(error)
	}
)

const CancelToken = axios.CancelToken

export const AxiosWrapper = {
	data(){
		return {
			cancelTokenSource: CancelToken.source()
		}
	},
	methods: {
		cancelAxiosCall(){
			this.cancelTokenSource.cancel()
			this.cancelTokenSource = CancelToken.source()
		},
		call(url, data, method, config){
			switch (method.toLowerCase()){
				case 'get':
					return this.get(url, config);
				case 'post':
					return this.post(url, data, config);
				case 'put':
					return this.put(url, data, config);
				case 'delete':
					return this.delete(url, config);
				default:
					throw new Error('Axios wrapper call method '+method+' not implemented.');
			}
		},
		get(url, config) {
			config = Object.assign((config || {}), {
				cancelToken: this.cancelTokenSource.token
			})
			return axios.get(url, config);
		},
		post(url, data, config) {
			config = Object.assign((config || {}), {
				cancelToken: this.cancelTokenSource.token
			})
			return axios.post(url, data, config);
		},
		put(url, data, config) {
			config = Object.assign((config || {}), {
				cancelToken: this.cancelTokenSource.token
			})
			return axios.put(url, data, config);
		},
		patch(url, data, config) {
			config = Object.assign((config || {}), {
				cancelToken: this.cancelTokenSource.token
			})
			return axios.patch(url, data, config);
		},
		delete(url, config){
			config = Object.assign((config || {}), {
				cancelToken: this.cancelTokenSource.token
			})
			return axios.delete(url, config);
		}
	}
};
