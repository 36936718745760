var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VSelectWrapper", {
    attrs: {
      label: "State/Province",
      options: _vm.options,
      labelClass: "col-form-label col-sm-3",
      fieldClass: "col-sm-9",
      sort: false,
      isValid: _vm.isValid,
      invalidFeedback: _vm.invalidFeedback,
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v
      },
      expression: "selected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }