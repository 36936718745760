var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Popup",
    {
      ref: "leaveRequestPopup",
      attrs: {
        destroyOnClose: true,
        width: "40%",
        "native-scroll": true,
        "hide-on-outside-click": false,
        title: "New Leave Entry for " + _vm.Current_User_Info.FullName,
      },
      on: { hidden: _vm.closePopup },
    },
    [
      _c(
        "form",
        {
          ref: "userLeaveForm",
          attrs: { id: "userLeaveForm" },
          on: {
            submit: function ($event) {
              return _vm.submit($event)
            },
          },
        },
        [
          _vm.supervisorStatusMsg
            ? _c("label", {
                staticClass: "mb-4",
                domProps: { innerHTML: _vm._s(_vm.supervisorStatusMsg) },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "DxValidationGroup",
            {
              ref: "userLeaveValidationGroup",
              attrs: { "validation-status": _vm.validationStatus },
            },
            [
              _c("LeaveFormFields", {
                attrs: { ActionableIn: "LeaveRequest" },
                on: {
                  "reevaluating-start": function ($event) {
                    return _vm.$refs.LeaveInfoAlert.reevaluatingStart()
                  },
                  "validation-response": _vm.onValidationResponse,
                },
                model: {
                  value: _vm.userLeave,
                  callback: function ($$v) {
                    _vm.userLeave = $$v
                  },
                  expression: "userLeave",
                },
              }),
              _vm._v(" "),
              _c(
                "DxField",
                { attrs: { label: "Notes", required: true } },
                [
                  _c(
                    "DxTextArea",
                    {
                      attrs: { placeholder: "Enter Notes", height: "80" },
                      model: {
                        value: _vm.userLeave.notes,
                        callback: function ($$v) {
                          _vm.$set(_vm.userLeave, "notes", $$v)
                        },
                        expression: "userLeave.notes",
                      },
                    },
                    [
                      _c(
                        "DxValidator",
                        [
                          _c("DxRequiredRule", {
                            attrs: { message: "Notes are required" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("LeaveInfoAlert", {
                ref: "LeaveInfoAlert",
                attrs: {
                  validationResponse: _vm.validationResponse,
                  userLeave: _vm.userLeave,
                  username: _vm.username,
                  leaveBank: _vm.leaveBank,
                },
              }),
              _vm._v(" "),
              _c("DxButton", {
                staticClass: "float-right",
                attrs: {
                  type: "default",
                  text: "Submit",
                  "use-submit-behavior": true,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }