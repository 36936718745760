var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DxLoadPanel", {
    attrs: {
      container: _vm.elementId,
      visible: _vm.visible,
      shading: _vm.shading,
      position: { at: "center", of: _vm.elementId },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }