<template>
	<lookup
		:optionsEndpoint="optionsEndpoint"
		:label="label || 'Invoice'"
		valueKey="id"
		displayKey="invoiceNumberAndBalance"
		v-model="selected"
		:isValid="isValid"
		:invalidFeedback="invalidFeedback"
		:includeProperties="['balance']"
		@change="$emit('change', $event)"
	></lookup>
</template>

<script>

import Lookup from '../generic/Lookup'

export default {
	name: 'UnpaidInvoiceLookup',
	props: ['value', 'isValid', 'invalidFeedback', 'label', 'clientId'],
	components: {Lookup},
	computed: {
		optionsEndpoint() {
			return '/api/invoices/unpaidOpenInvoices/' + this.clientId
		},
		selected: {
			get(){
				return this.value;
			},
			set(v){
				this.$emit('input', v);
			}
		}
	}
}
</script>
