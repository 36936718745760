var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Popup",
    {
      ref: "actionItemPopup",
      attrs: {
        "wrapper-attr": { id: "actionItemPopup" },
        title: "Action Item " + (_vm.type ? "- " + _vm.type : ""),
        "native-scroll": true,
        "equal-padding": true,
        width: "60%",
        height: "65%",
      },
      on: { hidden: _vm.onHidden },
    },
    [
      _c("LoadPanel", {
        attrs: {
          elementId: "#actionItemPopupContent",
          shading: false,
          visible: _vm.loading,
        },
      }),
      _vm._v(" "),
      _vm.authorized
        ? _c(
            "CRow",
            {
              key: _vm.contentKey,
              staticClass: "h-100",
              attrs: { id: "actionItemPopupContent" },
            },
            [
              _c("CCol", { attrs: { lg: "6" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between align-items-center",
                  },
                  [
                    _c("h6", [
                      _c("strong", [_vm._v("Employee: ")]),
                      _vm._v(_vm._s(_vm.actionItem.user.fullName)),
                    ]),
                    _vm._v(" "),
                    _vm.actionItem.approvable
                      ? _c(
                          "div",
                          [
                            _c("CustomDxButtonVue", {
                              attrs: {
                                text: "Approve Credit",
                                "custom-class": "primary-outline",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.applyCreditNote(
                                    _vm.actionItem.creditNote
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c("h6", [
                  _c("strong", [_vm._v("Date: ")]),
                  _vm._v(
                    _vm._s(_vm._f("formatDateTime")(_vm.actionItem.timestamp))
                  ),
                ]),
                _vm._v(" "),
                _vm.actionItem.user.supervisor
                  ? _c("h6", [
                      _c("strong", [_vm._v("Supervisor: ")]),
                      _vm._v(_vm._s(_vm.actionItem.user.supervisor.fullName)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.actionItem.clientName
                  ? _c("h6", [
                      _c("strong", [_vm._v("Client: ")]),
                      _vm._v(_vm._s(_vm.actionItem.clientName)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.actionItem.projectName
                  ? _c("h6", [
                      _c("strong", [_vm._v("Service: ")]),
                      _vm._v(_vm._s(_vm.actionItem.projectName)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.actionItem.creditNote &&
                _vm.actionItem.creditNote.invoiceId == null &&
                _vm.actionItem.approvable
                  ? _c("div", { staticClass: "p-4 simple-border" }, [
                      _c("div", { staticClass: "mb-2" }, [
                        _c("h6", { staticClass: "mb-4" }, [
                          _c("strong", [_vm._v("Update Credit Note Amount")]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "mt-2 d-flex justify-content-between",
                          },
                          [
                            _c("CurrencyLookupVue", {
                              staticClass: "mr-2",
                              attrs: {
                                amount: Number(_vm.actionItem.creditNote.total),
                                placeholder: "Update Credit Amount",
                              },
                              on: {
                                valueChanged: function ($event) {
                                  return _vm.onCreditValueChange($event)
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("CustomDxButtonVue", {
                              attrs: {
                                text: "Save",
                                "custom-class": "primary w-25",
                                disabled: _vm.savingCreditNote,
                              },
                              on: { click: _vm.onSaveCreditNote },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-4 mb-4" },
                  [
                    _c(
                      "DxScrollView",
                      {
                        attrs: {
                          height: "22em",
                          "element-attr": { class: "pb-4" },
                        },
                      },
                      [
                        _c("div", { staticClass: "font-italic" }, [
                          _vm._v('"' + _vm._s(_vm.actionItem.content) + '"'),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "fixed",
                      width: "47%",
                      bottom: "5px",
                    },
                  },
                  [
                    _vm.alert === "Opened"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "text-center pointer alert alert-success",
                            on: {
                              click: function ($event) {
                                return _vm.onClickClosed()
                              },
                            },
                          },
                          [_vm._v("Close Issue\n                    ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.alert === "Resolving"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "text-center pointer alert alert-warning",
                            on: {
                              click: function ($event) {
                                return _vm.onClickResolution()
                              },
                            },
                          },
                          [_vm._v("Resolution Pending\n                    ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.alert === "Closed"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "text-center pointer alert alert-secondary",
                            on: {
                              click: function ($event) {
                                return _vm.onClickReopen()
                              },
                            },
                          },
                          [_vm._v("Re-Open\n                    ")]
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "CCol",
                { staticClass: "h-100", attrs: { lg: "6" } },
                [
                  _vm.actionItem.isOpen
                    ? _c(
                        "form",
                        {
                          staticClass: "mb-3",
                          on: {
                            submit: function ($event) {
                              return _vm.submit($event)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("DxHtmlEditor", {
                                key: _vm.editorKey,
                                ref: "htmlEditor",
                                attrs: {
                                  mentions: _vm.mentions,
                                  placeholder: "Type in resolution.",
                                },
                                model: {
                                  value: _vm.actionItemComment.textHtml,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.actionItemComment,
                                      "textHtml",
                                      $$v
                                    )
                                  },
                                  expression: "actionItemComment.textHtml",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                { staticClass: "btn editor-send-btn" },
                                [_c("CIcon", { attrs: { name: "cid-send" } })],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "DxScrollView",
                    {
                      attrs: {
                        height: _vm.actionItem.isOpen
                          ? "calc(100% - 142px)"
                          : "100%",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "action-item-comments" },
                        _vm._l(_vm.actionItem.comments, function (comment) {
                          return _c(
                            "div",
                            { key: comment.id, staticClass: "comment-body" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-content-between mb-2",
                                },
                                [
                                  _c("CommentHeader", {
                                    attrs: {
                                      user: comment.user,
                                      timestamp: comment.timestamp,
                                    },
                                  }),
                                  _vm._v(" "),
                                  comment.commentBadge != "None"
                                    ? _c(
                                        "CBadge",
                                        {
                                          attrs: {
                                            color: _vm.badgeColor(
                                              comment.commentBadge
                                            ),
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(comment.commentBadge) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "comment-body-text" }, [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(comment.textHtml),
                                  },
                                }),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "CCard",
            { staticClass: "h-100" },
            [
              _c("CCardBody", [
                _c("h4", [_vm._v("Access Denied.")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v("You do not have permission to access this section."),
                ]),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }