var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CustomCModal",
    {
      attrs: {
        title: _vm.title,
        color: _vm.color,
        show: _vm.showModal,
        "close-on-backdrop": _vm.closeOnBackdrop,
        size: _vm.modalSize,
        addDialogClasses: _vm.dialogClasses,
        addModalClasses: _vm.addModalClasses,
      },
      on: {
        "update:show": [
          function ($event) {
            _vm.showModal = $event
          },
          _vm.updateShow,
        ],
      },
      scopedSlots: _vm._u(
        [
          {
            key: "title-append",
            fn: function () {
              return [_vm._t("title-append")]
            },
            proxy: true,
          },
          {
            key: "header-float-right",
            fn: function () {
              return [_vm._t("header-float-right")]
            },
            proxy: true,
          },
        ],
        null,
        true
      ),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _vm._t("content"),
      _vm._v(" "),
      _vm._t("default", [_vm._v(_vm._s(_vm.text))]),
      _vm._v(" "),
      _vm._t("footer", null, { slot: "footer" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }