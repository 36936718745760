var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DxButton", {
    attrs: {
      text: _vm.text,
      icon: _vm.icon,
      disabled: _vm.disabled,
      "element-attr": { id: "customDevextremeBtn" },
    },
    on: {
      "content-ready": _vm.onContentReady,
      click: function ($event) {
        return _vm.$emit("click")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }