var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("router-view", { key: _vm.$route.fullPath }),
      _vm._v(" "),
      _c("div", { attrs: { id: "popup-forms" } }),
      _vm._v(" "),
      _c("alert-modal", { ref: "alertModal" }),
      _vm._v(" "),
      _c("confirm-modal", { ref: "confirmModal" }),
      _vm._v(" "),
      _c("DxConfirmPopup", { ref: "dxConfirmPopup" }),
      _vm._v(" "),
      _c("DxAlertPopup", { ref: "dxAlertPopup" }),
      _vm._v(" "),
      _c("Toast", {
        model: {
          value: _vm.managedToasts,
          callback: function ($$v) {
            _vm.managedToasts = $$v
          },
          expression: "managedToasts",
        },
      }),
      _vm._v(" "),
      _vm.isLoggedIn
        ? _c("LeaveRequestModal", { ref: "leaveRequestModal" })
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoggedIn && _vm.showActionItem
        ? _c("ActionItemModal", {
            ref: "actionItemModal",
            on: {
              hidden: function ($event) {
                _vm.showActionItem = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }