var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("lookup", {
    attrs: {
      optionsEndpoint: _vm.optionsEndpoint,
      label: _vm.label !== false ? _vm.label || "Service Type" : false,
      placeholder: _vm.placeholder,
      isValid: _vm.isValid,
      invalidFeedback: _vm.invalidFeedback,
      displayKey: "name",
      containerClass: _vm.containerClass || "",
      fieldClass: _vm.fieldClass,
      labelClass: _vm.labelClass,
      includeProperties: [
        "allowedBillingTypes",
        "isProjectRequired",
        "serviceType",
        "isSLA",
      ],
      sort: false,
    },
    on: {
      change: function ($event) {
        return _vm.$emit("change", $event)
      },
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v
      },
      expression: "selected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }