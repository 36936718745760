var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CFormGroup",
    _vm._b(
      {
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [
                  _vm._t("label", [
                    _vm.label
                      ? _c(
                          "label",
                          {
                            class: _vm.labelClasses,
                            attrs: { for: _vm.safeId },
                          },
                          [
                            _vm._v(
                              "\n        " + _vm._s(_vm.label) + "\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._t("tooltip"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "input",
              fn: function () {
                return [
                  _c(
                    "select",
                    _vm._b(
                      {
                        class: _vm.inputClasses,
                        attrs: { id: _vm.safeId },
                        on: {
                          input: function ($event) {
                            return _vm.onSelect($event)
                          },
                        },
                      },
                      "select",
                      _vm.$attrs,
                      false
                    ),
                    [
                      _vm.placeholder
                        ? _c(
                            "option",
                            {
                              attrs: {
                                value: "",
                                selected: "",
                                disabled: "",
                                hidden: "",
                              },
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.placeholder) +
                                  "\n      "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.options, function (option, key) {
                        return [
                          option !== null && typeof option === "object"
                            ? _c(
                                "option",
                                _vm._b(
                                  {
                                    key: key,
                                    attrs: {
                                      disabled: option.disabled,
                                      "data-key": key,
                                    },
                                    domProps: {
                                      value: option.value,
                                      selected: option.value === _vm.value,
                                    },
                                  },
                                  "option",
                                  option.attrs,
                                  false
                                ),
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(option.label || option.value) +
                                      "\n        "
                                  ),
                                ]
                              )
                            : _c(
                                "option",
                                {
                                  key: key,
                                  attrs: { "data-key": key },
                                  domProps: {
                                    value: option,
                                    selected: option === _vm.value,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(option) +
                                      "\n        "
                                  ),
                                ]
                              ),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              },
              proxy: true,
            },
            _vm._l(_vm.$options.slots, function (slot) {
              return {
                key: slot,
                fn: function () {
                  return [_vm._t(slot)]
                },
                proxy: true,
              }
            }),
          ],
          null,
          true
        ),
      },
      "CFormGroup",
      {
        append: _vm.append,
        prepend: _vm.prepend,
        validFeedback: _vm.validFeedback,
        invalidFeedback: _vm.invalidFeedback,
        tooltipFeedback: _vm.tooltipFeedback,
        description: _vm.description,
        wrapperClasses: _vm.wrapperClasses,
        class: _vm.computedClasses,
      },
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }