import Vue from 'vue'
import CoreuiVue from '@coreui/vue'
// import moment from 'moment'
// import Vuetify from 'vuetify'
import App from './App'
import { router } from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import Spinner from './components/generic/Spinner.vue'
import Modal from './components/generic/Modal.vue'
import Popup from './components/generic/Popup.vue'
import Popover from './components/generic/Popover.vue'
import DxField from './components/generic/DxField.vue'
// import ConfirmModal from './components/ConfirmModal.vue'
import CSelectWrapper from './components/generic/CSelectWrapper.vue'
// CSelect doesn't seem to support objects as values, but vue-select does.
import VSelectWrapper from './components/generic/VSelectWrapper.vue'
import CTextareaWrapper from './components/generic/CTextareaWrapper.vue'
import InputRadioGroup from './components/generic/InputRadioGroup.vue'
import InputCheckboxGroup from './components/generic/InputCheckboxGroup.vue'
import { store } from './_store'
import VTooltip from 'v-tooltip'
import PortalVue from 'portal-vue'
import VueApexCharts from 'vue-apexcharts'
import { GenerateGuid, authMixin, Constants, HelperMethods, Util } from './mixins'
import { IconsPlugin, BPopover, BTooltip, NavPlugin, TabsPlugin, BFormRating } from 'bootstrap-vue'
import CustomCDataTable from './components/generic/CustomCDataTable.vue'
import CustomCSelect from './components/generic/CustomCSelect.vue'
import CustomCTextarea from './components/generic/CustomCTextarea.vue'
import Toggle from './components/generic/Toggle.vue'
import CollapsibleCard from './components/generic/CollapsibleCard.vue'
import CheckPermission from './components/generic/CheckPermission.vue'
import TooltipIcon from './components/generic/TooltipIcon.vue'
import CustomCInput from './components/generic/CustomCInput.vue'
import CAlertWrapper from './components/generic/CAlertWrapper.vue'
import DecimalInput from './components/generic/DecimalInput.vue'
import {NotImplemented} from './directives'
import ConnectionHub from './hubs/ConnectionHub'
import notify from 'devextreme/ui/notify';
import DatePickerField from './components/generic/DatePickerField.vue'
import EmptyRow from './components/EmptyRow.vue'
import Icon from './components/generic/Icon.vue'
import LoadPanel from './components/generic/LoadPanel.vue'
import DispositionBadge from './components/DispositionBadge'
import InFlightStageBadge from './components/InFlightStageBadge'
import UserLeaveBadge from './components/UserLeaveBadge'
import LicenseImportStatus from './components/LicenseImportStatus'
import PartnerCommissionStatus from './components/PartnerCommissionStatus'
import TextButton from './components/generic/TextButton'
import VueSlimScroll from 'vue-slimscroll'
import VuePapaParse from 'vue-papa-parse'
import './_helpers/filters'

Vue.mixin(authMixin)
Vue.mixin(GenerateGuid)
Vue.mixin(Constants)
Vue.mixin(HelperMethods)
Vue.mixin(Util)

Vue.use(VTooltip)
Vue.use(IconsPlugin)
Vue.use(CoreuiVue)
Vue.use(TabsPlugin)
Vue.use(NavPlugin)
Vue.use(VueSlimScroll)
Vue.use(VuePapaParse)

Vue.use(ConnectionHub)
Vue.directive('not-implemented', NotImplemented)

Vue.use(PortalVue, {
	portalName: 'portal',
	portalTargetName: 'portal-target'
})
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.component('b-popover', BPopover)
Vue.component('b-tooltip', BTooltip)
Vue.component('b-rating', BFormRating)
Vue.component('spinner', Spinner)
Vue.component('modal', Modal)
Vue.component('Popup', Popup)
Vue.component('Popover', Popover)
Vue.component('DxField', DxField)
// Vue.component('confirm-modal', ConfirmModal)
Vue.component('CSelectWrapper', CSelectWrapper)
Vue.component('VSelectWrapper', VSelectWrapper)
Vue.component('CTextareaWrapper', CTextareaWrapper)
Vue.component('InputRadioGroup', InputRadioGroup);
Vue.component('InputCheckboxGroup', InputCheckboxGroup);
Vue.component('Toggle', Toggle);
Vue.component('CustomCSelect', CustomCSelect);
Vue.component('CustomCDataTable', CustomCDataTable);
Vue.component('CollapsibleCard', CollapsibleCard);
Vue.component('CheckPermission', CheckPermission);
Vue.component('TooltipIcon', TooltipIcon);
Vue.component('CustomCInput', CustomCInput);
Vue.component('CustomCTextarea', CustomCTextarea);
Vue.component('CAlertWrapper', CAlertWrapper);
Vue.component('DecimalInput', DecimalInput);
Vue.component('DatePickerField', DatePickerField);
Vue.component('LoadPanel', LoadPanel);
Vue.component('EmptyRow', EmptyRow);
Vue.component('Icon', Icon);
Vue.component('InFlightStageBadge', InFlightStageBadge);
Vue.component('DispositionBadge', DispositionBadge);
Vue.component('UserLeaveBadge', UserLeaveBadge);
Vue.component('LicenseImportStatus', LicenseImportStatus);
Vue.component('PartnerCommissionStatus', PartnerCommissionStatus)
Vue.component('TextButton', TextButton);

// Yeah, I know, "shouldn't modify built-in object prototypes, blah blah blah."
Object.defineProperty(Array.prototype, 'sortByFn', {
    value: function(fn) { return [].concat(this).sort((a, b) => fn(a) > fn(b) ? 1 : (fn(a) == fn(b) ? 0 : -1)); }
});
Object.defineProperty(Array.prototype, 'sortDescByFn', {
    value: function(fn) { return [].concat(this).sort((a, b) => fn(a) > fn(b) ? -1 : (fn(a) == fn(b) ? 0 : 1)); }
});

(function(){
	let app = new Vue({
		el: '#app',
		router,
		//CIcon component documentation: https://coreui.io/vue/docs/components/icon
		icons,
		store,
		// vuetify: new Vuetify({}),
		template: '<App ref="app" :toasts="toasts" @update-toasts="updateToasts"/>',
		components: {
			App,
		},
		data: {
			toasts: [],
		},
		methods: {
			updateToasts(toasts){
				this.toasts = toasts
			},
			showAlertModal(message, title){
				this.$refs.app.showAlertModal(message, title);
			},
			showConfirmModal(options){
				this.$refs.app.showConfirmModal(options);
			},
            showConfirmModal2(options){
				this.$refs.app.showConfirmModal2(options);
			},
            showAlertModal2(options){
				this.$refs.app.showAlertModal2(options);
			},
            showActionItemModal(options){
                this.$refs.app.showActionItemModal(options);
            },
            showLeaveRequestModal(){
                this.$refs.app.showLeaveRequestModal();
            },
			showFilePreview(options){
				this.$refs.app.showFilePreview(options);
			},
			showAlertMessage(message, color){
				let self = this;
				this.$store.state.alert.type = color || 'danger'
				this.$store.state.alert.message = message
				setTimeout(function(){self.$store.state.alert.message = null},7000);
			},
			showToast(options){
				if (typeof(options) === 'string'){
					options = {
						text: options,
						title: 'Alert',
						color: 'danger',
					}
				}
                if(!Object.prototype.hasOwnProperty.call(options, 'autohide')){
                    options.autohide = options.title ? 10000 : 3000
                }
                if(options.color == 'danger'){
                    options.color = 'error'
                }
				// this.toasts.push({
				// 	title: options.title,
				// 	color: options.color || 'danger',
				// 	text: options.text,
				// 	autohide: options.autohide,
				// 	key: this.generateGuid(),
                //     onClick: options.onClick,
                //     timestamp: options.timestamp,
				// 	btnText: options.btnText || 'danger',
				// })
                notify(
                    {
                        // height: 45,
                        width: 320,
                        type: options.color,
                        minHeight: 50,
                        displayTime: options.autohide,
                        animation: {
                            show: { type: 'fade', duration: 400, from: 0, to: 1 },
                            hide: { type: 'fade', duration: 40, to: 0 },
                        },
                        contentTemplate: function () {
                            const placeholder = document.createElement("div");
                            placeholder.style.width = '100%'
                            placeholder.insertAdjacentHTML("afterbegin", `
                                <div class="dx-toast-custom-title">${options.title}</div>
                                <div class="dx-toast-custom-message">${options.text}</div>
                            `);
                            return placeholder;
                        }
                    },
                    {
                        position: 'bottom right',
                        direction: 'up-push'
                    }
                );
			},
			doLoginStatusCheck(){
				if (this.$store.state.authentication.details.Token){
					const parts = this.$store.state.authentication.details.Token.split('.')
					if (parts.length){
						const tokeninfo = JSON.parse(atob(parts[1]))
						const expTime = new Date(tokeninfo.exp * 1000)
						const now = new Date()
						const secondsLeft = (expTime.getTime() - now.getTime()) / 1000
						return secondsLeft > 0
					}
				}
				return false
			},
		}
	})

	window.showAlertModal = function(message, title){
		app.showAlertModal(message, title)
	}
	window.showConfirmModal = function(options){
		app.showConfirmModal(options)
	}
    window.showConfirmModal2 = function(options){
		app.showConfirmModal2(options)
	}
    window.showAlertModal2 = function(options, text){
        app.showAlertModal2(typeof options == 'object' ? options : {title: options, text: text})
	}
	window.showAlertMessage = function(message, color){
		app.showAlertMessage(message, color)
	}
	window.showToast = function(options){
		app.showToast(options)
	}
	window.showSuccessToast = function(message, title){
		app.showToast({
			text: message,
			title: title || 'Alert',
			color: 'success',
		})
	}
	window.showErrorToast = function(message, title){
		app.showToast({
			text: message,
			title: title || 'Alert',
			color: 'danger',
		})
	}
    window.showActionItemModal = function(options){
		app.showActionItemModal(options)
	},
    window.showLeaveRequestModal = function(){
		app.showLeaveRequestModal()
	}
	window.showFilePreview = function(options){
		app.showFilePreview(options)
	}

	window.setInterval(()=>{
		if (!window.location.href.endsWith('#/login')){
			if (!app.doLoginStatusCheck()){
				app.$store.dispatch('authentication/recordLogout', 'doLoginStatusCheck() returned false')
				window.location.href = "#/login"
			}
		}
	}, 10000)
})()
