var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CTextarea", {
    attrs: {
      label: _vm.label,
      horizontal: _vm.horizontal,
      placeholder: _vm.placeholder,
      description: _vm.description,
      isValid: _vm.isValid,
      invalidFeedback: _vm.invalidFeedback,
      addLabelClasses: _vm.addLabelClasses,
    },
    model: {
      value: _vm.text,
      callback: function ($$v) {
        _vm.text = $$v
      },
      expression: "text",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }