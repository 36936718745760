var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.iconPack == "coreui"
        ? _c("CIcon", {
            directives: [
              {
                name: "c-tooltip",
                rawName: "v-c-tooltip",
                value: _vm.tooltip,
                expression: "tooltip",
              },
              {
                name: "top",
                rawName: "v-top",
                value: _vm.top,
                expression: "top",
              },
              {
                name: "right",
                rawName: "v-right",
                value: _vm.right,
                expression: "right",
              },
              {
                name: "left",
                rawName: "v-left",
                value: _vm.left,
                expression: "left",
              },
              {
                name: "bottom",
                rawName: "v-bottom",
                value: _vm.bottom,
                expression: "bottom",
              },
            ],
            class: _vm.cssClass,
            attrs: {
              name: _vm.name,
              size: _vm.iconSize,
              id: _vm.elId,
              title: _vm.title,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.iconPack == "dx"
        ? _c("i", {
            directives: [
              {
                name: "c-tooltip",
                rawName: "v-c-tooltip",
                value: _vm.tooltip,
                expression: "tooltip",
              },
              {
                name: "top",
                rawName: "v-top",
                value: _vm.top,
                expression: "top",
              },
              {
                name: "right",
                rawName: "v-right",
                value: _vm.right,
                expression: "right",
              },
              {
                name: "left",
                rawName: "v-left",
                value: _vm.left,
                expression: "left",
              },
              {
                name: "bottom",
                rawName: "v-bottom",
                value: _vm.bottom,
                expression: "bottom",
              },
            ],
            class: _vm.name + " " + _vm.cssClass,
            attrs: { size: _vm.iconSize, id: _vm.elId, title: _vm.title },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.iconPack == "bootstrap"
        ? _c("b-icon", {
            directives: [
              {
                name: "c-tooltip",
                rawName: "v-c-tooltip",
                value: _vm.tooltip,
                expression: "tooltip",
              },
              {
                name: "top",
                rawName: "v-top",
                value: _vm.top,
                expression: "top",
              },
              {
                name: "right",
                rawName: "v-right",
                value: _vm.right,
                expression: "right",
              },
              {
                name: "left",
                rawName: "v-left",
                value: _vm.left,
                expression: "left",
              },
              {
                name: "bottom",
                rawName: "v-bottom",
                value: _vm.bottom,
                expression: "bottom",
              },
            ],
            class: _vm.cssClass,
            attrs: {
              icon: _vm.name,
              "font-scale": _vm.iconSize,
              id: _vm.elId,
              title: _vm.title,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.iconPack == "atc"
        ? _c("img", {
            directives: [
              {
                name: "c-tooltip",
                rawName: "v-c-tooltip",
                value: _vm.tooltip,
                expression: "tooltip",
              },
              {
                name: "top",
                rawName: "v-top",
                value: _vm.top,
                expression: "top",
              },
              {
                name: "right",
                rawName: "v-right",
                value: _vm.right,
                expression: "right",
              },
              {
                name: "left",
                rawName: "v-left",
                value: _vm.left,
                expression: "left",
              },
              {
                name: "bottom",
                rawName: "v-bottom",
                value: _vm.bottom,
                expression: "bottom",
              },
            ],
            class: _vm.cssClass,
            attrs: {
              src: _vm.name,
              alt: _vm.name,
              title: _vm.title,
              id: _vm.elId,
              width: _vm.iconSize || "22px",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }