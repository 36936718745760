import {
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowRight,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilCheckAlt,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMoon,
  cilNotes,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilTags,
  cilTask,
  cilThumbDown,
  cilThumbUp,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilXCircle,
  cilX,
  cilWarning,
  cilPlus,
  cilMinus,
  cilSync,
  cilReload,
  cilArrowLeft,
  cilAt,
  cilInfo,
  cilListFilter,
  cilOptions,
  cilActionUndo,
  cilFindInPage,
  cilArrowCircleLeft,
  cilArrowCircleRight,
  cilHistory,
  cilSwapHorizontal,
  cilLevelUp,
  cilLineSpacing,
  cilBadge,
  cilCopy,
  cilFolder,
  cilExcerpt,
  cilExternalLink
} from '@coreui/icons'

import {
  //start linear icons
  cilClock,
  cilCommentBubbleExclamation,
  cilDevices,
  cilFolderShared,
  cilGripDotsHorizontal,
  cilIdBadge,
  cilInfoCircle,
  cilAddressCard,
  cilDeveloperBoard,
  cilPlusCircle,
  cilChevronLeftAlt,
  //start duotone icons
  cidCheckAlt,
  cidCheckCircle,
  cisXCircle,
  cidChevronCircleUp,
  cidClock,
  cidCommentBubbleExclamation,
  cidDataTransferDown,
  cidDevices,
  cidDollarCircle,
  cidExclamationCircle,
  cidFolderShared,
  cidGripDotsHorizontal,
  cidHistory,
  cidIdBadge,
  cidInfoCircle,
  cidLibraryBooks,
  cidArchive,
  cidNotes,
  cidSend,
  cidFlightTakeoff,
  cidBuildingBusiness,
  //start solid icons
  cisCheckAlt,
  cisCheckCircle,
  cisChevronRightAlt,
  cisChevronTop,
  cisChevronLeft,
  cisClock,
  cisCog,
  cisCommentBubbleExclamation,
  cisCommentBubbleLines,
  cisDevices,
  cisDollarCircle,
  cisFilter,
  cisMoodVeryGood,
  cisFolderShared,
  cisGripDotsHorizontal,
  cisHistory,
  cisIdBadge,
  cisInfoCircle,
  cisLinkIntact,
  cisPaperclip,
  cisPlusCircle,
  cidThumbDown,
  cidThumbUp,
  cisX,
  cisLineSpacing,
  cisPeople,
  cisBarChart,
  cisCalendar,
  cisThumbUp,
  cisBan,
  cidCopy,
  cisDelete,
  cisCaretBottom,
  cisFileDoc,
  cilArrowThickCircleUp,
  cilArrowThickCircleDown,
  cilContactMail,
  cisEllipsis,
  cisLan,
  cisTarget,
  cisPeoplePlus,
  cisFlightTakeoff
} from '@coreui/icons-pro'

export const iconsSet = Object.assign({}, {
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowRight,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilCheckAlt,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cisCommentBubbleLines,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMoon,
  cilNotes,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilTags,
  cilTask,
  cilThumbDown,
  cilThumbUp,
  cidThumbDown,
  cidThumbUp,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilXCircle,
  cilX,
  cilWarning,
  cilPlus,
  cilMinus,
  cilSync,
  cilReload,
  cilArrowLeft,
  cilAt,
  cilInfo,
  cilListFilter,
  cilOptions,
  cilActionUndo,
  cilFindInPage,
  cilArrowCircleLeft,
  cilArrowCircleRight,
  cilHistory,
  cilSwapHorizontal,
  cilLevelUp,
  cilLineSpacing,
  cilBadge,
  cilDeveloperBoard,
  cilPlusCircle,
  cilChevronLeftAlt,
  cilExternalLink,
  //start linear icons
  cilClock,
  cilCommentBubbleExclamation,
  cilDevices,
  cilFolderShared,
  cilGripDotsHorizontal,
  cilIdBadge,
  cilInfoCircle,
  cilContactMail,
  cilAddressCard,
  //start duotone icons
  cidCheckAlt,
  cidCheckCircle,
  cisXCircle,
  cidChevronCircleUp,
  cidClock,
  cidCommentBubbleExclamation,
  cidDataTransferDown,
  cidDevices,
  cidDollarCircle,
  cidExclamationCircle,
  cidFolderShared,
  cidGripDotsHorizontal,
  cidHistory,
  cidIdBadge,
  cidInfoCircle,
  cidLibraryBooks,
  cidArchive,
  cidNotes,
  cidSend,
  cidFlightTakeoff,
  cidBuildingBusiness,
  //start solid icons
  cisCheckAlt,
  cisCheckCircle,
  cisChevronRightAlt,
  cisChevronTop,
  cisChevronLeft,
  cisClock,
  cisCog,
  cisCommentBubbleExclamation,
  cisDevices,
  cisDollarCircle,
  cisMoodVeryGood,
  cisFilter,
  cisFolderShared,
  cisGripDotsHorizontal,
  cisHistory,
  cisIdBadge,
  cisInfoCircle,
  cisLinkIntact,
  cisPaperclip,
  cisPlusCircle,
  cisX,
  cisLineSpacing,
  cisPeople,
  cisBarChart,
  cisCalendar,
  cisThumbUp,
  cisBan,
  cisDelete,
  cisCaretBottom,
  cisEllipsis,
  cisLan,
  cisTarget,
  cisPeoplePlus,
  cisFlightTakeoff,
  cilCopy,
  cidCopy,
  cilFolder,
  cilArrowThickCircleUp,
  cilArrowThickCircleDown,
  cisFileDoc,
  cilExcerpt
  }
)
