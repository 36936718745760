import { render, staticRenderFns } from "./CollapsibleCard.vue?vue&type=template&id=1d9644a4&scoped=true&"
import script from "./CollapsibleCard.vue?vue&type=script&lang=js&"
export * from "./CollapsibleCard.vue?vue&type=script&lang=js&"
import style0 from "./CollapsibleCard.vue?vue&type=style&index=0&id=1d9644a4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d9644a4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1d9644a4')) {
      api.createRecord('1d9644a4', component.options)
    } else {
      api.reload('1d9644a4', component.options)
    }
    module.hot.accept("./CollapsibleCard.vue?vue&type=template&id=1d9644a4&scoped=true&", function () {
      api.rerender('1d9644a4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/client/components/generic/CollapsibleCard.vue"
export default component.exports