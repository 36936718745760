import {AxiosWrapper, HelperMethods} from '../'

export default {
	mixins: [AxiosWrapper, HelperMethods],
	data(){
		return {
            isHolidaysFetched: false,
            _kdgHolidays: [],
		}
	},
	methods: {
		async loadKDGHolidays(){
            const response = await this.get('api/settings/kdg-holidays')
            this._kdgHolidays = response.data
            this.isHolidaysFetched = true
		},
		async isKDGHoliday(date = new Date(), loadOnce = false){
            if(loadOnce){
                if(!this.isHolidaysFetched) await this.loadKDGHolidays()
                let holiday = this._kdgHolidays.find(obj => {
                    return obj.multipleDates ? 
                    new Date(date) >= new Date(obj.date) && new Date(date) <= new Date(obj.endDate) : 
                    this.compareDate(date, '===', obj.date)
                })
                return { isHoliday: Boolean(holiday), holiday: holiday }
            }
            else{
                const response = await this.get(`api/settings/is-holiday?date=${date.toLocaleDateString('en-US')}`)
                return response.data
            }
		}
	},
}
