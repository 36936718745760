<template>
    <div class="d-flex justify-content-start">
        <div style="margin-top:3px">
            <UserPhoto :src="imgSource" />
        </div>
        <div class="mb-1">
            <div><strong>{{user.fullName || user.userName}}</strong></div>
            <div style="font-size:12px">{{timestamp | formatUtcToLocalDateTime}}</div>
        </div>
    </div>
</template>


<script>
import UserPhoto from './UserPhoto.vue';

export default {
    props:['user','timestamp'],
	components: {UserPhoto},
    computed:{
        imgSource(){
            if(this.user){
                return Boolean(this.user.picture) ? this.user.picture : '/img/avatars/user-thumbnail.png'
            }
            return '/img/avatars/user-thumbnail.png'
        }
    }
}
</script>

<style scoped>
.user-profile-picture{
	height: 35px;
	width: 35px;
    border-radius: 30px;
}
.avatar {
	/* Center the content */
	display: inline-block;
	vertical-align: middle;

	/* Used to position the content */
	position: relative;

	/* Colors */
    border: 1px solid #a9a9a9;
	background-color: #f5f5f5;
	color: #000;

	/* Rounded border */
	border-radius: 50%;
	height: 35px;
	width: 35px;
}
.avatar_letters {
	/* Center the content */
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
}
</style>
