<template>
	<CustomCInput v-bind="$attrs" v-on="$listeners" v-model="model" @change="format" @blur="format" :disabled="disabled">
		<template #label>
			<label v-if="label" :class="addLabelClasses">
			{{label}} <slot name="tooltip"></slot>
			</label>
		</template>
	</CustomCInput>
</template>

<script>
export default {
	props: ['value', 'disabled', 'label', 'addLabelClasses'],
	computed: {
		model: {
			get() { return this.value },
			set(v) { this.$emit('input', v) }
		}
	},
	methods: {
		format(v){
			this.model = ((v || null) !== null && v.toString().trim() != '') ? Number(v).toFixed(2) : ''
		}
	},
	mounted(){
		this.format(this.model)
	}
}
</script>
