var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.service.id || !_vm.service
    ? _c("lookup", {
        attrs: {
          optionsEndpoint: _vm.optionsEndpoint,
          label: _vm.label || "Zoho Subscription",
          isValid: _vm.isValid,
          invalidFeedback: _vm.invalidFeedback,
          includeProperties: "*",
          valueKey: "zoho_subscription_id",
          displayKey: "displayValue",
          externalFilter: _vm.clientFilter,
          containerClass: _vm.containerClass || "",
          fieldClass: _vm.fieldClass,
          labelClass: _vm.labelClass,
          disabled: _vm.disabled,
        },
        on: {
          change: function ($event) {
            return _vm.$emit("change", $event)
          },
          "loaded-options": function ($event) {
            return _vm.$emit("loaded-options", $event)
          },
        },
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }