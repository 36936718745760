<template>
	<CToaster position="bottom-right">
		<template v-for="toast in toasts">
            <CToast
                :key="'toast' + toast.key"
                :show="true"
                :header="toast.title"
                :class="toast.color"
                @update:show="updateShow($event, toast.key)"
                :autohide="toast.autohide">
                {{toast.text}}
                <div class="d-flex align-items-center justify-content-between mt-2">
                    <div>
                        <span v-if="showProp(toast, 'timestamp')" class="text-muted">{{toast.timestamp | formatDateTime}}</span>
                    </div>
                    <div v-if="showProp(toast, 'onClick')">
                        <button class="btn btn-primary" @click="toastClick(toast)">{{toast.btnText || 'Details'}}</button>
                    </div>
                </div>
            </CToast>
		</template>
	</CToaster>
</template>


<style>
.toast.danger .toast-header{
	color: #ffffff;
	background-color: #e55353;
}
.toast.danger .toast-body{
	color: #772b35;
	background-color: #fadddd;
	/* border-color: #f8cfcf; */
}
.toast.warning .toast-header{
	color: #ffffff;
	background-color: rgb(255, 140, 0);
}
.toast.warning .toast-body{
	background-color: #d1ba7b;
	color: rgb(122, 67, 0);
}
.toast.success .toast-header{
	color: #ffffff;
	background-color: rgb(18, 185, 40);
}
.toast.success .toast-body{
	color: rgb(22, 83, 6);
	background-color: rgb(131, 233, 145);
}
.toast.info .toast-header{
	color: #ffffff;
	background-color: #57abff;
}
.toast.info .toast-body{
	color: rgb(0,64,133);
	background-color: #57abff;
}
</style>

<script>
export default {
	props: ['value'],
	data () {
		return {

		}
	},
	methods: {
		updateShow(show, key){
			if (!show){
				let self = this
				window.setTimeout(function(){
					const toasts = self.toasts
					self.toasts = toasts.filter(t => t.key != key)
				}, 2000)
			}
		},
        toastClick(toast){
            if(toast.hasOwnProperty('onClick')){
                toast.onClick()
                this.toasts = this.toasts.filter(s=> s.key != toast.key)
            }
        },
        showProp(toast, prop){
            return toast.hasOwnProperty(prop) && toast[prop]
        }
	},
	computed: {
		toasts: {
			get(){ return this.value },
			set(v) { this.$emit('input', v) }
		},
	}
}
</script>