<template>
	<CCard>
		<CCardHeader :color="color" :class="headerClass || 'pointer d-flex justify-content-between'" @click="collapse = !collapse">
			<slot name="header-content">
				<strong>{{title}}</strong>
			</slot>
			<div class="d-md-flex align-items-center flex-wrap mt-2 mt-md-0">
				<slot name="headerButton" />
				<CIcon :name="icon" />
			</div>
		</CCardHeader>
		<CCollapse :show="!collapse">
			<CCardBody>
				<slot></slot>
			</CCardBody>
		</CCollapse>
	</CCard>
</template>

<style scoped>
.pointer {
	cursor: pointer;
}
</style>


<script>
export default {
	props: ['title', 'closedByDefault', 'color', 'headerClass', 'isOpen'],
	data(){
		return {
			collapse: (this.closedByDefault !== undefined) ? this.closedByDefault : (!this.isOpen),
		}
	},
	watch: {
		collapse(v) {
			this.$emit('update:isOpen', !v)
		},
		// This is a bit confusing. I want to be able to open this from outside, but not break other things by requiring it to be controlled from outside.
		isOpen(v) {
			if (v && this.collapse){
				this.collapse = false
			}
			if (!v && !this.collapse){
				this.collapse = true
			}
		}
	},
	computed: {
		icon(){
			return this.collapse ? 'cil-chevron-bottom' : 'cil-chevron-top'
		}
	}
}
</script>
