var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CIcon", {
    directives: [
      {
        name: "tooltip",
        rawName: "v-tooltip",
        value: { content: _vm.text },
        expression: "{content: text}",
      },
    ],
    staticClass: "icon-tooltip",
    class: _vm.classes,
    attrs: { name: _vm.name || "cid-info-circle" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }