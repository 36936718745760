export default {
	computed:{
		Google_Client_ID(){ return document.head.querySelector("[name~=google-client-id][content]").content },
		Current_User_Info() { return this.$store.state.authentication.details },
		Current_User_ID() { return this.$store.state.authentication.details.Id },
		Current_User_Permissions() { return this.$store.state.authentication.details.Permissions || [] },
	},
	methods: {
		hasPermission(permission){
			return this.Current_User_Permissions.includes(permission)
		},
        hasAnyPermission(permissions){
            return this.Current_User_Permissions.some(item => permissions.includes(item))
        },
        hasRole(role){
            if(typeof role == 'object')
                return role.includes(this.Current_User_Info.Group)
            else if(typeof role == 'string')
                return this.Current_User_Info.Group == role
        },
		changedUserPhoto(url){
			try {
				const user = JSON.parse(atob(localStorage.getItem('user').replaceAll('"','')))
				user.Picture = url;
				localStorage.setItem('user', '"'+btoa(JSON.stringify(user))+'"')
				this.$store.state.authentication.details.Picture = url
			} catch (e) {
				console.error(e)
			}
		}
	}
};
