<template>
	<lookup
		v-if="service.id || !service"
		:optionsEndpoint="optionsEndpoint"
		:label="label || 'Zoho Subscription'"
		v-model="selected"
		:isValid="isValid"
		:invalidFeedback="invalidFeedback"
		includeProperties="*"
		valueKey="zoho_subscription_id"
		displayKey="displayValue"
		:externalFilter="clientFilter"
		:containerClass="containerClass || ''"
		:fieldClass="fieldClass"
		:labelClass="labelClass"
		@change="$emit('change', $event)"
		@loaded-options="$emit('loaded-options', $event)"
		:disabled="disabled"
	></lookup>
</template>

<script>

import Lookup from '../generic/Lookup'

export default {
	name: 'ZohoSubscriptionLookup',
	props: ['value', 'isValid', 'invalidFeedback', 'label', 'clientFilter', 'disabled', 'containerClass', 'fieldClass', 'labelClass', 'service'],
	components: {Lookup},
	// data(){
	// 	return {
	// 		value: this.initValue,
	// 	}
	// },
	computed: {
		optionsEndpoint(){
			return '/api/services/zohoSubscriptions' + (this.service ? ('?serviceId=' + this.service.id) : '')
		},
		selected: {
			get(){
				return { zoho_subscription_id: this.value };
			},
			set(v){
				this.$emit('input', v ? v.zoho_subscription_id : null);
			}
		}
	},
	methods: {
		onChanged(selected, emitUp){
			this.contactLink = selected ? selected.contactUrl : ''
			if (emitUp){
				// Only emit when the selection is changed by the user.
				this.$emit('change', selected)
			}
		},
		loadedOptions(options){
			if (this.selected){
				this.onChanged(options.find(o => o.zoho_subscription_id == this.selected.zoho_subscription_id), false)
			}
		}
	}
}
</script>
