import moment from 'moment'

export const InvoiceStatuses = {
	methods: {
		isOpen(invoice){
			return !['paid', 'void', 'draft'].includes(invoice.status)
		},
		statusText(invoice, fullText){
			fullText = fullText || false
			const dueDate = moment(invoice.dueDate)
			const diff = moment().startOf('day').diff(dueDate, 'days')
			if ((invoice.isEmailed && this.isOpen(invoice)) || invoice.status == 'overdue'){
				if (diff > 0){
					return fullText
						? 'Overdue by ' + diff + (diff > 1 ? ' days' : ' day')
						: 'Overdue ' + diff + 'd'
				} else if (diff < 0) {
					return 'Due ' + Math.abs(diff) + 'd'
				}
			}
			return this.formatLabel(invoice.status)
		},
		statusColor(status){
			switch(status){
				case 'paid':
					return 'success'
				case 'partially_paid':
					return 'orangewarning'
				case 'overdue':
					return 'danger'
				case 'void':
				case 'draft':
					return 'light'
				default:
					return 'info'
			}
		},
		paymentTermsAbbreviation(terms){
			switch(terms){
				case window.constants.PAYMENT_TERM_END_OF_MONTH_DAYS:
					return 'EOM'
				case window.constants.PAYMENT_TERM_END_OF_NEXT_MONTH_DAYS:
					return 'EONM'
				case window.constants.PAYMENT_TERM_ON_RECEIPT_DAYS:
					return 'DOR'
			}
			return terms
		},
	}
}
