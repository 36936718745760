var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.show ? _vm._t("default") : _vm._t("alternate-content"),
      _vm._v(" "),
      _vm.accessDeniedMsg && !_vm.show
        ? _c(
            "CCard",
            { staticClass: "h-100" },
            [
              _c("CCardBody", [
                _c("h4", [_vm._v("Access Denied.")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v("You do not have permission to access this section."),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }