<template>
	<div class="c-avatar-wrapper mr-2">
		<div class="c-avatar">
			<img :src="src || '/KDGfavicon.png'" class="c-avatar-img fit" />
		</div>
	</div>
</template>

<script>

export default {
	props: {
		src: String
	},
}
</script>

<style>
.fit {
	object-fit: cover;
	max-width: 36px;
	max-height: 36px;
}
</style>
