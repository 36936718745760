var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { name: "authUsersPopupForm" } },
    [
      _c(
        "CheckPermission",
        { attrs: { value: _vm.constants.PERMISSION_UPDATE_SERVICES } },
        [
          _c(
            "PopupForm",
            {
              staticClass: "short-modal authorized-user-popup-form",
              attrs: { size: "lg", title: _vm.title, show: _vm.showEditForm },
              on: { "click-submit": _vm.submit, "click-cancel": _vm.cancel },
            },
            [
              _c("spinner", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.saveRequest,
                    expression: "saveRequest",
                  },
                ],
              }),
              _vm._v(" "),
              _vm.authorizedUser && !_vm.saveRequest
                ? _c(
                    "CForm",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submit($event)
                        },
                      },
                    },
                    [
                      _vm.responseError
                        ? _c("CAlert", { attrs: { color: "danger" } }, [
                            _vm._v(_vm._s(_vm.responseError)),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.authorizedUser.id &&
                      (!_vm.service ||
                        _vm.service.id != _vm.constants.EMPTY_GUID) &&
                      _vm.existingUserCount
                        ? _c("InputRadioGroup", {
                            staticClass: "custom-form-radios",
                            attrs: {
                              label: "New or Existing User?",
                              options: [
                                { label: "Add New", value: "new" },
                                { label: "Select Existing", value: "existing" },
                              ],
                            },
                            on: { change: _vm.changeNewUserMode },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "tooltip",
                                  fn: function () {
                                    return [
                                      _c("CIcon", {
                                        directives: [
                                          {
                                            name: "c-tooltip",
                                            rawName: "v-c-tooltip",
                                            value: {
                                              placement: "bottom",
                                              boundaries: "window",
                                              content:
                                                "Do you want to create a new authorized user, or add an existing user to the selected service?",
                                            },
                                            expression:
                                              "{placement: 'bottom', boundaries: 'window', content:'Do you want to create a new authorized user, or add an existing user to the selected service?'}",
                                          },
                                        ],
                                        staticClass: "icon-tooltip",
                                        attrs: { name: "cid-info-circle" },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              245512518
                            ),
                            model: {
                              value: _vm.newUserMode,
                              callback: function ($$v) {
                                _vm.newUserMode = $$v
                              },
                              expression: "newUserMode",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.newUserMode == "existing",
                              expression: "newUserMode == 'existing'",
                            },
                          ],
                        },
                        [
                          _c("AuthorizedUserLookupSimplified", {
                            attrs: {
                              otherServicesOnly: true,
                              label: "Select User",
                              service: _vm.authorizedUser.selectedService,
                              clientId: _vm.client.id,
                              isValid: _vm.isValid("linkExistingUser"),
                              invalidFeedback:
                                _vm.validationErrors.linkExistingUser,
                              labelClass: {
                                required:
                                  _vm.isFieldRequired("linkExistingUser"),
                                "col-form-label col-sm-3": true,
                              },
                              level: _vm.level,
                            },
                            on: {
                              "loaded-options": _vm.updateExistingUsers,
                              change: function ($event) {
                                _vm.authorizedUser.selectedServices = []
                              },
                            },
                            model: {
                              value: _vm.authorizedUser.linkExistingUser,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.authorizedUser,
                                  "linkExistingUser",
                                  $$v
                                )
                              },
                              expression: "authorizedUser.linkExistingUser",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.service && !_vm.authorizedUser.id
                        ? _c("SubscriptionLookup", {
                            attrs: {
                              clientId: _vm.client.id,
                              isValid: _vm.isValid("selectedServices"),
                              invalidFeedback:
                                _vm.validationErrors.selectedServices,
                              queryParams: _vm.serviceLookupQueryParams,
                              labelClass: {
                                required:
                                  _vm.isFieldRequired("selectedServices"),
                                "col-form-label col-sm-3": true,
                              },
                              fieldClass: "col-sm-9",
                              multiple: true,
                            },
                            on: { "loaded-options": _vm.serviceOptionsLoaded },
                            model: {
                              value: _vm.authorizedUser.selectedServices,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.authorizedUser,
                                  "selectedServices",
                                  $$v
                                )
                              },
                              expression: "authorizedUser.selectedServices",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.newUserMode == "new"
                        ? _c(
                            "span",
                            [
                              _vm.authorizedUser.contactPerson
                                ? _c("CAlert", { attrs: { color: "info" } }, [
                                    _vm._v(
                                      "NOTE: Changes to this user's name and email address will be saved for the contact person in Zoho Books."
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("CInput", {
                                attrs: {
                                  label: "First Name",
                                  horizontal: "",
                                  isValid: _vm.isValid("firstName"),
                                  invalidFeedback:
                                    _vm.validationErrors.firstName,
                                  addLabelClasses: {
                                    required: _vm.isFieldRequired("firstName"),
                                  },
                                },
                                model: {
                                  value: _vm.authorizedUser.firstName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.authorizedUser,
                                      "firstName",
                                      $$v
                                    )
                                  },
                                  expression: "authorizedUser.firstName",
                                },
                              }),
                              _vm._v(" "),
                              _c("CInput", {
                                attrs: {
                                  label: "Last Name",
                                  horizontal: "",
                                  isValid: _vm.isValid("lastName"),
                                  invalidFeedback:
                                    _vm.validationErrors.lastName,
                                  addLabelClasses: {
                                    required: _vm.isFieldRequired("lastName"),
                                  },
                                },
                                model: {
                                  value: _vm.authorizedUser.lastName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.authorizedUser,
                                      "lastName",
                                      $$v
                                    )
                                  },
                                  expression: "authorizedUser.lastName",
                                },
                              }),
                              _vm._v(" "),
                              _c("CInput", {
                                attrs: {
                                  label: "Title",
                                  horizontal: "",
                                  isValid: _vm.isValid("title"),
                                  invalidFeedback: _vm.validationErrors.title,
                                  addLabelClasses: {
                                    required: _vm.isFieldRequired("title"),
                                  },
                                },
                                model: {
                                  value: _vm.authorizedUser.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.authorizedUser, "title", $$v)
                                  },
                                  expression: "authorizedUser.title",
                                },
                              }),
                              _vm._v(" "),
                              _c("CInput", {
                                attrs: {
                                  label: "Email",
                                  horizontal: "",
                                  isValid: _vm.isValid("email"),
                                  invalidFeedback: _vm.validationErrors.email,
                                  addLabelClasses: {
                                    required: _vm.isFieldRequired("email"),
                                  },
                                },
                                model: {
                                  value: _vm.authorizedUser.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.authorizedUser, "email", $$v)
                                  },
                                  expression: "authorizedUser.email",
                                },
                              }),
                              _vm._v(" "),
                              _c("CInput", {
                                attrs: { label: "Phone", horizontal: "" },
                                model: {
                                  value: _vm.authorizedUser.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.authorizedUser, "phone", $$v)
                                  },
                                  expression: "authorizedUser.phone",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.service ||
                      _vm.authorizedUser.selectedService ||
                      _vm.authorizedUser.selectedServices.length
                        ? _c(
                            "div",
                            [
                              _c("AuthUserLevelSelect", {
                                attrs: {
                                  isValid: _vm.isValid("level"),
                                  invalidFeedback: _vm.validationErrors.level,
                                  addLabelClasses: {
                                    required: _vm.isFieldRequired("level"),
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "tooltip",
                                      fn: function () {
                                        return [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-form-label col-sm-3",
                                            },
                                            [
                                              _vm._v(
                                                " Level\n\t\t\t\t\t\t\t\t"
                                              ),
                                              _c("CIcon", {
                                                directives: [
                                                  {
                                                    name: "c-tooltip",
                                                    rawName: "v-c-tooltip",
                                                    value: {
                                                      placement: "right",
                                                      boundaries: "window",
                                                      content:
                                                        "If unsure of which level to choose, select Unauthorized and add a note below.",
                                                    },
                                                    expression:
                                                      "{placement: 'right', boundaries: 'window', content:'If unsure of which level to choose, select Unauthorized and add a note below.'}",
                                                  },
                                                ],
                                                staticClass: "icon-tooltip",
                                                attrs: {
                                                  name: "cid-info-circle",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1227827857
                                ),
                                model: {
                                  value: _vm.authorizedUser.level,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.authorizedUser, "level", $$v)
                                  },
                                  expression: "authorizedUser.level",
                                },
                              }),
                              _vm._v(" "),
                              _c("CTextarea", {
                                attrs: {
                                  label: "Notes",
                                  horizontal: "",
                                  isValid: _vm.isValid("notes"),
                                  invalidFeedback: _vm.validationErrors.notes,
                                  addLabelClasses: {
                                    required: _vm.isFieldRequired("notes"),
                                  },
                                },
                                model: {
                                  value: _vm.authorizedUser.notes,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.authorizedUser, "notes", $$v)
                                  },
                                  expression: "authorizedUser.notes",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.authorizedUser.serviceLevelSettings &&
                          _vm.authorizedUser.id
                        ? _c(
                            "div",
                            [
                              _vm.client
                                ? _c("ServiceLevelOptions", {
                                    attrs: {
                                      authorizedUser: _vm.authorizedUser,
                                      client: _vm.client,
                                      removeServices: _vm.servicesRemoved,
                                      addServices: _vm.servicesAdded,
                                    },
                                    on: {
                                      "update:removeServices": function (
                                        $event
                                      ) {
                                        _vm.servicesRemoved = $event
                                      },
                                      "update:remove-services": function (
                                        $event
                                      ) {
                                        _vm.servicesRemoved = $event
                                      },
                                      "update:addServices": function ($event) {
                                        _vm.servicesAdded = $event
                                      },
                                      "update:add-services": function ($event) {
                                        _vm.servicesAdded = $event
                                      },
                                      "changed-services": function ($event) {
                                        return _vm.$emit(
                                          "changed-services",
                                          $event
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.serviceLevelSettings,
                                      callback: function ($$v) {
                                        _vm.serviceLevelSettings = $$v
                                      },
                                      expression: "serviceLevelSettings",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.service ||
                      _vm.authorizedUser.selectedService ||
                      _vm.authorizedUser.selectedServices.length
                        ? _c("CInputCheckbox", {
                            staticClass: "custom-checkbox",
                            attrs: {
                              checked: _vm.authorizedUser.receiveFeedbackEmails,
                              label:
                                "Allow this person to receive feedback emails?",
                            },
                            on: {
                              "update:checked": function ($event) {
                                return _vm.$set(
                                  _vm.authorizedUser,
                                  "receiveFeedbackEmails",
                                  $event
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.service || _vm.authorizedUser.selectedService
                        ? _c("CInputCheckbox", {
                            staticClass: "custom-checkbox",
                            attrs: {
                              checked: _vm.authorizedUser.canReceiveEmails,
                              label:
                                "Allow this person to receive emails from service?",
                            },
                            on: {
                              "update:checked": function ($event) {
                                return _vm.$set(
                                  _vm.authorizedUser,
                                  "canReceiveEmails",
                                  $event
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showAlwaysCc
                        ? _c("CInputCheckbox", {
                            staticClass: "custom-checkbox",
                            attrs: {
                              checked: _vm.authorizedUser.alwaysCC,
                              label: "Always CC this person on invoices?",
                            },
                            on: {
                              "update:checked": function ($event) {
                                return _vm.$set(
                                  _vm.authorizedUser,
                                  "alwaysCC",
                                  $event
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.service || _vm.authorizedUser.selectedService
                        ? _c("CInputCheckbox", {
                            staticClass: "custom-checkbox",
                            attrs: {
                              checked: _vm.authorizedUser.leftGoogleReview,
                              label: "Has this user left a Google review?",
                            },
                            on: {
                              "update:checked": function ($event) {
                                return _vm.$set(
                                  _vm.authorizedUser,
                                  "leftGoogleReview",
                                  $event
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.newUserMode == "new"
                        ? _c(
                            "span",
                            [
                              !_vm.authorizedUser.isBillingUser
                                ? _c("CInputCheckbox", {
                                    staticClass: "custom-checkbox",
                                    attrs: {
                                      checked:
                                        _vm.authorizedUser.makeContactUser,
                                      label:
                                        "Make this person available to CC on an invoice?",
                                      description:
                                        _vm.makeContactUserDescription,
                                    },
                                    on: {
                                      "update:checked": function ($event) {
                                        return _vm.$set(
                                          _vm.authorizedUser,
                                          "makeContactUser",
                                          $event
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "description",
                                          fn: function () {
                                            return [
                                              _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "form-text text-muted w-100",
                                                  class: _vm.authorizedUser
                                                    .contactPerson
                                                    ? "danger"
                                                    : "info",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t(" +
                                                      _vm._s(
                                                        _vm.makeContactUserDescription
                                                      ) +
                                                      ")\n\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3220915921
                                    ),
                                  })
                                : _c(
                                    "CAlert",
                                    {
                                      staticClass: "mt-2 py-2",
                                      attrs: { color: "info" },
                                    },
                                    [
                                      _vm._v(
                                        "This is the person who will recieve invoices."
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.newUserMode == "new"
                        ? _c("AddressForm", {
                            staticClass: "mt-4",
                            attrs: {
                              label: "Address",
                              openByDefault: _vm.requireAddress,
                              validationErrors: _vm.validationErrors,
                              isValidCallback: _vm.isValid,
                              billingAddress: _vm.client.billingAddress,
                              shippingAddress: _vm.client.shippingAddress,
                            },
                            model: {
                              value: _vm.authorizedUser.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.authorizedUser, "address", $$v)
                              },
                              expression: "authorizedUser.address",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }