var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CustomCSelect", {
    attrs: {
      label: _vm.label,
      value: _vm.selected,
      disabled: _vm.disabled,
      attrs: _vm.attrs,
      options: _vm.options,
      placeholder: _vm.placeholder,
      horizontal: _vm.horizontal == undefined ? true : _vm.horizontal,
    },
    on: {
      "update:value": function ($event) {
        _vm.selected = $event
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "label",
          fn: function () {
            return [_vm._t("tooltip")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }