var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "d-flex",
      class: !_vm.disabled ? "text-info" : "text-dark",
      on: { click: _vm.onClick },
    },
    [
      _vm.icon
        ? _c("i", { staticClass: "text-button-icon", class: _vm.icon })
        : _vm._e(),
      _vm._v(" "),
      _c("span", { staticClass: "text-button-text" }, [
        _vm._v(_vm._s(_vm.text)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }