var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "currency-input-field-container" } }, [
    _c(
      "div",
      { staticClass: "input-container" },
      [
        _c("DxNumberBox", {
          attrs: {
            mode: "number",
            value: _vm.amount,
            min: 0,
            format: { type: "currency", precision: 2 },
            placeholder: _vm.placeholder,
            disabled: _vm.disabled,
            "element-attr": { id: "currencyInput" },
            step: 0,
          },
          on: { input: _vm.onAmountChange },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }