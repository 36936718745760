<template>
	<lookup
		:optionsEndpoint="optionsEndpoint"
		:label="label || 'Zoho Project'"
		placeholder="Select Zoho Project"
		v-model="selected"
		:isValid="isValid"
		:invalidFeedback="invalidFeedback"
		displayKey="name"
		:containerClass="containerClass || ''"
		:fieldClass="fieldClass"
		:labelClass="labelClass"
		:includeProperties="includeProperties"
		:disabled="disabled"
		@change="$emit('change', $event)"
	>
		<template #description>
			<slot name="description">
			</slot>
			<span v-if="enableImport">
				<p v-if="!apiRequest" class="form-text text-muted w-100">Don't see the project you're looking for? <CButton color="link" @click="importProjects">Click Here</CButton></p>
				<spinner v-else class="spinner-inline-sm" />
			</span>
		</template>
	</lookup>
</template>

<script>
import { AxiosWrapper } from '../../mixins';
import Lookup from '../generic/Lookup'

export default {
	name: 'ProjectLookup',
	props: ['value', 'service', 'isValid', 'invalidFeedback', 'containerClass', 'fieldClass', 'labelClass', 'includeProperties', 'disabled', 'enableImport', 'label'],
	mixins: [AxiosWrapper],
	components: {Lookup},
	data(){
		return {
			apiRequest: null,
		}
	},
	computed: {
		selected: {
			get(){
				return this.value;
			},
			set(v){
				this.$emit('input', v);
			}
		},
		optionsEndpoint(){
			return this.apiRequest ? null : '/api/projects/options/'
				+ ((this.service && this.service.id) ? this.service.id : this.constants.EMPTY_GUID)
				+ (this.value ? '?currentlySelected=' + this.value.id : '')
		}
	},
	methods: {
		async importProjects(){
			try {
				const response = await (this.apiRequest = this.get('/api/imports/importProjectsDirectly'))
			} catch (e) {
				console.error(e)
			} finally {
				this.apiRequest = null
			}
		}
	},
}
</script>
