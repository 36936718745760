var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        title: _vm.title,
        color: "danger",
        text: _vm.text,
        show: _vm.show,
        closeOnBackdrop: true,
      },
      on: {
        closed: function ($event) {
          _vm.show = false
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "w-100", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "CButton",
            {
              staticClass: "float-right",
              attrs: { size: "sm", color: "danger" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("\n\t\t\tClose\n\t\t")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }