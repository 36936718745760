var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("lookup", {
    attrs: {
      optionsEndpoint: "/api/clients/booksContactOptions/" + _vm.clientId,
      label: _vm.label || "Zoho Books Customer",
      valueKey: "zohoId",
      displayKey: "name",
      includeProperties: ["contactUrl"],
      isValid: _vm.isValid,
      invalidFeedback: _vm.invalidFeedback,
      labelClass: "col-form-label col-sm-3",
      fieldClass: _vm.value ? "col-sm-8" : "col-sm-9",
      disabled: _vm.disabled,
    },
    on: {
      change: function ($event) {
        return _vm.onChanged($event, true)
      },
      "loaded-options": _vm.loadedOptions,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "tooltip",
          fn: function () {
            return [_vm._t("tooltip")]
          },
          proxy: true,
        },
        {
          key: "extra",
          fn: function () {
            return [
              _vm.value
                ? _c(
                    "CLink",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: "Open in Zoho Books",
                          expression: "'Open in Zoho Books'",
                        },
                      ],
                      staticClass: "m-auto",
                      attrs: { href: _vm.contactLink, target: "_blank" },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "/img/zoho-icon.svg",
                          alt: "Zoho Icon",
                          width: "25",
                          height: "25",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v
      },
      expression: "selected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }