import { render, staticRenderFns } from "./ProductLookup.vue?vue&type=template&id=39950295&"
import script from "./ProductLookup.vue?vue&type=script&lang=js&"
export * from "./ProductLookup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('39950295')) {
      api.createRecord('39950295', component.options)
    } else {
      api.reload('39950295', component.options)
    }
    module.hot.accept("./ProductLookup.vue?vue&type=template&id=39950295&", function () {
      api.rerender('39950295', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/client/components/lookups/ProductLookup.vue"
export default component.exports