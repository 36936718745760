var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CFormGroup",
    _vm._b(
      {
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [
                  _vm._t("label", [
                    _vm.label
                      ? _c(
                          "label",
                          {
                            class: _vm.labelClasses,
                            attrs: { for: _vm.safeId },
                          },
                          [
                            _vm._v("\n        " + _vm._s(_vm.label) + " "),
                            _vm._t("tooltip"),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "input",
              fn: function () {
                return [
                  _c(
                    "input",
                    _vm._b(
                      {
                        class: _vm.inputClasses,
                        attrs: {
                          id: _vm.safeId,
                          type: _vm.type,
                          readonly: _vm.readonly || _vm.plaintext,
                        },
                        domProps: { value: _vm.state },
                        on: {
                          input: function ($event) {
                            return _vm.onInput($event)
                          },
                          change: function ($event) {
                            return _vm.onChange($event)
                          },
                        },
                      },
                      "input",
                      _vm.$attrs,
                      false
                    )
                  ),
                ]
              },
              proxy: true,
            },
            _vm._l(_vm.$options.slots, function (slot) {
              return {
                key: slot,
                fn: function () {
                  return [_vm._t(slot)]
                },
                proxy: true,
              }
            }),
          ],
          null,
          true
        ),
      },
      "CFormGroup",
      {
        append: _vm.append,
        prepend: _vm.prepend,
        validFeedback: _vm.validFeedback,
        invalidFeedback: _vm.invalidFeedback,
        tooltipFeedback: _vm.tooltipFeedback,
        description: _vm.description,
        wrapperClasses: _vm.wrapperClasses,
        class: _vm.computedClasses,
      },
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }