<template>
    <DxSelectBox 
        v-model="statusId" 
        :search-enabled="true"
        :data-source="statuses" 
        display-expr="name" 
        value-expr="id" 
        placeholder="Select Stage" 
        item-template="item"
        :disabled="disabled"
        @opened="$emit('opened', $event)">
        <template #item="{ data }">
            <div :style="`padding:10px 15px !important; background:${data.bgClr}`">
                <div>
                    <span><Icon v-if="data.icon" :name="data.icon" icon-pack="atc" /></span>
                    <span>{{data.name}} </span>
                </div>
            </div>
        </template>
        <DxValidator v-if="required"> <DxRequiredRule message="Project is required"/> </DxValidator>
    </DxSelectBox>			
</template>

<script>
import DxSelectBox from 'devextreme-vue/select-box';
import { AxiosWrapper } from '../../mixins/AxiosWrapper'
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator'
export default {
    props:{
        'required':{
            type: Boolean,
            default: false
        },
        'value':{
            type: String,
            default: ''
        },
        'disabled':{
            type: Boolean,
            default: false
        },
        'disabled-items':{
            type: Array,
            default: () => []
        }
    },
    components: { DxSelectBox, DxValidator, DxRequiredRule },
    mixins: [ AxiosWrapper ],
    data(){
        return {
            isLoaded: true,
            statusesRaw:[],
            statuses: {
                loadMode: 'raw',
                load: () => {
                    const promise = new Promise((resolve) => {
                        this.get('api/projects-in-flight/statuses').then(response => {
                            let statuses = response.data;
                            statuses.forEach(status => {
                                if(this.disabledItems.includes(status.id)) status.disabled = true
                            })
                            this.$emit('on-load', statuses)
                            this.statusesRaw = statuses
                            resolve(this.statusesRaw);
                        })
                    });
                    return promise;
                },
            },
        }
    },
    methods:{
        onSelectStatus(e){
            this.$emit('value-changed', e.value)
            let status = e.value ? this.find(this.statusesRaw, e.value) : undefined
            this.$emit('status-changed', status)
        },
    },
    watch:{
        disabledItems(){
            this.statuses = {
                loadMode: 'raw',
                load: () => {
                    const promise = new Promise((resolve) => {
                        this.statusesRaw.forEach(status => {
                            if(this.disabledItems.includes(status.id)) status.disabled = true
                            else status.disabled = false
                        })
                        resolve(this.statusesRaw);
                    });
                    return promise;
                },
            }
        }
    },
    computed:{
        statusId:{
            get(){ return this.value || '' },
			set(v){ this.$emit('input', v) }
        }
    }
}
</script>