var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.containerClass || "form-group form-row lookup-field",
      attrs: { role: "group" },
    },
    [
      _vm.label
        ? _c("label", { class: _vm.labelClass || "col-form-label w-180" }, [
            _vm._v(" " + _vm._s(_vm.label)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.fieldClass || "width-300-c" },
        [
          _c("v-select", {
            class: { "is-invalid": !_vm._isValid },
            attrs: {
              label: _vm._displayKey,
              options: _vm.sortedOptions,
              placeholder: _vm.placeholderText,
              invalidFeedback: _vm.invalidFeedback || "",
              clearable: _vm.clearable !== undefined ? _vm.clearable : true,
              disabled: _vm.disabled,
              "append-to-body": true,
              multiple: _vm.multiple,
              selectable: function (option) {
                return !option.heading
              },
            },
            on: { input: _vm.onChange },
            scopedSlots: _vm._u([
              {
                key: "option",
                fn: function (option) {
                  return [
                    option.heading
                      ? _c(
                          "h4",
                          {
                            staticClass: "mb-0",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                              },
                            },
                          },
                          [_vm._v(_vm._s(option[_vm._displayKey]))]
                        )
                      : _c("span", { staticClass: "ms-3" }, [
                          _vm._v(_vm._s(option[_vm._displayKey])),
                        ]),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          }),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control is-invalid",
            attrs: { type: "hidden" },
          }),
          _vm._v(" "),
          !_vm._isValid
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(_vm._s(_vm._invalidFeedback)),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("extra"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }