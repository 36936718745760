<template>
    <tr>
        <td colspan="7" v-height="'100px'" class="position-relative">
            <div class="vertical-center">
                <span v-if="loading"> <CSpinner size="sm" component="span" aria-hidden="true"/> Loading... </span>
                <span v-else>No data <Icon name="slash-circle" icon-pack="bootstrap" /></span>
            </div>
        </td>
    </tr>
</template>

<script>
export default {
    props:{
        'loading':{
            type: Boolean,
            default: false
        }
    }
}
</script>
