<template>
	<lookup
		optionsEndpoint="/api/clients"
		:label="labelText"
		:placeholder="multiple ? 'Select Client(s)' : 'Select Client'"
		v-model="selected"
		:isValid="isValid"
		:invalidFeedback="invalidFeedback"
		ref="lookupField"
		displayKey="companyName"
		:clearable="clearable !== undefined ? clearable : true"
		:multiple="multiple !== undefined ? multiple : false"
		:containerClass="containerClass || ''"
		:labelClass="labelClass"
		:fieldClass="fieldClass"
		:style="style"
		:disabled="disabled"
		:includeProperties="includeProperties"
		@change="$emit('change', $event)"
	>

	<!-- <CButton slot="extra" class="col-sm-1" size="sm" color="primary" @click="$emit('click-add-client')"><CIcon name="cil-plus" /> Add New</CButton> -->

	</lookup>
</template>

<script>

import Lookup from '../generic/Lookup.vue'

export default {
	name: 'ClientLookup',
	props: ['value', 'isValid', 'invalidFeedback', 'label', 'clearable', 'containerClass', 'labelClass', 'fieldClass', 'minWidth', 'includeProperties', 'disabled', 'multiple'],
	components: {Lookup},
	computed: {
		selected: {
			get(){
				return this.value;
			},
			set(v){
				this.$emit('input', v);
			}
		},
		labelText(){
			return this.label !== false ? (this.label || 'Client') : false;
		},
		style(){
			return this.minWidth ? {
				'min-width': this.minWidth
			} : {};
		}
	},
	methods: {
		addOption(option){
			this.$refs.lookupField.addOption(option);
		}
	}
}
</script>
