var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.popup.show
    ? _c(
        "DxPopup",
        {
          attrs: {
            "wrapper-attr": { id: "dxConfirmPopup", class: _vm.mode },
            visible: _vm.popup.show,
            "on-hidden": _vm.popup.closeButtonOptions,
            "drag-enabled": true,
            "hide-on-outside-click": _vm.hideOnOutsideClick,
            "show-close-button": false,
            "show-title": false,
            height: _vm.height,
            width: _vm.width,
          },
        },
        [
          _c(
            "div",
            [
              !_vm.loading
                ? _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "18px",
                            margin: "5px",
                            "font-weight": "bolder",
                          },
                        },
                        [_vm._v(_vm._s(_vm.title))]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "mt-3" }, [
                        _vm._v(_vm._s(_vm.subTitle)),
                      ]),
                      _vm._v(" "),
                      _c("DxButton", {
                        attrs: {
                          text: _vm.cancelButtonText,
                          "element-attr": { class: "cancel-btn" },
                        },
                        on: { click: _vm.cancel },
                      }),
                      _vm._v(" "),
                      _c("DxButton", {
                        attrs: {
                          text: _vm.confirmButtonText,
                          type: _vm.mode,
                          "element-attr": { class: "confirm-btn" },
                        },
                        on: { click: _vm.confirm },
                      }),
                    ],
                    1
                  )
                : _c("spinner"),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }