<template>
	<CustomCModal
		:title="title"
		:color="color"
		:show.sync="showModal"
		:close-on-backdrop="closeOnBackdrop"
		:size="modalSize"
		:addDialogClasses="dialogClasses"
		:addModalClasses="addModalClasses"
		@update:show="updateShow"
	>
		<template #title-append><slot name="title-append"></slot></template>
		<template #header-float-right><slot name="header-float-right"></slot></template>


		<slot name="content"></slot>
		<slot>{{text}}</slot>

		<slot name="footer" slot="footer" class="w-100"></slot>
	</CustomCModal>
</template>
<script>
import CustomCModal from './CustomCModal.vue'
export default {
	name: 'Modal',
	components: {CustomCModal},
	props: ['title', 'color', 'text', 'show', 'size', 'closeOnBackdrop', 'setDialogClasses', 'addModalClasses'],
	methods: {
		updateShow(a){
			if (!a){
				this.$emit('closed');
			}
		}
	},
	computed: {
		showModal: {
			get(){
				return this.show;
			},
			set(v){
				this.$emit('update:show', v);
			}
		},
		modalSize(){
			return this.size || '';
		},
		dialogClasses() {
			return this.setDialogClasses || 'modal-dialog-scrollable'
		}
	}
}
</script>
