<template>
	<lookup
		:preloadedOptions="options"
		:label="label !== false ? (label || 'Cc') : false"
		v-model="selected"
		:isValid="isValid"
		:invalidFeedback="invalidFeedback"
		displayKey="fullName"
		@change="$emit('change', $event)"
		labelClass="col-form-label col-sm-3"
		fieldClass="col-sm-9"
		:multiple="true"
	>
	<template #tooltip>
		<CIcon v-if="Boolean(label)" name="cid-info-circle" class="icon-tooltip" v-c-tooltip="'Want to copy a client on this invoice? Go to the Authorized Users panel to add eligible clients.'"/>
	</template>
</lookup>
</template>

<script>

import Lookup from '../generic/Lookup'

export default {
	props: ['value', 'isValid', 'invalidFeedback', 'label', 'clientId', 'level', 'options'],
	components: {Lookup},
	computed: {
		selected: {
			get(){
				return this.value;
			},
			set(v){
				this.$emit('input', v);
			}
		},
	}
}
</script>
