<template>
    <DxButton 
        :text="text" 
        :icon="icon"
        :disabled="disabled"
        :element-attr="{id: 'customDevextremeBtn'}"
        @content-ready="onContentReady"
        @click="$emit('click')"
    />
</template>

<script>
import DxButton from 'devextreme-vue/button';

export default {
    components:{
		DxButton,
	},
    props: {
        'text': {
            type: String, 
            default: ''
        },
        'icon': {
            type: String, 
            default: ''
        },
        'disabled': {
            type: Boolean, 
            default: false
        },
        'custom-class': {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            elementAttr: {id: 'customDevextremeBtn', class: ''}
        }
    },
    methods: {
        onLoad(){
            this.elementAttr.class = this.customClass;
        },
        onContentReady(e){
            if(this.customClass){
                let classesArray = this.customClass.split(" ");
                for(let i = 0; i < classesArray.length; i++){
                    e.component.element().classList.add(classesArray[i]);
                }
            }
        }
    },
    mounted(){
        this.onLoad();
    }
}
</script>

<style lang="scss">
    #customDevextremeBtn{
        border-radius: 6px;
        &.small-btn{
            border: none !important;
			.dx-button-content{
				padding: 4px 8px;
				border-radius: 6px;
                .dx-icon{
                    font-size: 14px !important;
                }
			}
        }
        &.primary{
            border: 1px solid #1570EF;
            background: #1570EF;
            color: #FFFFFF;

            .dx-icon{
                color: #FFFFFF;
            }
        }
        &.primary-outline{
            border: 1px solid #1570EF;
            color: #1570EF;

            .dx-icon{
                color: #1570EF;
            }
        }
        &.purple{
            border: 1px solid #444CE7;
            color: #FFFFFF;
            background: #444CE7; 
            
            .dx-icon{
                color: #FFFFFF;
            }
        }
        &.light-primary{
            background: #D1E9FF;
			border: 1px solid #84CAFF;
			color: #1570EF;
            
            .dx-icon{
                color: #1570EF;
            }
        }
        &.secondary{
            border: 1px solid #667085;
            background: #E4E7EC;
            color: #667085;
            .dx-icon{
                color: #FFFFFF;
            }
        }
        &.danger{
            border: 1px solid #D92D20;
            background: #D92D20;
            color: #FFFFFF;
            .dx-icon{
                color: #FFFFFF;
            }
        }
        &.success{
            border: 1px solid #039855;
            background: #039855;
            color: #FFFFFF;
            .dx-icon{
                color: #FFFFFF;
            }
        }
        &.success-secondary 
        {
            border: 1px solid #6CE9A6;
			color: #039855;
			background: #ECFDF3;
            .dx-icon{
                color: #039855;
            }
        }
    }
</style>