<template>
    <a class="d-flex" @click="onClick" :class="!disabled ? 'text-info' : 'text-dark'">
        <i v-if="icon" :class="icon" class="text-button-icon"></i>
        <span class="text-button-text">{{text}}</span>
    </a>
</template>
<script>
export default {
    props: ['text', 'icon', 'disabled'],
    methods:{
        onClick(){
            if(!this.disabled){
                this.$emit('click')
            }
        }
    }
}
</script>
<style scoped>
.text-button-text{
    font-size: 13px;
}
.text-button-icon{
    font-size: 15px;
    margin-right: 3px;
}
</style>