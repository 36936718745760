<template>
    <div>
        <span class="badge" :class="color">{{status}}</span>
    </div>
</template>
<script>
export default {
    props:['status'],
    computed:{
        color(){
            if(this.status == 'Pending'){
                return 'badge-warning-light'
            }
            else if(this.status == 'Paid'){
                return 'badge-success-light'
            }
            else if(this.status == 'Ineligible'){
                return 'badge-danger-light'
            }
            return ''
        }
    }
}
</script>
