var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DxSelectBox",
    {
      attrs: {
        "search-enabled": true,
        "data-source": _vm.clients,
        "display-expr": "companyName",
        "value-expr": "id",
        placeholder: "Select Client",
        disabled: _vm.disabled,
        "read-only": _vm.readOnly,
        hint: _vm.hint,
      },
      on: { "value-changed": _vm.onSelectClient },
      model: {
        value: _vm.clientId,
        callback: function ($$v) {
          _vm.clientId = $$v
        },
        expression: "clientId",
      },
    },
    [
      _vm.required
        ? _c(
            "DxValidator",
            [
              _c("DxRequiredRule", {
                attrs: { message: "Client is required" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }