<template>
	<CAlert
		:color="color"
		:closeButton="closeButton"
		:show.sync="_show"
		:fade="fade"
	>
		<slot>{{text}}</slot>
		<div v-if="rawHtml" v-html="rawHtml"></div>
	</CAlert>
</template>

<script>
export default {
	props: ['color', 'closeButton', 'show', 'fade', 'text', 'rawHtml'],
	computed: {
		_show: {
			get() { return this.show },
			set(v) { this.$emit('update:show', v) }
		}
	}
}
</script>
