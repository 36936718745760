export const LevelColor = {
	methods: {
		levelColor(level){
			switch(level){
				case 'Unauthorized':
					return 'secondary'
				case 'Banned':
					return 'danger'
				case 'Admin':
				case 'Support':
				case 'RestrictedSupport':
					return 'success'
				default:
					return 'secondary'
			}
		}
	}
}
