var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-avatar-wrapper mr-2" }, [
    _c("div", { staticClass: "c-avatar" }, [
      _c("img", {
        staticClass: "c-avatar-img fit",
        attrs: { src: _vm.src || "/KDGfavicon.png" },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }