import { render, staticRenderFns } from "./UserLeaveBadge.vue?vue&type=template&id=221a3825&"
import script from "./UserLeaveBadge.vue?vue&type=script&lang=js&"
export * from "./UserLeaveBadge.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('221a3825')) {
      api.createRecord('221a3825', component.options)
    } else {
      api.reload('221a3825', component.options)
    }
    module.hot.accept("./UserLeaveBadge.vue?vue&type=template&id=221a3825&", function () {
      api.rerender('221a3825', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/client/components/UserLeaveBadge.vue"
export default component.exports