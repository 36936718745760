var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CAlert",
    {
      attrs: {
        color: _vm.color,
        closeButton: _vm.closeButton,
        show: _vm._show,
        fade: _vm.fade,
      },
      on: {
        "update:show": function ($event) {
          _vm._show = $event
        },
      },
    },
    [
      _vm._t("default", [_vm._v(_vm._s(_vm.text))]),
      _vm._v(" "),
      _vm.rawHtml
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.rawHtml) } })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }