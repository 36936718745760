<template>
	<lookup
		optionsEndpoint="/api/products"
		:label="label || 'Product'"
		v-model="selected"
		:isValid="isValid"
		:invalidFeedback="invalidFeedback"
		:labelClass="labelClass"
		:fieldClass="fieldClass"
		@change="$emit('change', $event)"
	></lookup>
</template>

<script>

import Lookup from '../generic/Lookup'

export default {
	name: 'ProductLookup',
	props: ['value', 'isValid', 'invalidFeedback', 'label', 'labelClass', 'fieldClass'],
	components: {Lookup},
	computed: {
		selected: {
			get(){
				return this.value;
			},
			set(v){
				this.$emit('input', v);
			}
		}
	}
}
</script>
