<template>
	<span>
		<lookup
			:optionsEndpoint="optionsEndpoint"
			:label="label || 'Authorized User'"
			v-model="selected"
			:isValid="isValid"
			:invalidFeedback="invalidFeedback"
			:displayKey="displayKey || 'fullName'"
			:labelClass="labelClass || 'col-form-label col-sm-3'"
			fieldClass="col-sm-9"
			:includeProperties="_includeProperties"
			:appendOption.sync="appendOption"
			:key="key"
			@change="onChange"
			@loaded-options="$emit('loaded-options', $event)"
		>
		</lookup>
	</span>
</template>

<script>

import Lookup from '../generic/Lookup'

export default {
	name: 'AuthorizedUserLookupSimplified',
	props: ['value', 'isValid', 'invalidFeedback', 'label', 'service', 'clientId', 'level',
		'requireAddress', 'includeProperties', 'displayKey', 'otherServicesOnly', 'labelClass',
	],
	components: {Lookup},
	data(){
		return {
			newAuthedUser: null,
			userFormKey: 0,
			appendOption: null,
			keyIncrement: 0,
		}
	},
	watch: {
		service(){
			if (this.otherServicesOnly){
				this.keyIncrement++
			}
		}
	},
	computed: {
		key(){ return this.keyIncrement + 'cid' + this.clientId },
		selected: {
			get(){
				return this.value;
			},
			set(v){
				this.$emit('input', v);
			}
		},
		optionsEndpoint(){
			if (this.otherServicesOnly && this.service && this.service.id != this.constants.EMPTY_GUID){
				return ('/api/authorizedUsers/service/' + this.service.id)
					+ '?distinct=true&otherServicesOnly=true'
					+ (this.level ? '&level=' + this.level : '')
			}
			return ((this.serviceUsersOnly && this.service)
				? ('/api/authorizedUsers/service/' + this.service.id)
				: ('/api/authorizedUsers/client/' + this.clientId))
				+ '?distinct=true'
				+ (this.level ? '&level=' + this.level : '')
		},
		_includeProperties(){
			const props = (this.includeProperties || [])
			if (this.requireAddress && !props.includes('address')){
				props.push('address')
			}
			return props
		}
	},
	methods: {
		onChange(value){
			this.$emit('change', value)
		}
	}
}
</script>
