var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.popup.show
    ? _c(
        "DxPopup",
        {
          attrs: {
            "wrapper-attr": { id: "dxAlertPopup", class: _vm.mode },
            visible: _vm.popup.show,
            "on-hidden": _vm.popup.closeButtonOptions,
            "drag-enabled": true,
            "hide-on-outside-click": false,
            "show-close-button": false,
            "show-title": false,
            width: _vm.width,
            height: _vm.height,
          },
        },
        [
          _c("div", [
            _vm.loading
              ? _c(
                  "div",
                  {
                    staticClass: "spinner-wrapper",
                    staticStyle: { "min-height": "100px" },
                  },
                  [_c("CSpinner", { attrs: { "aria-hidden": "true" } })],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c("strong", [_vm._v(_vm._s(_vm.title))]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mt-2" }, [
                      _vm._v(_vm._s(_vm.text)),
                    ]),
                    _vm._v(" "),
                    _c("DxButton", {
                      attrs: {
                        text: _vm.confirmButtonText,
                        type: _vm.mode,
                        width: "100%",
                      },
                      on: { click: _vm.confirm },
                    }),
                  ],
                  1
                ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }