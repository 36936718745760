<template>
    <DxSelectBox
        v-model="selected"
        :search-enabled="true"
        :data-source="list"
        :display-expr="displayExpr"
        :value-expr="valueExpr"
        :placeholder="placeholder"
        :disabled="disabled"
        :read-only="readOnly"
        :label="label"
        :label-mode="labelMode"
        :show-clear-button="showClearButton"
        :hint="hint"
        :element-attr="elementAttr"
        @value-changed="onValueChanged">

        <template v-if="showCustomTemp" #item="{ data }">
            <div style="padding: 14px; border: 1px solid lightgray">
                <span v-if="displayExpr2 == ''">{{ data[displayExpr]}}</span>
                <span v-else>{{data[displayExpr][displayExpr2]}}</span>
                <span v-if="showLinkedClient" class="float-right text-danger">
                    {{ data.isLinked && !data.hasActiveService ? 'Linked' : '' }}
                </span>
            </div>
        </template>

        <DxValidator v-if="required"> <DxRequiredRule message="Value is required"/> </DxValidator>
    </DxSelectBox>
</template>

<script>
import DxSelectBox from 'devextreme-vue/select-box';
import { AxiosWrapper } from '../../mixins/AxiosWrapper'
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
export default {
    props:{
        'value':[String, Object],
        'required':{
            type: Boolean,
            default: false
        },
        'display-expr':{
            type: String,
            default: 'name'
        },
        'display-expr2': {
            type: String,
            default: ''
        },
        'value-expr':{
            type: String,
            default: ''
        },
        'placeholder':{
            type: String,
            default: 'Select...'
        },
        'api':{
            type: String,
            default: ''
        },
        'call-api':{
            type: Boolean,
            default: true
        },
        'data-source': {
            type: Array,
            default: () => []
        },
        'disabled':{
            type: Boolean,
            default: false
        },
        'read-only':{
            type: Boolean,
            default: false
        },
        'show-clear-button':{
            type: Boolean,
            default: false
        },
        'label':{
            type: String,
            default: ''
        },
        'label-mode':{
            type: String,
            default: 'hidden'
        },
        'hint':{
            type: String,
            default: ''
        },
        'element-attr':{
            type: Object,
            default: () => {}
        },
        'show-custom-temp':{
            type: Boolean,
            default: false
        },
        'receive-data-source':{
            type: Boolean,
            default: false
        },
        'show-linked-client':{
            type: Boolean,
            default: false
        },
    },
    components:{ DxSelectBox, DxValidator, DxRequiredRule },
    mixins:[ AxiosWrapper ],
    data(){
        return {
            listRaw:[],
            list: null,
        }
    },
    created(){
        this.list = !this.callApi ? this.dataSource : this.initializeList()
    },
    watch:{
        api(){
            this.list = !this.callApi ? this.dataSource : this.initializeList()
        }
    },
    computed:{
        selected:{
            get(){
                return this.value || undefined
            },
            set(v){
                this.$emit('input', v)
            }
        }
    },
    methods:{
        onValueChanged(e){
            if(typeof e.value != 'object' && this.valueExpr != ''){
                let obj = this.listRaw.find(s => s[this.valueExpr] == e.value)
                e.object = obj
            }
            this.receiveDataSourcethis ? 
            this.$emit('value-changed', {value: e.value, dataSource: e.component.getDataSource().items()}) : 
            this.$emit('value-changed', e)
        },
        initializeList(){
            return {
                loadMode: 'raw',
                load: () => {
                    const promise = new Promise((resolve) => {
                        if(this.api){
                            this.get(this.api).then(response =>{
                                this.listRaw = typeof response.data == 'string' ? JSON.parse(response.data) : response.data
                                this.$emit('on-load', this.listRaw)
                                resolve(this.listRaw);
                            }).catch(error => {
                                console.log(error.message)
                                this.listRaw = []
                                resolve(this.listRaw);
                            })
                        }
                        else{
                            this.listRaw = []
                            resolve(this.listRaw);
                        }
                    });
                    return promise;
                },
            }
        },
    }
}
</script>
