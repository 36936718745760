<template>
	<div id="currency-input-field-container">
		<div class="input-container">
			<DxNumberBox
				mode="number"
				:value="amount"
				:min="0"
				:format="{type: 'currency', precision: 2}"
				:placeholder="placeholder"
				:disabled="disabled"
				:element-attr="{id: 'currencyInput'}"
				:step="0"
				@input="onAmountChange"
			/>
		</div>
	</div>
</template>

<script>
import { DxNumberBox } from 'devextreme-vue';

export default {
	components:{
		DxNumberBox
	},
	props: {
		'amount': {
			type: Number,
			default: 0
		},
		'placeholder':{
			type: String,
			default: ''
		},
		'disabled':{
			type: Boolean,
			default: false
		}
	},
	methods:{
		onAmountChange(e){
			let amount = e.event.target.value;
			if(amount){
				amount = amount.replaceAll("$", "");
				amount = amount.replaceAll(",", "");
				this.$emit('valueChanged', parseFloat(amount));
			}
		}
	}
}
</script>

<style lang="scss">
	#currency-input-field-container{
		width: 100%;

		.input-container{
			display: flex;
		}

		#currencyInput{
			width: 100%;
			border: 1px solid lightgray !important;
			border-radius: 6px !important;
			padding: 10px !important;
		}
	}
</style>