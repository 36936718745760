<template>
	<modal :text="text" :title="modalTitle" :color="confirmColor" :show="show" :size="modalSize" :close-on-backdrop="false" @closed="cancel">
		<div slot="content" v-if="innerComponent || rawHtml">
			<div v-if="innerComponent" :is="innerComponent" v-bind="innerComponentData"></div>
			<div v-if="rawHtml" v-html="rawHtml"></div>
		</div>

		<div slot="footer" class="w-100">
			<CButton size="sm" class="float-right ml-2" :color="confirmColor" @click="ok">
				{{confirmBtnText}}
			</CButton>
			<CButton size="sm" class="float-right" color="secondary" @click="cancel">
				Cancel
			</CButton>
		</div>
	</modal>
</template>

<script>
export default {
	// props: ['text', 'show', 'color', 'title'],
	data(){
		return {
			text: '',
			show: false,
			color: '',
			title: '',
			size: '',
			rawHtml: null,
			confirmBtnText: 'Ok',
			innerComponent: null,
			innerComponentData: {},
			onConfirm: function(){},
			onCancel: function(){},
		}
	},
	computed: {
		confirmColor(){
			return this.color || 'danger';
		},
		modalTitle(){
			return this.title || 'Confirm';
		},
		modalSize(){
			return this.size || '';
		},
	},
	methods: {
		showConfirmModal(options){
			this.confirmBtnText = options.confirmBtnText || 'Ok'
			this.text = options.text || ''
			this.color = options.color || 'danger'
			this.title = options.title || 'Confirm'
			this.size = options.size || null
			this.rawHtml = options.rawHtml || null
			this.onConfirm = options.onConfirm || function(){}
			this.onCancel = options.onCancel || function(){}
			this.innerComponent = options.innerComponent || null
			this.innerComponentData = options.innerComponentData || {};
			this.show = true;
		},
		ok(){
			this.onConfirm();
			this.onConfirm = function(){};
			this.closed();
		},
		cancel(){
			this.onCancel();
			this.onCancel = function(){};
			this.closed();
		},
		closed(){
			this.show = false;
		}
	}
}
</script>
