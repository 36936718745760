var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return (_vm.userLeaves || []).length > 0
    ? _c(
        "span",
        { staticClass: "pointer" },
        [
          _c("Icon", {
            attrs: { name: "cid-flight-takeoff", "el-attr": { id: _vm.id } },
          }),
          _vm._v(" "),
          _c(
            "b-popover",
            { attrs: { target: _vm.id, triggers: "hover", placement: "top" } },
            _vm._l(_vm.userLeaves, function (userLeave) {
              return _c(
                "div",
                { key: userLeave.id },
                [
                  _c("Icon", {
                    attrs: {
                      name:
                        userLeave.type == "Flex"
                          ? "cil-clock"
                          : "cid-flight-takeoff",
                      "el-attr": { id: _vm.id },
                    },
                  }),
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(userLeave.type) +
                      " - " +
                      _vm._s(_vm.userLeaveTooltip(userLeave, _vm.date)) +
                      "\n\t\t\t\t"
                  ),
                  userLeave.leaveStatus == "Pending"
                    ? _c("span", { staticClass: "badge badge-primary" }, [
                        _vm._v("Pending"),
                      ])
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }