<template>
	<lookup
		:optionsEndpoint="'/api/clients/booksContactOptions/' + clientId"
		:label="label || 'Zoho Books Customer'"
		valueKey="zohoId"
		displayKey="name"
		:includeProperties="['contactUrl']"
		v-model="selected"
		:isValid="isValid"
		:invalidFeedback="invalidFeedback"
		labelClass="col-form-label col-sm-3"
		:fieldClass="value ? 'col-sm-8' : 'col-sm-9'"
		:disabled="disabled"
		@change="onChanged($event, true)"
		@loaded-options="loadedOptions"
	>
		<template #tooltip><slot name="tooltip" /></template>
		<template #extra>
			<CLink v-if="value" :href="contactLink" target="_blank" v-tooltip="'Open in Zoho Books'" class="m-auto"><img src="/img/zoho-icon.svg" alt="Zoho Icon" width="25" height="25"></CLink>
		</template>
	</lookup>
</template>

<script>

import Lookup from '../generic/Lookup.vue'

export default {
	name: 'BooksContactLookup',
	props: ['value', 'clientId', 'isValid', 'invalidFeedback', 'label', 'disabled'],
	components: {Lookup},
	data(){
		return {
			contactLink: '',
		}
	},
	computed: {
		selected: {
			get(){
				return { zohoId: this.value };
			},
			set(v){
				this.$emit('input', v ? v.zohoId : null);
			}
		}
	},
	methods: {
		onChanged(selected, emitUp){
			this.contactLink = selected ? selected.contactUrl : ''
			if (emitUp){
				// Only emit when the selection is changed by the user.
				this.$emit('change', selected)
			}
		},
		loadedOptions(options){
			if (this.selected){
				this.onChanged(options.find(o => o.zohoId == this.selected.zohoId), false)
			}
		}
	}
}
</script>
