<template>
	<!-- close on backdrop is false by default because I'm sick of accidentally closing the form -->
	<Modal
		v-move-to-element="'popup-forms'"
		:title="title"
		:show="show"
		:close-on-backdrop="closeOnBackdrop || false"
		:size="modalSize"
		:color="color || ''"
		:backdrop="showBackdrop"
		addDialogClasses="modal-dialog-scrollable"
		@update:show="updateShow"
		:centered="true"
	>
		<template #header v-if="showHeader">
			<slot name="header">
				<div class="w-100">
					<h5 class="float-left modal-title">{{title}}</h5>
					<slot name="header-button"></slot>
				</div>
				<button type="button" aria-label="Close" class="close" @click="closed">×</button>
			</slot>
		</template>

		<slot></slot>
		<slot name="form"></slot>

		<div slot="footer" class="w-100">
			<slot name="footer-prepend"></slot>
			<CButton size="sm" class="float-right ml-2" :color="color || 'primary'" @click="$emit('click-submit')" :disabled="submitDisabled">
				<slot name="custom-button-text">{{ submitBtnText || 'Submit'}}  </slot>
			</CButton>
			<CButton size="sm" class="float-right" color="secondary" @click="closed">
				Cancel
			</CButton>
		</div>

	</Modal>
</template>

<script>
import { MoveToElement } from '../../directives'
import Modal from './Modal.vue';
export default {
    props: ['title', 'show-title', 'show', 'size', 'submitBtnText', 'color', 'backdrop', 'closeOnBackdrop', 'submitDisabled',],
	components: { Modal },
    directives: { MoveToElement },
    computed: {
        modalSize() {
            return this.size || '';
        },
        showBackdrop() {
            return this.backdrop == null || this.backdrop;
        },

		showHeader(){
			return this.showTitle == false ? true : false;
		}
    },
    methods: {
        closed() {
            // this.showModal = false;
            this.$emit('click-cancel');
        },
        updateShow(a) {
            if (!a) {
                this.closed();
            }
        }
    },
}
</script>
