var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("lookup", {
    style: _vm.style,
    attrs: {
      optionsEndpoint: "/api/invoices/taxAuthorities",
      label: _vm.label !== false ? _vm.label || "Tax Authority" : false,
      valueKey: "zohoTaxAuthorityId",
      displayKey: "taxAuthorityName",
      labelClass: "col-form-label col-sm-3 required",
      fieldClass: _vm.fieldClass || "col-sm-9",
      isValid: _vm.isValid,
      invalidFeedback: _vm.invalidFeedback,
      placeholder: _vm.placeholder,
      clearable: _vm.clearable !== undefined ? _vm.clearable : true,
      containerClass: _vm.containerClass || "",
      fieldCol: _vm.fieldCol,
      sort: false,
      disabled: _vm.disabled,
      autoSelectOnlyOption: _vm.autoSelectOnlyOption,
    },
    on: {
      change: function ($event) {
        return _vm.$emit("change", $event)
      },
      "loaded-options": _vm.loadedOptions,
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v
      },
      expression: "selected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }