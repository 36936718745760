<template>
	<CTextarea
		:label="label"
		v-model="text"
		:horizontal="horizontal"
		:placeholder="placeholder"
		:description="description"
		:isValid="isValid"
		:invalidFeedback="invalidFeedback"
		:addLabelClasses="addLabelClasses"
	>
	</CTextarea>
</template>

<script>
export default {
	props: ['value', 'label', 'horizontal', 'placeholder', 'description', 'isValid', 'invalidFeedback', 'addLabelClasses'],
	computed: {
		text: {
			get() { return this.value },
			set(v) { this.$emit('input', v) }
		},
	}
}
</script>

<style>

</style>
