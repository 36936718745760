var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.popup.isPopup
    ? _c(
        "DxPopup",
        {
          attrs: {
            visible: _vm.popup.show,
            "on-hidden": _vm.closeButtonOptions,
            "drag-enabled": false,
            "scroll-enabled": true,
            "hide-on-outside-click": _vm.hideOnOutsideClick,
            "show-close-button": _vm.showCloseButton,
            "show-title": _vm.showTitle,
            width: _vm.width,
            height: _vm.height,
            title: _vm.title,
            "max-height": _vm.maxHeight,
            "wrapper-attr": _vm.elAttr,
            titleTemplate: "title",
          },
        },
        [
          _vm.overrideTitleSlot
            ? _vm._t("title")
            : _c("template", {}, [
                _c(
                  "div",
                  {
                    staticClass:
                      "dx-toolbar dx-widget dx-visibility-change-handler dx-collection dx-popup-title dx-has-close-button",
                    attrs: { role: "toolbar" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-between",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "dx-item dx-toolbar-item dx-toolbar-label",
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" + _vm._s(_vm.title) + " "
                            ),
                            _vm._t("header-before"),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "dx-toolbar-center" },
                          [_vm._t("header-center")],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-between",
                          },
                          [
                            _vm._t("header-after"),
                            _vm._v(" "),
                            _c("DxButton", {
                              staticClass: "popupCloseBtn",
                              attrs: { icon: "close", "styling-mode": "text" },
                              on: { click: _vm.closeButtonOptions },
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
          _vm._v(" "),
          _vm.nativeScroll
            ? _c(
                "DxScrollView",
                {
                  attrs: {
                    width: "100%",
                    height: "100%",
                    "show-scrollbar": "always",
                  },
                },
                [
                  _c(
                    "div",
                    { class: { "pr-10px": !_vm.noPadding } },
                    [_vm._t("default")],
                    2
                  ),
                ]
              )
            : _c(
                "div",
                {
                  directives: [
                    {
                      name: "height",
                      rawName: "v-height",
                      value: "100%",
                      expression: "'100%'",
                    },
                  ],
                },
                [_vm._t("default")],
                2
              ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }