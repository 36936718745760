var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      directives: [
        {
          name: "move-to-element",
          rawName: "v-move-to-element",
          value: "popup-forms",
          expression: "'popup-forms'",
        },
      ],
      attrs: {
        title: _vm.title,
        show: _vm.show,
        "close-on-backdrop": _vm.closeOnBackdrop || false,
        size: _vm.modalSize,
        color: _vm.color || "",
        backdrop: _vm.showBackdrop,
        addDialogClasses: "modal-dialog-scrollable",
        centered: true,
      },
      on: { "update:show": _vm.updateShow },
      scopedSlots: _vm._u(
        [
          _vm.showHeader
            ? {
                key: "header",
                fn: function () {
                  return [
                    _vm._t("header", [
                      _c(
                        "div",
                        { staticClass: "w-100" },
                        [
                          _c("h5", { staticClass: "float-left modal-title" }, [
                            _vm._v(_vm._s(_vm.title)),
                          ]),
                          _vm._v(" "),
                          _vm._t("header-button"),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "close",
                          attrs: { type: "button", "aria-label": "Close" },
                          on: { click: _vm.closed },
                        },
                        [_vm._v("×")]
                      ),
                    ]),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm._t("form"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "w-100", attrs: { slot: "footer" }, slot: "footer" },
        [
          _vm._t("footer-prepend"),
          _vm._v(" "),
          _c(
            "CButton",
            {
              staticClass: "float-right ml-2",
              attrs: {
                size: "sm",
                color: _vm.color || "primary",
                disabled: _vm.submitDisabled,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("click-submit")
                },
              },
            },
            [
              _vm._t("custom-button-text", [
                _vm._v(_vm._s(_vm.submitBtnText || "Submit") + "  "),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "CButton",
            {
              staticClass: "float-right",
              attrs: { size: "sm", color: "secondary" },
              on: { click: _vm.closed },
            },
            [_vm._v("\n\t\t\tCancel\n\t\t")]
          ),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }