var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.reevaluating
        ? _c("spinner")
        : _vm.validationResponse
        ? _c("div", [
            _vm.validationResponse.alertHTML
              ? _c("div", { staticClass: "alert alert-warning" }, [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(_vm.validationResponse.alertHTML),
                    },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "d-flex border mb-2",
                staticStyle: { "border-radius": "10px" },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "width",
                        rawName: "v-width",
                        value: "40px",
                        expression: "'40px'",
                      },
                    ],
                    staticClass: "border-right position-relative",
                  },
                  [
                    _c("Icon", {
                      staticClass: "vertical-center",
                      attrs: { name: "wallet", "icon-pack": "bootstrap" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "pl-2 pt-2 pb-2" }, [
                  _c("p", { staticClass: "mb-1" }, [
                    _vm._v(
                      "Before this " +
                        _vm._s(_vm.userLeave.entryType) +
                        " " +
                        _vm._s(_vm.username) +
                        "'s " +
                        _vm._s(_vm.userLeave.type) +
                        "'s balance is " +
                        _vm._s(_vm.balanceBefore)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      "After this " +
                        _vm._s(_vm.userLeave.entryType) +
                        " " +
                        _vm._s(_vm.username) +
                        "'s " +
                        _vm._s(_vm.userLeave.type) +
                        "'s balance will be " +
                        _vm._s(_vm.balanceAfter)
                    ),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            (_vm.validationResponse.dailyTargets || []).length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "d-flex border mb-2",
                    staticStyle: { "border-radius": "10px" },
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "width",
                            rawName: "v-width",
                            value: "40px",
                            expression: "'40px'",
                          },
                        ],
                        staticClass: "border-right position-relative",
                      },
                      [
                        _c("Icon", {
                          staticClass: "vertical-center",
                          attrs: { name: "cis-target" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "w-100" },
                      [
                        _c(
                          "DxScrollView",
                          {
                            attrs: {
                              width: "100%",
                              height: "100%",
                              "show-scrollbar": "always",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { "max-height": "100px" } },
                              _vm._l(
                                _vm.validationResponse.dailyTargets,
                                function (dailyTarget, i) {
                                  return _c(
                                    "div",
                                    {
                                      key: "daily-target-" + i,
                                      staticClass:
                                        "border-bottom pl-2 pb-2 pt-2",
                                    },
                                    [
                                      _c("p", { staticClass: "mb-1" }, [
                                        _vm._v(
                                          "Before this " +
                                            _vm._s(_vm.userLeave.entryType) +
                                            " " +
                                            _vm._s(_vm.username) +
                                            "'s daily target on " +
                                            _vm._s(
                                              _vm._f("MMslashDD")(
                                                dailyTarget.date
                                              )
                                            ) +
                                            " is " +
                                            _vm._s(
                                              dailyTarget.billableHoursTarget
                                            )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          "After this " +
                                            _vm._s(_vm.userLeave.entryType) +
                                            " " +
                                            _vm._s(_vm.username) +
                                            "'s daily target on " +
                                            _vm._s(
                                              _vm._f("MMslashDD")(
                                                dailyTarget.date
                                              )
                                            ) +
                                            " will be " +
                                            _vm._s(
                                              dailyTarget.expectedBillableHoursTarget
                                            )
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }