<template>
	<div class="form-row form-group">
		<CCol sm="3" v-if="label">
			<label class="col-form-label" :class="addLabelClasses"> {{label}} </label>
		</CCol>
		<CCol :sm="label ? '9' : '12'" class="form-inline">
			<CInputCheckbox
				v-for="option in options"
				:key="option.value"
				:label="option.label"
				type="radio"
				:value="option.value"
				:custom="true"
				:name="`Option 1${label}`"
				:checked="selected.includes(option.value)"
				:inline="inline"
				:disabled="disabled"
				@update:checked="toggle($event, option)"
			/>
			<CRow>
				<CCol class="form-inline">
					<CInput
						type="hidden"
						:isValid="_isValid"
						:invalidFeedback="_invalidFeedback"
					/>
				</CCol>
			</CRow>
		</CCol>
	</div>
</template>

<script>

export default {
	name: 'InputCheckboxGroup',
	props: ['label', 'value', 'disabled', 'attrs', 'options', 'placeholder', 'inline', 'addLabelClasses', 'isValid', 'invalidFeedback', 'disabled'],
	computed: {
		selected: {
			get() { return this.value; },
			set(v) { this.$emit('input', v); }
		},
		_isValid(){
			return this.isValid != null ? this.isValid : true
		},
		_invalidFeedback(){
			return this.invalidFeedback || '';
		},
	},
	methods: {
		toggle(checked, option){
			if (!checked && this.selected.includes(option.value)){
				this.selected = this.selected.filter(o => o != option.value)
			} else if (checked) {
				this.selected.push(option.value)
			}
		}
	}
}
</script>
