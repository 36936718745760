<template>
    <DxSelectBox
        v-model="serviceId"
        :search-enabled="true"
        :data-source="services"
        display-expr="displayName"
        value-expr="id"
        placeholder="Select Project"
        :disabled="disabled"
        :read-only="readOnly"
        :hint="hint"
        @value-changed="onSelectService"
        item-template="item">	
        <template #item="{ data }">
            <div>
                {{ data.displayName }}
                <DispositionBadge :type="data.projectType" />
                <InFlightStageBadge :stage="data.stage" />
                <span class="billing-type-badge mr-1" v-if="data.isBilling2">Billing 2.0</span>
            </div>
        </template>
        <DxValidator v-if="required"> <DxRequiredRule message="Project is required"/> </DxValidator>
    </DxSelectBox>
</template>
<script>
import DxSelectBox from 'devextreme-vue/select-box';
import { AxiosWrapper } from '../../mixins/AxiosWrapper'
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator'
export default {
    props:{
        'required':{
            type: Boolean,
            default: false
        },
        'value':{
            type: String,
            default: ''
        },
        'clientId':{
            type: String,
            default: ''
        },
        'disabled':{
            type: Boolean,
            default: false
        },
        'require-client':{
            type: Boolean,
            default: false
        },
        'disabled-items':{
            type: Array,
            default: () => []
        },
        'read-only':{
            type: Boolean,
            default: false
        },
        'hint':{
            type: String,
            default: ''
        }
    },
    components: { DxSelectBox, DxValidator, DxRequiredRule },
    mixins: [ AxiosWrapper ],
    data(){
        return {
            isLoaded: true,
            servicesRaw:[],
            services: null,
        }
    },
    methods:{
        onSelectService(e){
            this.$emit('value-changed', e.value)
            let service = e.value ? this.find(this.servicesRaw, e.value) : undefined
            this.$emit('service-changed', service)
        },
        intiliazeServicesList(){
            return {
                loadMode: 'raw',
                load: () => {
                    const promise = new Promise((resolve) => {
                        let queryParam = this.clientId ? '?clientId='+this.clientId : ''
                        this.get('api/services/detailed-objects' + queryParam).then(response => {
                            let services = response.data;
                            services.forEach(service => {
                                if(this.disabledItems.includes(service.id)) service.disabled = true
                            })
                            this.$emit('on-load', services)
                            this.servicesRaw = services
                            resolve(this.servicesRaw);
                        })
                    });
                    return promise;
                },
            }
        }
    },
    created(){
        if((this.requireClient && this.clientId) || !this.requireClient) 
            this.services = this.intiliazeServicesList()
    },
    watch:{
        clientId(val){
            this.serviceId = '' 
            this.$forceUpdate()
            if(this.requireClient){
                if(val) this.services = this.intiliazeServicesList()
            }
            else{
                this.services = this.intiliazeServicesList()
            }
        },
        disabledItems(){
            this.services = {
                loadMode: 'raw',
                load: () => {
                    const promise = new Promise((resolve) => {
                        this.servicesRaw.forEach(service => {
                            if(this.disabledItems.includes(service.id)) service.disabled = true
                            else service.disabled = false
                        })
                        resolve(this.servicesRaw);
                    });
                    return promise;
                },
            }
        }
    },
    computed:{
        serviceId:{
            get(){ return this.value || '' },
			set(v){ this.$emit('input', v) }
        }
    }
}
</script>

<style lang="scss">
    .billing-type-badge{
        padding: 2px 10px;
        background: #dc3545;
        color: #fff;
        border-radius: 4px;
        font-size: 13px;
    }
</style>
