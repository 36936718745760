var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group form-row",
      attrs: { role: "group", horizontal: _vm.horizontal },
    },
    [
      _vm.label
        ? _c("label", { staticClass: "col-form-label col-sm-3" }, [
            _vm._v(" " + _vm._s(_vm.label) + " "),
            _vm.tooltip
              ? _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.tooltip,
                        expression: "tooltip",
                      },
                    ],
                  },
                  [
                    _c(
                      "CBadge",
                      { attrs: { shape: "pill", color: "secondary" } },
                      [_vm._v("?")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center",
          class: _vm.label ? "col-sm-9" : "",
        },
        [
          _c(
            "label",
            {
              staticClass: "c-switch c-switch-label mb-0",
              class: "c-switch-" + (_vm.color || "success"),
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.checkboxValue,
                    expression: "checkboxValue",
                  },
                ],
                staticClass: "c-switch-input",
                attrs: { type: "checkbox", disabled: _vm.disabled },
                domProps: {
                  checked: Array.isArray(_vm.checkboxValue)
                    ? _vm._i(_vm.checkboxValue, null) > -1
                    : _vm.checkboxValue,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.checkboxValue,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.checkboxValue = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.checkboxValue = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.checkboxValue = $$c
                    }
                  },
                },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "c-switch-slider",
                attrs: {
                  "data-checked": _vm.checkedValue || "On",
                  "data-unchecked": _vm.uncheckedValue || "Off",
                },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }