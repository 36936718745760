var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-row form-group" },
    [
      _vm._t("alternative-label-slot"),
      _vm._v(" "),
      _vm.label
        ? _c(
            "CCol",
            { attrs: { sm: _vm._labelSize } },
            [
              _c(
                "label",
                { staticClass: "col-form-label", class: _vm.addLabelClasses },
                [_vm._v(" " + _vm._s(_vm.label) + " "), _vm._t("tooltip")],
                2
              ),
              _vm._v(" "),
              _vm._t("labelDescription"),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "CCol",
        { attrs: { sm: _vm.fieldSize } },
        [
          _vm._l(_vm._options, function (option) {
            return _c("CInputRadio", {
              key: option.value,
              attrs: {
                label: option.label,
                type: "radio",
                value: option.value,
                custom: true,
                name: "Option 1" + _vm.label,
                checked: option.value === _vm.selected,
                inline: true,
                disabled: _vm.disabled,
              },
              on: {
                "update:checked": function ($event) {
                  _vm.selected = option.value
                },
              },
            })
          }),
          _vm._v(" "),
          _c("CInput", {
            attrs: {
              type: "hidden",
              isValid: _vm._isValid,
              invalidFeedback: _vm._invalidFeedback,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "description",
                  fn: function () {
                    return [_vm._t("description")]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }