var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CToaster",
    { attrs: { position: "bottom-right" } },
    [
      _vm._l(_vm.toasts, function (toast) {
        return [
          _c(
            "CToast",
            {
              key: "toast" + toast.key,
              class: toast.color,
              attrs: {
                show: true,
                header: toast.title,
                autohide: toast.autohide,
              },
              on: {
                "update:show": function ($event) {
                  return _vm.updateShow($event, toast.key)
                },
              },
            },
            [
              _vm._v(
                "\n                " + _vm._s(toast.text) + "\n                "
              ),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-between mt-2",
                },
                [
                  _c("div", [
                    _vm.showProp(toast, "timestamp")
                      ? _c("span", { staticClass: "text-muted" }, [
                          _vm._v(
                            _vm._s(_vm._f("formatDateTime")(toast.timestamp))
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.showProp(toast, "onClick")
                    ? _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            on: {
                              click: function ($event) {
                                return _vm.toastClick(toast)
                              },
                            },
                          },
                          [_vm._v(_vm._s(toast.btnText || "Details"))]
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }