var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.multiple
        ? _c(
            "DxTagBox",
            {
              directives: [
                {
                  name: "height",
                  rawName: "v-height",
                  value: _vm.height,
                  expression: "height",
                },
              ],
              attrs: {
                "search-enabled": true,
                "display-expr": "userName",
                "value-expr": "id",
                "data-source": _vm.users,
                placeholder: "Select Users",
                disabled: _vm.disabled,
                "read-only": _vm.readOnly,
                label: _vm.label,
                "label-mode": _vm.labelMode,
                "apply-value-mode": _vm.applyValueMode,
              },
              on: { "value-changed": _vm.onValueChanged },
              model: {
                value: _vm.selectedUsers,
                callback: function ($$v) {
                  _vm.selectedUsers = $$v
                },
                expression: "selectedUsers",
              },
            },
            [
              _vm.required
                ? _c(
                    "DxValidator",
                    [
                      _c("DxRequiredRule", {
                        attrs: { message: "User(s) are required" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c(
            "DxSelectBox",
            {
              directives: [
                {
                  name: "height",
                  rawName: "v-height",
                  value: _vm.height,
                  expression: "height",
                },
              ],
              attrs: {
                "search-enabled": true,
                "data-source": _vm.users,
                "display-expr": "userName",
                "value-expr": "id",
                placeholder: "Select User",
                disabled: _vm.disabled,
                "read-only": _vm.readOnly,
                label: _vm.label,
                "label-mode": _vm.labelMode,
                "show-clear-button": _vm.showClearButton,
                multiline: _vm.multiline,
              },
              on: { "value-changed": _vm.onValueChanged },
              model: {
                value: _vm.userId,
                callback: function ($$v) {
                  _vm.userId = $$v
                },
                expression: "userId",
              },
            },
            [
              _vm.required
                ? _c(
                    "DxValidator",
                    [
                      _c("DxRequiredRule", {
                        attrs: { message: "User is required" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }