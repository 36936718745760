var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CollapsibleCard",
    {
      attrs: {
        title: _vm.label || "Billing Address",
        closedByDefault: !_vm.openByDefault,
        isOpen: _vm.openAddressForm,
      },
      on: {
        "update:isOpen": function ($event) {
          _vm.openAddressForm = $event
        },
        "update:is-open": function ($event) {
          _vm.openAddressForm = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "headerButton",
          fn: function () {
            return [
              _c(
                "div",
                [
                  _vm.showUseShippingAddress
                    ? _c(
                        "CButton",
                        {
                          staticClass: "mr-2",
                          attrs: { type: "button", size: "sm", color: "light" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.clickedUseShippingAddress($event)
                            },
                          },
                        },
                        [_vm._v("Use Shipping address")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showUseBillingAddress
                    ? _c(
                        "CButton",
                        {
                          staticClass: "mr-2",
                          attrs: { type: "button", size: "sm", color: "light" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.clickedUseBillingAddress($event)
                            },
                          },
                        },
                        [_vm._v("Use billing address")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "CForm",
        { attrs: { id: "billing-address" } },
        _vm._l(_vm.fields, function (field) {
          return _c(
            "div",
            { key: field.name },
            [
              _c(field.component, {
                tag: "div",
                attrs: {
                  type: field.type,
                  label: field.label || _vm.formatLabel(field.name),
                  options: field.options || null,
                  horizontal: "",
                  placeholder: field.placeholder,
                  readonly: field.readonly || false,
                  isValid: _vm.isValidCallback(field.name),
                  invalidFeedback: _vm.invalidFeedback(field.name),
                },
                model: {
                  value: _vm.address[field.name],
                  callback: function ($$v) {
                    _vm.$set(_vm.address, field.name, $$v)
                  },
                  expression: "address[field.name]",
                },
              }),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }