export const PhotoHelper = {
	methods: {
		getIconSrc(path) { return "https://efeedback.kyledavidgroup.com/" + path; },
		decode(x) {
			return atob(x);
		},
		hexStringToPath(hexString) {
			const bytes = [];
			for (let c = 2; c < hexString.length; c += 2) {
				bytes.push(parseInt(hexString.substr(c, 2), 16));
			}
			
			let decoder = new TextDecoder();
			return decoder.decode(new Uint8Array(bytes));
		},
		isImage(data) {
			try {
				let decoded_data = this.decode(data);
				return (decoded_data.startsWith('\x89PNG') || decoded_data.startsWith('\xFF\xD8')) ? true : false
			}
			catch (e) {
				return false;
			}
		},
		getImageType(data) {
			let decoded_data = this.decode(data)
			if (decoded_data.endsWith(".png")) {
				return "png"
			}
			else if (decoded_data.endsWith(".jpeg")) {
				return "jpeg"
			}
			return (decoded_data.startsWith('\x89PNG') ? "png" : decoded_data.startsWith('\xFF\xD8')) ? "jpeg" : "None"
		}
	},
}
