var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DxTagBox",
    {
      attrs: {
        "search-enabled": true,
        "data-source": _vm.list,
        "display-expr": _vm.displayExpr,
        "value-expr": _vm.valueExpr,
        placeholder: _vm.placeholder,
        label: _vm.label,
        "label-mode": _vm.labelMode,
        multiline: _vm.multiline,
        "read-only": _vm.readOnly,
        "show-clear-button": _vm.showClearButton,
        "apply-value-mode": _vm.applyValueMode,
      },
      on: { "value-changed": _vm.onValueChanged },
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v
        },
        expression: "selected",
      },
    },
    [
      _vm.required
        ? _c(
            "DxValidator",
            [
              _c("DxRequiredRule", {
                attrs: { message: "Value(s) are required" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }