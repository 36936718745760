const showMessage = () => {
	window.showAlertModal('Not Implemented')
}
export default {
	bind(el) {
		el.addEventListener('click', showMessage);
	},
	unbind(el) {
		el.removeEventListener('click', showMessage);
	}
}
