import { render, staticRenderFns } from "./CTextareaWrapper.vue?vue&type=template&id=6544921c&"
import script from "./CTextareaWrapper.vue?vue&type=script&lang=js&"
export * from "./CTextareaWrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6544921c')) {
      api.createRecord('6544921c', component.options)
    } else {
      api.reload('6544921c', component.options)
    }
    module.hot.accept("./CTextareaWrapper.vue?vue&type=template&id=6544921c&", function () {
      api.rerender('6544921c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/client/components/generic/CTextareaWrapper.vue"
export default component.exports