import { AxiosWrapper, TextFormatter, LevelColor } from '../'
import AuthorizedUserPopupForm from '../../views/authorized-users/AuthorizedUserPopupForm'

export const blankAddress = () => {
	return {
		street: '',
		city: '',
		state: '',
		zip: '',
		country: 'USA',
	}
}

export const newAuthUser = (defaultLevel) => {
	return {
		firstName: '',
		lastName: '',
		title: '',
		email: '',
		phone: '',
		level: (defaultLevel || 'Support'),
		notes: '',
		makeContactUser: false,
		receiveFeedbackEmails: false,
		alwaysCC: false,
		leftGoogleReview:false,
		feedbackOptOutTime: null,
		linkExistingUser: {},
		address: blankAddress(),
		serviceLevelSettings: [],
		selectedServices: [],
	}
}

export default {
	components: { AuthorizedUserPopupForm },
	mixins: [AxiosWrapper, TextFormatter, LevelColor],
	props: ['client', 'service', 'recentChanges'],
	data(){
		return {
			apiRequest: null,
			editRecord: null,
			editFormKey: 0,
			tableKey: 0,
			records: [],
			searchCallback(record, search) {
				return (record.firstName+record.lastName).toLowerCase().replace(' ', '').includes(search.toLowerCase().replace(' ', ''))
					|| (record.title || '').toLowerCase().includes(search.toLowerCase())
					|| (record.email || '').toLowerCase().includes(search.toLowerCase())
					|| (record.phone || '').toLowerCase().includes(search.toLowerCase())
					|| (record.level || '').toLowerCase().includes(search.toLowerCase())
					|| (record.notes || '').toLowerCase().includes(search.toLowerCase())
			},
			filterTypes: {
				level: ['Unauthorized', 'Banned', 'Support', 'RestrictedSupport', 'Admin'],
			}
		}
	},
	computed: {
		authorizedUsers(){
			return this.records
		},
		recordsEndpoint(){
			return '/api/authorizedUsers/' + (this.service ? ('service/'+this.service.id) : ('client/'+this.client.id))
		},
		changeCount(){
			return this.recentChanges.authorizedUsers ? (Object.keys(this.recentChanges.authorizedUsers).length + this.recentChanges.deletedAuthorizedUsers.length) : 0
		},
		fields(){
			const fields = [
				{ key: 'user' },
				// { key: 'lastName' },
				// { key: 'title' },
				{ key: 'email' },
				{ key: 'phone' },
				// { key: 'level', sorter: false  },
				{ key: 'notes', sorter: false  },
				{ key: 'actions', label: '', sorter: false, _classes:'width-100' },
			]
			if (!this.service){
				fields.splice(2, 0, {key: 'services', sorter: false,})
			}
			return fields
		},
	},
	methods: {
		editAuthorizedUser(user){
			++this.editFormKey
			this.editRecord = Object.assign({}, user)
		},
		newAuthorizedUser(){
			++this.editFormKey
			this.editRecord = newAuthUser()
		},
		deleteAuthorizedUser(user, deleteCallback){
			let self = this;
			const options = {
				confirmBtnText: this.service ? 'Yes, remove user' : 'Yes, delete user',
				text: 'Rather than deleting this user, it is recommended to update their support level so you maintain a record of them. Would you still like to '
					+ (this.service ? 'remove this user from the service?' : 'delete this user?'),
				onConfirm: function(){
					self.deleteAuthorizedUserConfirmed(user, deleteCallback)
				}
			}
			if (!this.service && user.contactPerson && !user.contactPerson.isPrimaryContact){
				options.innerComponent = 'CAlertWrapper'
				options.innerComponentData = {
					color: 'warning',
					text: 'Note: The contact person will also be removed from Zoho Books.',
				}
			}
			window.showConfirmModal(options);
		},
		async deleteAuthorizedUserConfirmed(user, deleteCallback){
			try {
				let url = '/api/authorizedUsers/' + (this.service ? ('service/'+this.service.id+'/') : '') + user.id
				await this.delete(url)
				deleteCallback(user)
				this.$emit('do-refresh')
			} catch (e) {
				if (typeof e.response.data == 'string'){
					window.showToast({text: e.response.data, title: 'Alert', color: 'danger'})
				} else {
					window.showToast({text: 'An error occurred while performing the action.', title: 'Alert', color: 'danger'})
				}
			}
		},
		isRecordChanged(record){
			return this.recentChanges.authorizedUsers && this.recentChanges.authorizedUsers[record.id] != null
		},
		savedRecord(record, panelComponent, doRefresh){
			panelComponent.savedRecord(record)
			if (doRefresh){
				this.$emit('do-refresh')
			}
		},
		containsAdmin(users) {
			return users.some(u => u.level === 'Admin')
		},
		markAsPrimary(user){
			const self = this
			window.showConfirmModal({
				title: 'Change Primary Contact',
				text: 'Do you want to make ' + user.fullName + ' the primary contact?',
				color: 'warning',
				confirmBtnText: 'Yes, change primary contact',
				async onConfirm(){
					try {
						await (self.apiRequest = self.post('/api/authorizedUsers/makePrimaryContact/' + user.id))
						self.$emit('do-refresh')
					} catch (e){
						console.error(e)
					} finally {
						self.apiRequest = null
					}
				}
			})
		},
	}
}
