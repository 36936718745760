var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        class: _vm.modalClasses,
        attrs: { tabindex: "-1", role: "dialog" },
        on: {
          click: function ($event) {
            return _vm.modalClick($event)
          },
        },
      },
      [
        _c("div", { class: _vm.dialogClasses, attrs: { role: "document" } }, [
          _c(
            "div",
            { class: _vm.contentClasses },
            [
              _vm._t("header-wrapper", [
                _c(
                  "header",
                  { staticClass: "modal-header" },
                  [
                    _vm._t("header", [
                      _c(
                        "h5",
                        { staticClass: "modal-title w-100" },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t" + _vm._s(_vm.title) + " "
                          ),
                          _vm._t("title-append"),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "float-right" },
                        [_vm._t("header-float-right")],
                        2
                      ),
                      _vm._v(" "),
                      _c("CButtonClose", {
                        on: {
                          click: function ($event) {
                            return _vm.hide($event)
                          },
                        },
                      }),
                    ]),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _vm._t("body-wrapper", [
                _c(
                  "div",
                  { staticClass: "modal-body" },
                  [_vm._t("default")],
                  2
                ),
              ]),
              _vm._v(" "),
              _vm._t("footer-wrapper", [
                _c(
                  "footer",
                  { staticClass: "modal-footer" },
                  [
                    _vm._t("footer", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.hide($event)
                            },
                          },
                        },
                        [_vm._v("\n\t\t\t\t\t\t\t\tCancel\n\t\t\t\t\t\t\t")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          class: _vm.btnClasses,
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.hide($event)
                            },
                          },
                        },
                        [_vm._v("\n\t\t\t\t\t\t\t\tOK\n\t\t\t\t\t\t\t")]
                      ),
                    ]),
                  ],
                  2
                ),
              ]),
            ],
            2
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm.backdrop && (_vm.visible || _vm.isTransitioning)
      ? _c("div", { class: _vm.backdropClasses })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }