<template>
	<div class="form-row form-group">
		<slot name="alternative-label-slot" />
		<CCol :sm="_labelSize" v-if="label">
			<label class="col-form-label" :class="addLabelClasses"> {{label}} <slot name="tooltip" /></label>
			<slot name="labelDescription" />
		</CCol>
		<CCol :sm="fieldSize">
				<CInputRadio
					v-for="option in _options"
					:key="option.value"
					:label="option.label"
					type="radio"
					:value="option.value"
					:custom="true"
					:name="`Option 1${label}`"
					:checked="option.value === selected"
					:inline="true"
					:disabled="disabled"
					@update:checked="selected = option.value"
				>
				</CInputRadio>
			<!-- <CRow>
				<CCol class=""> -->
					<CInput
						type="hidden"
						:isValid="_isValid"
						:invalidFeedback="_invalidFeedback"
					>
						<template #description><slot name="description" /></template>
					</CInput>
				<!-- </CCol>
			</CRow> -->
		</CCol>
	</div>
</template>

<script>

export default {
	name: 'InputRadioGroup',
	props: ['label', 'value', 'disabled', 'attrs', 'options', 'placeholder', 'horizontal', 'addLabelClasses', 'isValid', 'invalidFeedback', 'disabled', 'labelSize'],
	computed: {
		selected: {
			get() { return this.value; },
			set(v) {
				this.$emit('input', v);
				this.$emit('change', v);
			}
		},
		_isValid(){
			return this.isValid != null ? this.isValid : true
		},
		_invalidFeedback(){
			return this.invalidFeedback || '';
		},
		_options(){
			return this.options.map(o => {
				if (typeof o === 'string'){
					return { value: o, label: o }
				}
				return o
			})
		},
		_labelSize(){
			return this.labelSize || 3
		},
		fieldSize(){
			return this.label ? (12 - Number(this._labelSize)) : 12
		},
	},
}
</script>
