var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DxTagBox",
    {
      attrs: {
        "search-enabled": true,
        displayExpr: "displayName",
        "data-source": _vm.groups,
        placeholder: "Select Groups",
        disabled: _vm.disabled,
      },
      on: { "value-changed": _vm.onValueChanged },
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v
        },
        expression: "selected",
      },
    },
    [
      _vm.required
        ? _c(
            "DxValidator",
            [
              _c("DxRequiredRule", {
                attrs: { message: "Groups are required" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }