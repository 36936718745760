var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("lookup", {
    style: _vm.style,
    attrs: {
      preloadedOptions: _vm.options,
      label: _vm.label !== false ? _vm.label || "Payment Terms" : false,
      valueKey: "paymentTerms",
      displayKey: "paymentTermsLabel",
      labelClass: "col-form-label col-sm-3 required",
      fieldClass: _vm.fieldClass || "col-sm-9",
      isValid: _vm.isValid,
      invalidFeedback: _vm.invalidFeedback,
      placeholder: _vm.placeholder,
      clearable: _vm.clearable !== undefined ? _vm.clearable : true,
      containerClass: _vm.containerClass || "",
      fieldCol: _vm.fieldCol,
      sort: false,
      disabled: _vm.disabled,
    },
    on: {
      change: function ($event) {
        return _vm.$emit("change", $event)
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "description",
          fn: function () {
            return [
              _vm.client
                ? _c(
                    "p",
                    { staticClass: "text-dark pt-2" },
                    [
                      _vm._v(
                        "\n\t\t\tClient's default payment terms: " +
                          _vm._s(
                            _vm.client.paymentTermsObject.paymentTermsLabel
                          ) +
                          "\n\t\t\t"
                      ),
                      _vm.client.paymentTermsObject.paymentTerms !=
                      _vm.selected.paymentTerms
                        ? _c(
                            "CButton",
                            {
                              staticClass: "ml-2 py-0",
                              attrs: { color: "primary", size: "sm" },
                              on: {
                                click: _vm.clickedChangeClientPaymentTerms,
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\tChange to the selected terms\n\t\t\t"
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
        {
          key: "tooltip",
          fn: function () {
            return [_vm._t("nestedtooltip")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v
      },
      expression: "selected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }