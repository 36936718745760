var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-row form-group" },
    [
      _vm.label
        ? _c("CCol", { attrs: { sm: "3" } }, [
            _c(
              "label",
              { staticClass: "col-form-label", class: _vm.addLabelClasses },
              [_vm._v(" " + _vm._s(_vm.label) + " ")]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "CCol",
        { staticClass: "form-inline", attrs: { sm: _vm.label ? "9" : "12" } },
        [
          _vm._l(_vm.options, function (option) {
            return _c("CInputCheckbox", {
              key: option.value,
              attrs: {
                label: option.label,
                type: "radio",
                value: option.value,
                custom: true,
                name: "Option 1" + _vm.label,
                checked: _vm.selected.includes(option.value),
                inline: _vm.inline,
                disabled: _vm.disabled,
              },
              on: {
                "update:checked": function ($event) {
                  return _vm.toggle($event, option)
                },
              },
            })
          }),
          _vm._v(" "),
          _c(
            "CRow",
            [
              _c(
                "CCol",
                { staticClass: "form-inline" },
                [
                  _c("CInput", {
                    attrs: {
                      type: "hidden",
                      isValid: _vm._isValid,
                      invalidFeedback: _vm._invalidFeedback,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }