<template>
    <span>
        <CIcon
            v-if="iconPack == 'coreui'"
            :name="name"
            :size="iconSize"
            :id="elId" :class="cssClass"
            v-c-tooltip="tooltip" :title="title"
            v-top="top" v-right="right" v-left="left" v-bottom="bottom" />
        <i
            v-if="iconPack == 'dx'"
            :size="iconSize"
            :id="elId" :class="name + ' ' + cssClass"
            v-c-tooltip="tooltip" :title="title"
            v-top="top" v-right="right" v-left="left" v-bottom="bottom">
        </i>
        <b-icon
            v-if="iconPack == 'bootstrap'"
            :icon="name" :font-scale="iconSize"
            :id="elId" :class="cssClass"
            v-c-tooltip="tooltip" :title="title"
            v-top="top" v-right="right" v-left="left" v-bottom="bottom" />
        <img
            v-if="iconPack == 'atc'"
            :src="name" :alt="name"
            v-c-tooltip="tooltip" :title="title"
            :id="elId" :class="cssClass"
            v-top="top" v-right="right" v-left="left" v-bottom="bottom"
            :width="iconSize || '22px'" />
    </span>
</template>

<script>
import { CIcon } from '@coreui/icons-vue';
export default {
    props:{
        'name': {
            type: String,
            default: ''
        },
        'size': {
            type: String,
            default: 'sm'
        },
        'el-attr': {
            type:Object,
            default: () => {}
        },
        'tooltip':{
            type: String,
            default: ''
        },
        'title':{
            type: String,
            default: ''
        },
        'icon-pack':{
            type: String,
            default: 'coreui'
        },
        'top':{
            type: String,
            default: 'auto'
        },
        'right':{
            type: String,
            default: 'auto'
        },
        'left':{
            type: String,
            default: 'auto'
        },
        'bottom':{
            type: String,
            default: 'auto'
        }
    },
    components:{CIcon},
    data(){
        return {
            id: this.generateUniqueCode()
        }
    },
    computed:{
		_size(){
			if(this.iconPack == 'atc' && this.size == 'sm'){
				return '18px'
			}
			return this.size
		},
        elId(){
            if(this.elAttr) return this.elAttr.id ? this.elAttr.id : this.id
            return this.id
        },
        cssClass(){
            if(this.elAttr) return this.elAttr.class ? this.elAttr.class : ''
            return ''
        },
        iconSize(){
            if(this.iconPack == 'atc' && this.size == 'sm'){
                return '18px'
            }
            else {
                return this.size
            }
        }
    }
}
</script>
