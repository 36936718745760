var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("lookup", {
        key: _vm.key,
        attrs: {
          optionsEndpoint: _vm.optionsEndpoint,
          label: _vm.label || "Authorized User",
          isValid: _vm.isValid,
          invalidFeedback: _vm.invalidFeedback,
          displayKey: _vm.displayKey || "fullName",
          labelClass: "col-form-label col-sm-3",
          fieldClass: _vm.allowNew ? "col-sm-8" : "col-sm-9",
          includeProperties: _vm._includeProperties,
          appendOption: _vm.appendOption,
          disabled: _vm.disabled,
        },
        on: {
          change: _vm.onChange,
          "update:appendOption": function ($event) {
            _vm.appendOption = $event
          },
          "update:append-option": function ($event) {
            _vm.appendOption = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "extra",
            fn: function () {
              return [
                _vm.allowNew
                  ? _c(
                      "span",
                      { staticClass: "col-sm-1", attrs: { name: "addButton" } },
                      [
                        _c(
                          "CButton",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: "Add new authorized user",
                                expression: "'Add new authorized user'",
                              },
                            ],
                            staticClass: "btn-input-height btn-fit-right",
                            attrs: {
                              disabled: _vm.disabled,
                              type: "button",
                              color: "primary",
                              size: "sm",
                            },
                            on: { click: _vm.openAuthedUserForm },
                          },
                          [_c("CIcon", { attrs: { name: "cil-plus" } })],
                          1
                        ),
                        _vm._v(" "),
                        _c("AuthorizedUserPopupForm", {
                          key: _vm.userFormKey,
                          attrs: {
                            client: (_vm.service || {}).client || _vm.client,
                            service: _vm.service,
                            requireAddress: _vm.requireAddress,
                            level: _vm.level,
                          },
                          on: { saved: _vm.savedNewAuthedUser },
                          model: {
                            value: _vm.newAuthedUser,
                            callback: function ($$v) {
                              _vm.newAuthedUser = $$v
                            },
                            expression: "newAuthedUser",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
      _vm._v(" "),
      _vm.requireAddress && _vm.selected
        ? _c(
            "span",
            [
              _c("AddressForm", {
                attrs: {
                  label: "Address",
                  openByDefault: true,
                  validationErrors: _vm.addressValidationErrors,
                  isValidCallback: _vm.addressIsValidCallback,
                  validationPasses: _vm.validationPasses,
                  billingAddress: _vm.client.billingAddress,
                },
                model: {
                  value: _vm.selected.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.selected, "address", $$v)
                  },
                  expression: "selected.address",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }