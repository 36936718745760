<template>
    <DxLoadPanel 
        :container="elementId" 
        :visible="visible" 
        :shading="shading"
        :position="{at:'center', of:elementId}" />
</template>

<script>
import { DxLoadPanel } from 'devextreme-vue/load-panel';

export default {
    props:['visible', 'elementId', 'shading'],
    components: {DxLoadPanel}
}
</script>
