var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DxSelectBox",
    {
      attrs: {
        "search-enabled": true,
        "data-source": _vm.list,
        "display-expr": _vm.displayExpr,
        "value-expr": _vm.valueExpr,
        placeholder: _vm.placeholder,
        disabled: _vm.disabled,
        "read-only": _vm.readOnly,
        label: _vm.label,
        "label-mode": _vm.labelMode,
        "show-clear-button": _vm.showClearButton,
        hint: _vm.hint,
        "element-attr": _vm.elementAttr,
      },
      on: { "value-changed": _vm.onValueChanged },
      scopedSlots: _vm._u(
        [
          _vm.showCustomTemp
            ? {
                key: "item",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          padding: "14px",
                          border: "1px solid lightgray",
                        },
                      },
                      [
                        _vm.displayExpr2 == ""
                          ? _c("span", [_vm._v(_vm._s(data[_vm.displayExpr]))])
                          : _c("span", [
                              _vm._v(
                                _vm._s(data[_vm.displayExpr][_vm.displayExpr2])
                              ),
                            ]),
                        _vm._v(" "),
                        _vm.showLinkedClient
                          ? _c(
                              "span",
                              { staticClass: "float-right text-danger" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      data.isLinked && !data.hasActiveService
                                        ? "Linked"
                                        : ""
                                    ) +
                                    "\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                },
              }
            : null,
        ],
        null,
        true
      ),
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v
        },
        expression: "selected",
      },
    },
    [
      _vm._v(" "),
      _vm.required
        ? _c(
            "DxValidator",
            [_c("DxRequiredRule", { attrs: { message: "Value is required" } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }