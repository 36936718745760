<template>
	<CustomCSelect
		:label="label"
		:value="selected"
		:disabled="disabled"
		:attrs="attrs"
		:options="options"
		@update:value="selected = $event"
		:placeholder="placeholder"
		:horizontal="horizontal == undefined ? true : horizontal"
	>
		<template #label><slot name="tooltip" /></template>
	</CustomCSelect>
</template>

<script>
export default {
	name: 'CSelectWrapper',
	props: ['label', 'value', 'disabled', 'attrs', 'options', 'placeholder', 'horizontal'],
	computed: {
		selected: {
			get() { return this.value; },
			set(v) { this.$emit('input', v); }
		},
	},
}
</script>
