var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("lookup", {
    ref: "lookupField",
    style: _vm.style,
    attrs: {
      optionsEndpoint: "/api/clients",
      label: _vm.labelText,
      placeholder: _vm.multiple ? "Select Client(s)" : "Select Client",
      isValid: _vm.isValid,
      invalidFeedback: _vm.invalidFeedback,
      displayKey: "companyName",
      clearable: _vm.clearable !== undefined ? _vm.clearable : true,
      multiple: _vm.multiple !== undefined ? _vm.multiple : false,
      containerClass: _vm.containerClass || "",
      labelClass: _vm.labelClass,
      fieldClass: _vm.fieldClass,
      disabled: _vm.disabled,
      includeProperties: _vm.includeProperties,
    },
    on: {
      change: function ($event) {
        return _vm.$emit("change", $event)
      },
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v
      },
      expression: "selected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }