var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { directives: [{ name: "fragments", rawName: "v-fragments" }] },
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "bg-color",
              rawName: "v-bg-color",
              value: (_vm.stage || {}).bgClr || "default",
              expression: "(stage || {}).bgClr || 'default'",
            },
          ],
          staticClass: "badge pointer",
          attrs: { id: _vm.uniqueCode },
        },
        [
          (_vm.stage || {}).icon
            ? _c("Icon", {
                attrs: {
                  size: "14px",
                  name: (_vm.stage || {}).icon,
                  "icon-pack": "atc",
                },
              })
            : _vm._e(),
          _vm._v("\n        " + _vm._s((_vm.stage || {}).name) + "\n    "),
        ],
        1
      ),
      _vm._v(" "),
      _vm.tooltip
        ? _c(
            "DxTooltip",
            {
              attrs: {
                target: "#" + _vm.uniqueCode,
                "show-event": "mouseenter",
                "hide-event": "mouseleave",
                position: "bottom",
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.tooltip) + "\n    ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }