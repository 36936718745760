<template>
	<DxPopup
        :wrapper-attr="{id: 'dxConfirmPopup', class: mode}"
		v-if="popup.show"
		:visible="popup.show"
		:on-hidden="popup.closeButtonOptions"
		:drag-enabled="true"
		:hide-on-outside-click="hideOnOutsideClick"
		:show-close-button="false"
        :show-title="false"
        :height="height"
        :width="width">
        <div>
            <div class="text-center" v-if="!loading">
                <div style="font-size: 18px;margin: 5px;font-weight: bolder;">{{title}}</div>
                <p class="mt-3">{{subTitle}}</p>
                <DxButton :text="cancelButtonText" 
                    @click="cancel" :element-attr="{class:'cancel-btn'}" />
                <DxButton :text="confirmButtonText" :type="mode"
                    @click="confirm" :element-attr="{class:'confirm-btn'}" />
            </div>
            <spinner v-else />
        </div>
	</DxPopup>
</template>

<script>
import {DxPopup, DxToolbarItem} from 'devextreme-vue/popup';
import { DxButton } from 'devextreme-vue/button'
export default {
	name:'DxConfirmPopup',
	components:{
        DxPopup,
        DxToolbarItem,
        DxButton
    },
	data(){
		return {
            loading: false,
			popup:{
				show: false,
				closeButtonOptions: () => { 
                    this.popup.show = false; 
                    this.onHidden();
                }
			},
            title: 'Are you sure?',
            subTitle: '',
            height: 200,
            width: 350,
            confirmButtonText: 'Okay',
            cancelButtonText: 'Cancel',
            showLoader: false,
            onConfirm: async function(){},
            onCancel: async function(){},
            onHidden: function(){},
            mode: 'normal',
            hideOnOutsideClick: true
		}
	},
	methods:{
		show(options){
			this.title = options.title || 'Are you sure?'
			this.subTitle = options.subTitle || ''
			this.height = options.height || 200
			this.width = options.width || 350
			this.confirmButtonText = options.confirmButtonText || 'Okay'
			this.cancelButtonText = options.cancelButtonText || 'Cancel'
			this.onConfirm = options.onConfirm || async function(){}
			this.onCancel = options.onCancel || function(){}
			this.onHidden = options.onHidden || function(){}
            this.showLoader = options.showLoader || false
            this.hideOnOutsideClick = options.hideOnOutsideClick || false
			this.popup.show = true
            this.mode = options.mode || 'normal'
		},
		async cancel(){
            if(this.showLoader) this.loading = true
            try{
                await this.onCancel()
            }
            finally{
                this.loading = false
                this.popup.show = false
            }
		},
		async confirm(){
            if(this.showLoader) this.loading = true
            try{
			    await this.onConfirm()
            }
            finally{
                this.loading = false
                this.popup.show = false
            }
		}
	}
}
</script>

<style>
#dxConfirmPopup.danger .dx-popup-title{
    background-color: var(--danger);
    color: #fff;
}
#dxConfirmPopup.success .dx-popup-title{
    background-color: var(--success);
    color: #fff;
}
#dxConfirmPopup.warning .dx-popup-title{
    background-color: var(--warning);
    color: #fff;
}
#dxConfirmPopup .cancel-btn{
    position: absolute;
    bottom:20px;
    left: 20px;
}
#dxConfirmPopup .confirm-btn{
    position: absolute;
    bottom:20px;
    right: 20px;
}
</style>