<template>
	<CollapsibleCard :title="label || 'Billing Address'" :closedByDefault="!openByDefault" :isOpen.sync="openAddressForm">
		<template #headerButton>
            <div>
			    <CButton v-if="showUseShippingAddress" type="button" size="sm" color="light" class="mr-2" @click.stop="clickedUseShippingAddress">Use Shipping address</CButton>
			    <CButton v-if="showUseBillingAddress" type="button" size="sm" color="light" class="mr-2" @click.stop="clickedUseBillingAddress">Use billing address</CButton>
            </div>
		</template>
		<CForm id="billing-address">
			<div v-for="field in fields" :key="field.name">
				<div :is="field.component"
					:type="field.type"
					:label="field.label || formatLabel(field.name)"
					:options="field.options || null"
					horizontal
					:placeholder="field.placeholder"
					:readonly="field.readonly || false"
					v-model="address[field.name]"
					:isValid="isValidCallback(field.name)"
					:invalidFeedback="invalidFeedback(field.name)"
				></div>
			</div>
		</CForm>
	</CollapsibleCard>
</template>

<script>
import { TextFormatter } from '../mixins';
import StateDropdown from './StateDropdown.vue';

export default {
	components: { StateDropdown },
	name: 'AddressForm',
	props: ['value', 'label', 'validationErrors', 'openByDefault', 'isValidCallback', 'validationPasses', 'billingAddress', 'shippingAddress'],
	mixins: [TextFormatter],
	data () {
		return {
			fields: [
				{ name: 'street', component: 'CInput', type: 'text'},
				{ name: 'city', component: 'CInput', type: 'text'},
				{ name: 'state', component: 'StateDropdown'},
				{ name: 'zip', component: 'CInput', type: 'text'},
				{ name: 'country', component: 'CInput', type: 'text', },
			],
			openAddressForm: this.openByDefault,
		}
	},
	computed: {
		showUseBillingAddress(){
            return this.isNotNullOrEmpty(this.billingAddress)
			//return typeof this.billingAddress !== 'undefined'
		},
        showUseShippingAddress(){
            return this.isNotNullOrEmpty(this.shippingAddress)
			//return typeof this.shippingAddress !== 'undefined'
		},
		address: {
			get(){
				return this.value || {
					street: '',
					city: '',
					state: '',
					zip: '',
					country: 'USA',
				};
			},
			set(v){
				this.$emit('input', v);
			}
		},
	},
	methods: {
		invalidFeedback(field){
			return this.validationErrors && this.validationErrors[field] ? this.validationErrors[field] : ''
		},
		clickedUseBillingAddress(){
			if (!this.openAddressForm){
				this.openAddressForm = true
				window.setTimeout(this.useBillingAddress, 700)
			} else {
				this.useBillingAddress()
			}
		},
		useBillingAddress(){
			this.address = this.shallowClone(this.billingAddress)
		},
		clickedUseShippingAddress(){
			if (!this.openAddressForm){
				this.openAddressForm = true
				window.setTimeout(this.useShippingAddress, 700)
			} else {
				this.useShippingAddress()
			}
		},
		useShippingAddress(){
			this.address = this.shallowClone(this.ShippingAddress)
		},

	}
}
</script>
