<template>
	<lookup
		:optionsEndpoint="optionsEndpoint"
		:label="label !== false ? (label || 'Service Type') : false"
		:placeholder="placeholder"
		v-model="selected"
		:isValid="isValid"
		:invalidFeedback="invalidFeedback"
		displayKey="name"
		:containerClass="containerClass || ''"
		:fieldClass="fieldClass"
		:labelClass="labelClass"
		:includeProperties="['allowedBillingTypes', 'isProjectRequired', 'serviceType', 'isSLA']"
		:sort="false"
		@change="$emit('change', $event)"
	></lookup>
</template>

<script>
import Lookup from '../generic/Lookup'

export default {
	props: ['value', 'isValid', 'invalidFeedback', 'containerClass', 'fieldClass', 'labelClass', 'label', 'placeholder'],
	components: {Lookup},
	computed: {
		selected: {
			get(){
				return this.value;
			},
			set(v){
				this.$emit('input', v);
			}
		},
		optionsEndpoint(){
			return '/api/settings/serviceTypes'
		}
	},
}
</script>
